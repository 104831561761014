import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'antd/dist/reset.css'; // Import for Ant Design styles

const { RangePicker } = DatePicker;

const TopField = ({
  startDate,
  endDate,
  selectedShift,
  department,
  section,
  handleStartDateChange,
  handleEndDateChange,
  handleShiftChange,
  handleDepartment,
  handleSection,
  editable
}) => {


  // console.log('editable',editable)
  // Function to handle date range change
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      // Convert dates to ISO string format before sending to the backend
      const startDate = dates[0].format('YYYY-MM-DD');
      const endDate = dates[1].format('YYYY-MM-DD');
  
      // Call the handlers with the formatted date strings
      handleStartDateChange({ target: { value: startDate } });
      handleEndDateChange({ target: { value: endDate } });
    }
  };

  const fields = [
    {
      label: 'Dates Worked',
      element: (
        <RangePicker
          className='w-full outline-none border-none font-poppins'
          value={[dayjs(startDate), dayjs(endDate)]} // Convert start and end dates to dayjs objects
          onChange={handleDateRangeChange}
          format='YYYY-MM-DD'
          disabled = {!editable}
        />
      ),
    },
    {
      label: 'Shift',
      element: (
        <select
          className='outline-none w-full h-[30px] font-poppins'
          value={selectedShift}
          onChange={handleShiftChange}
          disabled = {!editable}

        >
          <option value=''>Select your Shift</option>
          <option value='morning'>Morning</option>
          <option value='evening'>Evening</option>
        </select>
      ),
    },
    {
      label: 'Department',
      element: (
        <input
          type='text'
          value={department}
          onChange={handleDepartment}
          disabled = {!editable}

          placeholder='Your Department'
          className='outline-none w-full h-[30px] font-poppins'
        />
      ),
    },
    {
      label: 'Section',
      element: (
        <input
          type='text'
          value={section}
          onChange={handleSection}
          disabled = {!editable}

          placeholder='Your Section'
          className='outline-none w-full h-[30px] font-poppins'
        />
      ),
    },
  ];

  return (
    <div className='bg-primary-background flex flex-col space-y-4 p-5 px-7 pb-10 rounded-[5px]'>
      <div className='flex flex-wrap md:gap-y-[5px] md:gap-x-[5px] '>
        {fields.map((field, index) => (
          <div key={index} className='w-full sm:w-1/2 md:w-[calc(50%-10px)] xl:w-[calc(25%-12px)] gap-y-[5px]'>
            <div>
              <span className='font-poppins'>{field.label}</span>
            </div>
            <div className='border-2 border-gray-500 p-2 rounded-lg bg-white font-poppins'>
              {field.element}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopField;

{/* <RangePicker
format='YYYY-MM-DD'
value={values.internstartend}
onChange={(dates) => setFieldValue('internstartend', dates)}
className={`border-[1px] font-poppins text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-lg outline-none ${touched.internstartend && errors.internstartend ? 'border-red-500' : 'border-gray-400'}`}
/> */}


// setFieldValue in form bhanda mathi ma with values,
// formData.append('internStartDate', internstartend[0]);
//           formData.append('internEndDate', internstartend[1]);

