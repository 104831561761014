import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../components/Button';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import LeftSection from './LeftSection';
import { getRegisterStepOneData } from '../store/reducerSlices/userSlice';
import { useDispatch } from 'react-redux';

const Register = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          phonenumber: '',
          candidateregisterationnumber: '',
          supervisorname: '',
          partnertrainingcenter: '',
          address: '',
          gender: '',
          course: '',
          // company: '',
          profilepic: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Name is required'),
          // lastname: Yup.string().max(255).required('Last Name is required'),
          phonenumber: Yup.number().required('Phone Number is required').required('Phone Number is required'),
          candidateregisterationnumber: Yup.string().max(255).required('Candidate registration Number is Required'),
          course: Yup.string().max(255).required('Course Field is required'),

          // password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async ({ name, phonenumber, candidateregisterationnumber, supervisorname, partnertrainingcenter, address, gender, course }) => {
          console.log('navigating two registeersteptwo')
          dispatch(getRegisterStepOneData({ name, phonenumber, candidateregisterationnumber, supervisorname, partnertrainingcenter, address, gender, course }))
          navigate('/registersteptwo')
          // const isRegistered = await dataService.postData('/register',{name,email,password,name,refreshToken})
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='flex flex-col lg:h-screen md:flex-row '>
              {/* left section of the login code */}
              <div className='flex flex-col  md:fixed md:h-screen md:left-0 md:w-1/2 md:p-8 md:gap-y-32 md:bg-primary-background ' >
                <Link to="/">
                  <div className='hidden md:block'>
                    <img src="/assets/images/Logo.png" alt="" className='h-16' />

                  </div>

                </Link>
                <div className="flex justify-center items-center mb-6 mt-12 md:hidden ">
                  <span className='text-xl text-primary-title font-poppins font-semibold '>Register</span>

                </div>
                <div className='flex justify-center items-center'>
                  <img src="/assets/images/login.png" alt="" className='h-60 md:h-80 ' />
                </div>
              </div>

              {/* right section of the login code */}

              <div className='w-full right-0 md:w-1/2 md:ml-auto'>
                <div className='md:mt-12 lg:px-16  2xl:px-28 p-4 lg:p-10 md:space-y-[20px] '>
                  <div className='flex justify-center items-center hidden md:block  text-primary-title font-poppins text-xl font-semibold w-full'>
                    <span className='text-2xl  flex justify-center items-end w-full'>Register</span>


                  </div >

                  <div className='hidden md:block '>
                    <div className="flex justify-between items-center  w-full max-w-xl relative   md:px-20 lg:px-32 ">
                      <div className="  mb-8">
                        <div className="w-10 h-10 flex items-center justify-center bg-primary-title text-white rounded-full">
                          1
                        </div>

                      </div>

                      <div className="relative flex-grow">
                        <div className="absolute left-0 -top-4 transform -translate-y-1/2 w-full h-1 bg-primary-title"></div>
                      </div>

                      <div className="mb-8">
                        <div className="w-10 h-10 flex items-center justify-center bg-gray-300 text-white rounded-full">
                          2
                        </div>
                      </div>
                    </div>
                    <div className='  flex justify-around'>
                    <span className=" text-center font-poppins text-[14px]">Basic Information</span>
                    <span className=" text-center font-poppins text-[14px]">Sign-up Information</span>


                    </div>
                  </div>

                  <div style={{ marginTop: '60px' }}>
                    <span className='font-poppins text-primary-title text-md  md:text-xl font-semibold '>Basic Information</span>
                  </div>

                  {/* secttion below of basic information */}

                  <div className=' flex w-full text-sm '>
                    <div className='flex flex-wrap justify-between gap-y-5'>
                      <div className='flex flex-col space-y-1 md:space-y-[5px]   w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Name <span className=' text-red-600 font-medium text-xl'>* </span> </span>
                        <input
                          type="text"
                          id='name'
                          value={values.name}
                          name='name'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your name'

                          className={`border-[1px] border-gray-400 text-[14px] font-poppins font-normal p-1 h-10 px-2 rounded-lg outline-none ${touched.name && errors.name ? 'border-red-500' : 'border-gray-400'
                            }`} />
                        {touched.name && errors.name && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.name}
                          </span>
                        )}
                      </div>

                      <div className='flex flex-col space-y-1 md:space-y-[5px]  w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Phone Number <span className=' text-red-600 font-medium text-xl'>* </span> </span>
                        <input
                          type="text"
                          id='phonenumber'
                          value={values.phonenumber}
                          name='phonenumber'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your phonenumber'

                          className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none ${touched.phonenumber && errors.phonenumber ? 'border-red-500' : 'border-gray-400'
                            }`} />
                        {touched.phonenumber && errors.phonenumber && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.phonenumber}
                          </span>
                        )}
                      </div>
                      <div className='flex flex-col space-y-1 md:space-y-[5px]  w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Candidate Registration No. <span className=' text-red-600 font-medium text-xl'>* </span></span>
                        <input
                          type="text"
                          id='candidateregisterationnumber'
                          value={values.candidateregisterationnumber}
                          name='candidateregisterationnumber'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter Candidate Number'

                          className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none ${touched.candidateregisterationnumber && errors.candidateregisterationnumber ? 'border-red-500' : 'border-gray-400'
                            }`} />
                        {touched.candidateregisterationnumber && errors.candidateregisterationnumber && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.candidateregisterationnumber}
                          </span>
                        )}
                      </div>

                      <div className='flex flex-col space-y-1 md:space-y-[5px]  w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Supervisor Name </span>
                        <input
                          type="text"
                          id='supervisorname'
                          value={values.supervisorname}
                          name='supervisorname'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your supervisor name'

                          className='border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none  '
                        />

                      </div>
                      <div className='flex flex-col space-y-1 md:space-y-[5px]  w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Partner Training Center </span>
                        <select
                          type="text"
                          id='partnertrainingcenter'
                          value={values.partnertrainingcenter}
                          name='partnertrainingcenter'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your partnertrainingcenter'

                          className='border-[1px] font-poppins border-gray-400 text-[14px]  p-1 h-10 px-2 rounded-lg outline-none'
                        >


                          <option value=''>Select Partner Training Center</option>
                          <option value='Bhaktapur Academy Of Tourism And Hotel Management'>Bhaktapur Academy Of Tourism And Hotel Management
                          </option>
                          <option value='Birjung College of Hotel Management'>Birjung College of Hotel Management
                          </option>
                          <option value='Culinary and Hospital Academy'>Culinary and Hospital Academy
                          </option>
                          <option value='Dewal Technical Institute'>Dewal Technical Institute
                          </option>
                          <option value='Dhalkebar College of Hotel Management'>Dhalkebar College of Hotel Management
                          </option>
                          <option value='Galaxy Hotel and Traning School'>Galaxy Hotel and Traning School
                          </option>
                          <option value='Siddhartha Institute of Hospitality'>Siddhartha Institute of Hospitality
                          </option>
                          <option value='Aspire College'>Aspire College
                          </option>
                          <option value='EECOM Technicals'>EECOM Technicals
                          </option>
                          <option value='Kumakh College of Management and Technical Science'>Kumakh College of Management and Technical Science
                          </option>

                          {/* <option value='male'>Male</option>
                          <option value='male'>Male</option>
                          <option value='male'>Male</option> */}


                        </select>

                      </div>
                      <div className='flex flex-col space-y-1 md:space-y-[5px]  w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Address </span>
                        <input
                          type="text"
                          id='address'
                          value={values.address}
                          name='address'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your address'

                          className='border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none '
                        />

                      </div>

                      <div className='flex flex-col space-y-1 md:space-y-[5px]  w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Gender </span>
                        <select
                          id='gender'
                          value={values.gender}
                          name='gender'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className='border-[1px] font-poppins border-gray-400 text-[14px]  p-1 h-10 px-2 rounded-lg outline-none'
                        >
                          <option value=''>Select your gender</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                          <option value='Culinary and Hospital Academy
'>Prefer not to say</option>
                        </select>


                      </div>

                      <div className='flex flex-col space-y-1 md:space-y-[5px]  w-full xl:w-[260px]'>
                        <span className='font-poppins md:text-[14px] font-medium'>Select Course <span className=' text-red-600 font-medium text-xl'>* </span></span>
                        <select
                          id='course'
                          value={values.course}
                          name='course'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`border-[1px] font-poppins ${touched.course && errors.course ? 'border-red-500' : 'border-gray-400'
                            } p-1 h-10 px-2 rounded-lg outline-none`}
                        >
                          <option value=''>Select your course</option>
                          <option value='Diploma in Hotel Management'>Diploma in Hotel Management</option>
                          <option value='Diploma in Professional Cookery'>Diploma in Professional Cookery</option>
                        </select>

                        {touched.course && errors.course && (
                          <span className='text-red-500 font-poppins'>
                            {errors.course}
                          </span>
                        )}
                      </div>


                      {/* <div className='flex flex space-y-1-md:col space py-4 w-full xl:w-[260px] '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Upload Profile Picture </span>

                        <div className='bg-primary-background border-2 border-dotted  flex justify-start items-center p-2 rounded-lg'>
                          <div className='flex justify-center items-center w-full                        <img src="assets/images/one.png" alt="" className='h-7' />
                            <span>Upload picture</span>
                          </div>
                        </div>

                      </div> */}







                    </div>


                  </div>
                  <div className='flex space-x-4 justify-center'>
                    <Link to="/">
                      <button type='button' className='rounded-md bg-white border-2 border-primary-title text-primary-title  p-3 px-12 text-[14px] font-poppins'>Back</button>

                    </Link>
                    <Button title="Next" className='text-white text-[14px] px-12  bg-primary-title rounded-md' />

                  </div>


                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default Register