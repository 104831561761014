import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    isSidebarCollapsed: false,
    openMobileSideBar: false

}

export const hamburgerSlice = createSlice({
    name: 'hamburger',
    initialState,
    reducers: {
        setIsSidebarCollapsed: (state, action) => {
            state.isSidebarCollapsed = !state.isSidebarCollapsed
            

        },
        setOpenMobileSidebar: (state, action) => {
            state.openMobileSideBar = !state.openMobileSideBar
        },

        
    },
})

// Action creators are generated for each case reducer function
export const { setIsSidebarCollapsed, setOpenMobileSidebar } = hamburgerSlice.actions

export default hamburgerSlice.reducer