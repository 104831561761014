
// Author: Kalyan Bikram Adhikari
// Created-Date: 2024/06/27
// Modified-Date: 

// third-party
import { configureStore } from '@reduxjs/toolkit';
import { authApis } from './APIFeatures/adminAPI';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userAPIs } from './APIFeatures/userAPIs';
import adminSlice from './reducerSlices/adminSlice';
import userSlice from './reducerSlices/userSlice';
import hamburgerSlice from './reducerSlices/hamburgerSlice';
import adminClickedSlice from './reducerSlices/adminClickedSlice';

// project import

export const store = configureStore({
  reducer: {
    [authApis.reducerPath]: authApis.reducer,
    [userAPIs.reducerPath]: userAPIs.reducer,
    admin: adminSlice,
    user:userSlice,
    adminClickedUser:adminClickedSlice ,
    hamburger: hamburgerSlice


  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApis.middleware, userAPIs.middleware),
});

setupListeners(store.dispatch);
