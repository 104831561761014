// Author: Kalyan Bikram Adhikari
// Created-Date: 2024/06/28
// Modified-Date: 


import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import LeftSection from './LeftSection';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//toast
import { toast } from 'react-toastify';


// rtk query
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../store/APIFeatures/userAPIs';
import { getUserData } from '../store/reducerSlices/userSlice';






const UserLogin = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [login, { data, isLoading, isSuccess, isError, error, status }] = useLoginMutation()


    useEffect(() => {
        console.log('status', status)
        if (isSuccess) {
            console.log(isSuccess)
            console.log('login of student sucessful')
            // toast.success('Login successful')
            dispatch(getUserData(data))
            // dispatch(getUserData({'name':loginData.user.name, 'token':loginData.token }))
            console.log('getuserdata called')
            navigate("/")
            toast.success('Student Login Sucessful')
            setIsLoggingIn(false)


        }
        if (isError) {
            console.log('login failed', error);
            const errorMessage = error?.data || 'Student Login Failed.';
            toast.error(errorMessage);
            setIsLoggingIn(false)

        }
    }, [isSuccess, isError, error, status]);

    return (
        <div>
            <Formik
                initialValues={{
                    email_or_phoneNumber: '',
                    password: '',
                    // admin_type: 'master',
                    submit: null


                }}
                validationSchema={Yup.object().shape({
                    // username: Yup.string().max(255).required('Username is required'),
                    email_or_phoneNumber: Yup.string()
                        .matches(
                            /^(?:\d{10}|\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)$/,
                            'Must be a valid email or phone number'
                        )
                        .required('Email or phone number is required'),
                    password: Yup.string().max(255).required('Password is required'),
                    // admin_type: Yup.string().max(255).required('Admin Type is required')
                })}
                onSubmit={async ({ email_or_phoneNumber, password }) => {
                    setIsLoggingIn(true)

                    console.log(email_or_phoneNumber, password)

                    const isLoggedIn = await login({ "input": email_or_phoneNumber, password });
                    console.log('isLoggedIn', isLoggedIn);



                    // if (isLoggedIn) navigate('/');
                }}

            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                    return (
                        <form noValidate onSubmit={handleSubmit}>
                            <div className='flex flex-col lg:h-screen md:flex-row'>
                                {/* left section of the login code */}
                                <LeftSection />

                                {/* right section of the login code */}

                                <div className=' w-full md:w-1/2 md:ml-auto '>
                                    <div className='md:mt-32 lg:px-32 xl:px-44 2xl:px-52 lg:p-10 p-4  md:space-y-[20px] '>
                                        <div className=' text-primary-title font-poppins text-xl font-semibold hidden md:flex md:justify-center'>
                                            <span className='text-2xl'>Student Login</span>

                                        </div>
                                        <div>


                                            <div className='flex flex-col space-y-[5px] p-4  '>
                                                <span className='font-poppins font-medium text-[14px]'>Email or Phone Number* </span>
                                                <input
                                                    type="text"
                                                    id='email_or_phoneNumber'
                                                    value={values.email_or_phoneNumber}
                                                    name='email_or_phoneNumber'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='Enter your Email or Phone Number'

                                                    className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none ${touched.email_or_phoneNumber && errors.email_or_phoneNumber ? 'border-red-500' : 'border-[#CED4DA]'
                                                        }`} />
                                                {touched.email_or_phoneNumber && errors.email_or_phoneNumber && (
                                                    <span error className='text-red-500 font-poppins' >
                                                        {errors.email_or_phoneNumber}
                                                    </span>
                                                )}
                                            </div>
                                            <div className='flex flex-col space-y-[5px] p-4'>
                                                <span className='font-poppins font-medium text-[14px]'>Password *</span>
                                                <div className='relative'>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={values.password}
                                                        name='password'
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder='Enter your Password'

                                                        className={`border-[1px] border-gray-400 p-1 font-poppins text-[14px] h-10 px-2 rounded-lg outline-none w-full ${touched.password && errors.password ? 'border-red-500' : 'border-[#CED4DA]'
                                                            }`}
                                                    />
                                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={handleClickShowPassword}>
                                                        {showPassword ? <RemoveRedEyeIcon className="w-5 h-5" /> : <VisibilityOffIcon className="w-5 h-5" />}
                                                    </div>
                                                </div>

                                                {touched.password && errors.password && (
                                                    <span error className='text-red-500 font-poppins' >
                                                        {errors.password}
                                                    </span>
                                                )}
                                            </div>
                                            <div className='flex justify-end px-4'>
                                                <span className='font-poppins text-[#448eb6] font-medium text-sm'>Forgot Password ?</span>
                                            </div>

                                            <div className=' p-4'>
                                                {/* <Button title="Sign in" className="w-full rounded-md" /> */}
                                                <button disabled={isLoggingIn} type="submit" className='flex justify-center items-center bg-primary-title sm:rounded-sm  w-full p-2 px-4 text-lg text-white md:rounded-md font-poppins transition ease-in-out delay-150 hover:-translate-y-2 hover:scale-110 duration-300 ' >
                                                    {
                                                        isLoggingIn ?
                                                            (
                                                                <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            ) : (
                                                                <span>Sign In</span>
                                                            )

                                                    }
                                                </button>
                                            </div>
                                            <div className="flex items-center px-4 ">
                                                <hr className="flex-grow border-t border-gray-300" />
                                                <span className="mx-4 text-gray-500">Or</span>
                                                <hr className="flex-grow border-t border-gray-300" />
                                            </div>

                                            <div className='flex justify-center mt-6  '>
                                                <span className='font-medium text-sm font-poppins '>Don't have an account ?
                                                    <Link to='/registerstepone'><span className='font-poppins text-[#448eb6] font-medium text-sm ml-2'>Register</span></Link>
                                                </span>

                                            </div>

                                        </div>
                                    </div>




                                </div>
                            </div>
                        </form>
                    );
                }}


            </Formik>
        </div>
    )
}

export default UserLogin