import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'; // Import ThemeProvider
import theme from './theme';

import './index.css';
import App from './App';
import { store } from './store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}> {/* Wrap App with ThemeProvider */}

        <App />
      </ThemeProvider>
    </Router>
  </Provider>
);


