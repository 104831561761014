// data-service.js
import { toast } from 'react-toastify';
import api from './api';
// import authHeader from './auth-header';
import { authHeader } from './auth-header';

class DataService {
    async getData(endpoint) {
        try {
            const response = await api.get(endpoint, { headers: authHeader() });
            console.log('response', response.data)
            return response.data;
        } catch (error) {
            console.error('Error retrieving data:', error);
            throw error;
        }
    }

    async postData(endpoint, data) {
        console.log('endpoint,data', endpoint, data)
        try {
            const response = await api.post(endpoint, data, {
                headers: authHeader()
            });
            console.log('response', response)
            return response.data;
        } catch (error) {
            console.error('Error posting data:', error);

            throw error;
        }
    }

    async patchData(endpoint, data) {
        try {
            const response = await api.patch(endpoint, data, {
                headers: authHeader()
            });
            return response.data;
        } catch (error) {
            console.error('Error patching data:', error);

            throw error;
        }
    }
    async putData(endpoint, data) {
        try {
            const response = await api.put(endpoint, data, {
                headers: authHeader()
            });
            return response.data;
        } catch (error) {
            console.error('Error patching data:', error);

            throw error;
        }
    }

    async deleteData(endpoint) {
        try {
            const response = await api.delete(endpoint, { headers: authHeader() });
            return response.data;
        } catch (error) {
            console.error('Error deleting data:', error);

            throw error;
        }
    }
}

export default new DataService();
