import React from 'react'
import { useSelector } from 'react-redux';

const StudentDecleration = () => {
    var user = localStorage.getItem('user');
    // var admin = localStorage.getItem('admin');
    var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)
    var loggedInData = null;

    if (user) {
        loggedInData = JSON.parse(user);
        console.log('Logged in data from profile left (user):', loggedInData);
    } else if (userClickedByAdmin) {
        loggedInData = userClickedByAdmin;
        console.log('data of particular user clicked by admin:', loggedInData);
    } else {
        console.log('No user logged in or admin has not clicked any user');
        // Handle the case where no one is logged in
    }
    const { courseName, name, address, candidateNumber, email } = loggedInData

    return (
        <div>
            <div className='lg:mt-5 space-y-5'>
                <p className='font-poppins text-[14px] text-[#11232E] '>I hereby certify that the internship report I submitted to the Skills to Earn International Education’s Training Qualification, (TQUK) endorsed {courseName} in partial fulfillment of the requirement  is entirely original work on my part and has not previously been submitted to any other education institution.</p>

                <p className='font-poppins  text-[14px] text-[#11232E]'>I further certify that no part of this text in whole or in part, has been taken from any previous  work produced by other students or scholars. </p>
            </div>

            <div className='flex flex-col space-y-2 mt-12'>
                <span className='font-poppins text-[#11232E] text-[14px] font-semibold '>{name}</span>
                <span className='font-poppins  text-[#11232E] text-[14px] '>{address}</span>
                <span className='font-poppins  text-[#11232E] text-[14px] '>{candidateNumber}</span>
                <span className='font-poppins  text-[#11232E] text-[14px] '>11th June, 2023</span>
                <span className='font-poppins  text-[#11232E] text-[14px] '>{address}</span>
                <span className='font-poppins  text-[#11232E] text-[14px] '>{email}</span>
                <img src="assets/images/signature.png" alt="" className='w-36' />

            </div>


            {/* <div>
                <img src="assets/images/signaturee.png" alt='' className='h-12' />
            </div> */}
        </div>

    )
}

export default StudentDecleration