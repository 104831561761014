
// Author: Kalyan Bikram Adhikari
// Created-Date: 2024/06/27
// Modified-Date: 


import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const authApis = createApi({
  reducerPath: 'authApis',
  baseQuery: fetchBaseQuery({ baseUrl:'https://s2e.tech-glazers.com/api' }),
  // baseQuery: fetchBaseQuery({  baseUrl: 'https://localhost:7209/api'}),


    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.user.token
      console.log('token', token)

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
    endpoints: (builder) => ({


      getAdminData: builder.query({
        query: () => {
          return {
            url: '/UserInformation/getall/admin'

          }
        }
      }),
      getAdminDataByID: builder.query({
        query: (id) => {
          console.log('id', id)
          return {
            url: `/UserInformation/getbyid/admin?adminId=${id}`

          }
        }
      }),

      login: builder.mutation({
        query: (data) => {
          console.log(data)
          return {
            url: '/AdminAccount/login',
            method: 'POST',
            body: data,
            headers: {
              'Content-type': 'application/json;charset=UTF-8',
            }
          }
        }
      }),
      register: builder.mutation({
        query: (data) => {
          //   for (const [key, value] of data.entries()) {
          //     console.log(key, value);
          //   }        
          return {
            url: '/register',
            method: 'POST',
            body: data,
            headers: {
              // 'Content-type':'application/json;charset=UTF-8',
              'Content-type': 'multipart/form-data'

            }
          }
        },


      }),

      changePassword: builder.mutation({
        query: (data) => {

          return {
            url: '/change-password',
            method: 'POST',
            body: data,
            headers: {
              // 'Content-type':'application/json;charset=UTF-8',
              'Content-type': 'multipart/form-data'

            }
          }
        }
      }),
      resetPassword: builder.mutation({
        query: (data) => {

          return {
            url: '/reset-password',
            method: 'POST',
            body: data,
            headers: {
              // 'Content-type':'application/json;charset=UTF-8',
              'Content-type': 'multipart/form-data'

            }
          }
        }
      }),





    }),
  })

export const { useLoginMutation, useRegisterMutation } = authApis