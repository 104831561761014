import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';


const SkelotonLoaderCertificate = () => {
    return (
        // <div className='animate-pulse rounded-md h-44   md:h-60  md:rounded-2xl flex flex-col items-center justify-center  shadow-md bg-primary-background gap-y-6 p-4 w-[calc(50%-10px)] md:w-[calc(33%-15px)] lg:w-[calc(33%-15px)]  '>
        <div className='bg-primary-background animate-pulse flex flex-col justify-center p-3 pb-4 w-[calc(50%-10px)] lg:w-[calc(25%-15px)] md:pb-8 md:space-y-3 sm:rounded-sm md:rounded-md px-4 shadow-lg'>
            <div className='hidden md:justify-end md:flex'>
                <MoreVertIcon />
            </div>
            <div className='relative  md:hidden'>
                <MoreVertIcon style={{ fontSize: '1rem' }} className='absolute -top-2 -right-4' />
            </div>
            <div className=" flex justify-center items-center w-full">
                <div className="h-32 w-full  bg-slate-200"></div>

            </div>
            <div className="flex h-1 bg-slate-200 my-6"></div>
            <div className='bg-slate-200 h-5 w-full rounded'></div>
            {/* <span></span> */}
        </div>
    )
}

export default SkelotonLoaderCertificate