import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import StudentDecleration from './StudentDecleration'
import { Title } from '@mui/icons-material'
import ProfileLeft from '../../components/ProfileLeft'
import ProfileTitle from '../ProfileTitle'

const index = () => {
  return (
    <div className=' p-2 space-y-3 md:bg-primary-background rounded-lg lg:p-10 shadow-md'>

      {/* <ProfileTitle  title="Course Details"/> */}
      <ProfileTitle title="Student's Declaration" />

      {/* <CourseDetail/> */}
      <StudentDecleration />


    </div>




  )
}

export default index