import React from 'react'
import { Link, useParams } from 'react-router-dom'

const LeftSection = () => {
    const params = useParams()

    return (
        <div className='flex flex-col  md:fixed md:h-screen md:left-0 md:w-1/2 md:p-8 md:gap-y-32 md:bg-primary-background ' >
            <Link to="/">
                <div className='hidden md:block'>
                    <img src="/assets/images/Logo.png" alt="" className='h-16' />

                </div>

            </Link>
            <div className="flex justify-center items-center mb-6 mt-12 md:hidden ">
                <span className='text-xl text-primary-title font-poppins font-semibold'>Student Login</span>

            </div>
            <div className='flex justify-center items-center'>
                <img src="/assets/images/login.png" alt="" className='h-60 md:h-80 ' />
            </div>
        </div>
    )
}

export default LeftSection