import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import Title from '../../components/Title';
import Button from '../../components/Button';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import AdminSidebar from '../../components/AdminSidebar';
import dataService from '../../services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// const initialData = [
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },
//     { id: 1, candidateID: '001', name: 'John Doe', address: '123 Main St', email: 'john@example.com', phone: '123-456-7890', program: 'Engineering' },
//     { id: 2, candidateID: '002', name: 'Jane Smith', address: '456 Elm St', email: 'jane@example.com', phone: '987-654-3210', program: 'Science' },

//     // Add more initial rows as needed
// ];

const Index = () => {
    const [isRetrivingData, setIsRetrivingData] = useState(false)
    const [data, setData] = useState([]);
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);


    console.log('data', data)
    const usersField = ["All", "Completed", "Pending"];
    const [activeField, setActiveField] = useState("All");

    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState('')
    const [selectedUserID, setSelectedUserID] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const admin = useSelector((state) => state.admin.admin)

    const handleSelectStudent = (rowid, candidateID) => {
        navigate('/dashboard', { state: { userId: candidateID } });

    }

    const handleDownloadLogbook = (id) => {
        const fetchData = async () => {
            setIsLoadingDownload(true);
            toast.info('Please wait 1 or 2 minutes. This will take time')


            try {
                const response = await dataService.getData(`/DownloadLogbook?userId=${id}`);
                console.log('response', response)
                // if(response.)
                setIsLoadingDownload(response);
            } catch (error) {
                // setErrorResponse(error.response.data[0].Module)
                toast.error('Error downloading. Contact to admin')


                console.log('error', error.response.data || 'An error occurred');

            } finally {
                setIsLoadingDownload(false);
            }
        };
        fetchData();
    };

    const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rows per page changes
    };
    const handleSelectedUserChange = (name, userId) => {
        setSelectedUser(name)
        setSelectedUserID(userId)
        setShowConfirmDialog(true)

    }
    const handleConfirmChange = (candidateID) => {
        console.log('candidateID', candidateID)
        // the second attribute is used to pass data into dashboard when it navigates to dashboard. we can access using const location = useLocation();
        // const { userId } = location.state || {}; in dashboard
        navigate('/dashboard', { state: { userId: candidateID } });



    };

    const handleCancelChange = () => {
        setShowConfirmDialog(false);
    };

    const completedData = data.filter((row) => row.completeStatus === true)
    const pendingData = data.filter((row) => row.completeStatus === false)

    const filteredData = data.filter((row) =>  // Filter data based on the search query
        row.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.trainingCenter?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const completedFilteredData = completedData.filter((row) =>  // Filter data based on the search query
        row.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.trainingCenter?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const pendingFilteredData = pendingData.filter((row) =>  // Filter data based on the search query
        row.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.trainingCenter?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const completedPaginatedData = completedFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const pendingPaginatedData = pendingFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);





    const fetchAllStudents = async () => {
        setIsRetrivingData(true)
        try {

            if (admin.role === 'Administrator') {
                const response = await dataService.getData(`/UserAccount/getalluserdata`);

                setData(response)
                setIsRetrivingData(false)

            } else if (admin.role === 'PartnerCenter') {
                const response = await dataService.getData(`/PartnerTrainingCenter/getPartnerCenterUsers?partnerName=${admin.partnerTrainingCenter}`);
                setData(response)
                setIsRetrivingData(false)
            }


        } catch (error) {
            // console.log('error', error)
            toast.error('Error retriving user data')
            setIsRetrivingData(false)
        }



    }

    useEffect(() => {
        fetchAllStudents();
    }, [])

    return (
        <div>
            <div className={`block ${openMobileSideBar ? '' : 'flex'}`}>
                <div className={`hidden md:block ${isSidebarCollapsed ? 'w-20' : 'md:w-[250px] lg:w-[300px]'}`}>
                    <AdminSidebar />
                </div>
                <div className={` ${openMobileSideBar ? 'block fixed top-0 z-20' : 'hidden'} w-lvw block md:hidden`}>
                    <AdminSidebar />
                </div>
                <div className={`w-full border-red-700 ${isSidebarCollapsed ? 'w-[100%-80px]' : 'w-5/6'} ${openMobileSideBar ? 'w-full top-0' : 'w-full'}`}>
                    <Navbar />
                    <div className='m-4 md:m-8'>
                        <div className='md:flex justify-between'>
                            <Title title="Students" />
                        </div>
                        {/* <div className='flex justify-between mt-4'>
                            <div className='flex items-end justify-between w-full'>
                                <span className='font-poppins text-gray-500 cursor-pointer px-8 pb-4 text-lg border-b-[3px] border-primary-title'>
                                    All
                                </span>
                            </div>
                        </div> */}

                        <div className='flex justify-between mt-4 '>
                            <div className='flex  items-end  w-full  space-x-[5px]'>
                                {usersField.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`font-poppins text-gray-500  cursor-pointer  lg:px-8 pb-4 text-xs md:text-[14px] ${activeField === item ? 'border-b-[3px] border-primary-title  ' : 'border-b-[3px] border-transparent'}`}
                                        onClick={() => setActiveField(item)}
                                    >
                                        {item}
                                    </span>
                                ))}
                            </div>


                        </div>
                        <hr className='border border-slate-300 -mx-8' />


                        {
                            activeField === "All" ? (
                                <div>
                                    <div className='flex space-x-4 mt-4'>
                                        <input
                                            type="text"
                                            className='rounded-md border-2 w-full p-2 outline-none font-poppins text-[14px]'
                                            placeholder='Search'
                                            value={searchQuery}  // Bind input value to searchQuery state
                                            onChange={(e) => setSearchQuery(e.target.value)}  // Update searchQuery state on input change
                                        />
                                    </div>

                                    {
                                        isRetrivingData ?
                                            (
                                                <div className="flex justify-center items-center w-full mt-32">
                                                    <svg aria-hidden="true" role="status" className='inline h-12 w-12 md:w-28 md:h-28 me-3 text-primary-title animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                </div>

                                            ) : (
                                                <div className='mt-6 shadow-md font-poppins'>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow style={{ backgroundColor: '#2b5972' }}>
                                                                    <TableCell style={{ color: '#fff' }}> ID</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Name</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Address</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Email</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Phone</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Course</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Partner Training Center</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Status</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {paginatedData.length > 0 ? paginatedData.map((row, index) => (
                                                                    <TableRow key={index} style={{ cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }} >
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{index + 1}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.name}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.address}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.email}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.phoneNumber}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.courseName}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.trainingCenter}</TableCell>

                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }}

                                                                        >
                                                                            {
                                                                                row.completeStatus === true ?
                                                                                    (
                                                                                        <span className={` bg-green-500 p-2 rounded-md`}>
                                                                                            Completed
                                                                                        </span>

                                                                                    ) :
                                                                                    <span className={` bg-yellow-400 p-2 rounded-md`}>
                                                                                        Pending
                                                                                    </span>
                                                                            }
                                                                        </TableCell>

                                                                        <TableCell>

                                                                            {
                                                                                row.completeStatus === true ? (
                                                                                    <IconButton onClick={() => handleDownloadLogbook(row.id)}>
                                                                                        {
                                                                                            isLoadingDownload ? (
                                                                                                <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                                                </svg>

                                                                                            ) : (
                                                                                                <DownloadIcon />)
                                                                                        }


                                                                                    </IconButton>
                                                                                ) : (
                                                                                    <div className='flex justify-center'>---</div>
                                                                                )
                                                                            }


                                                                        </TableCell>
                                                                    </TableRow>
                                                                )) : (
                                                                    <TableRow>
                                                                        <TableCell colSpan={5} align="center" style={{ fontFamily: 'Poppins , sans-serif' }} >
                                                                            No results found
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )

                                                                }
                                                            </TableBody>


                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination

                                                        style={{ fontFamily: 'Poppins , sans-serif' }}
                                                        rowsPerPageOptions={[10, 25, 50]}
                                                        component="div"
                                                        count={filteredData.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </div>

                                            )
                                    }

                                </div>

                            ) : activeField === "Completed" ? (
                                <div>
                                    <div className='flex space-x-4 mt-4'>
                                        <input
                                            type="text"
                                            className='rounded-md border-2 w-full p-2 outline-none font-poppins text-[14px]'
                                            placeholder='Search'
                                            value={searchQuery}  // Bind input value to searchQuery state
                                            onChange={(e) => setSearchQuery(e.target.value)}  // Update searchQuery state on input change
                                        />
                                    </div>

                                    {
                                        isRetrivingData ?
                                            (
                                                <div className="flex justify-center items-center w-full mt-32">
                                                    <svg aria-hidden="true" role="status" className='inline h-12 w-12 md:w-28 md:h-28 me-3 text-primary-title animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                </div>

                                            ) : (
                                                <div className='mt-6 shadow-md font-poppins'>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow style={{ backgroundColor: '#2b5972' }}>
                                                                    <TableCell style={{ color: '#fff' }}> ID</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Name</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Address</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Email</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Phone</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Course</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Partner Training Center</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Status</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {completedFilteredData.length > 0 ? completedFilteredData.map((row, index) => (
                                                                    <TableRow key={index} style={{ cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }} >
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}>{index + 1}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}>{row.name}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}>{row.address}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}>{row.email}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}>{row.phoneNumber}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}>{row.courseName}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}>{row.trainingCenter}</TableCell>

                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)}

                                                                        >
                                                                            {
                                                                                row.completeStatus === true ?
                                                                                    (
                                                                                        <span className={` bg-green-500 p-2 rounded-md`}>
                                                                                            Completed
                                                                                        </span>

                                                                                    ) :
                                                                                    <span className={` bg-yellow-400 p-2 rounded-md`}>
                                                                                        Pending
                                                                                    </span>
                                                                            }
                                                                        </TableCell>

                                                                        <TableCell>

                                                                            {/* <IconButton onClick={() => handleDelete(row.id)}>
                                                                                <DownloadIcon />
                                                                            </IconButton> */}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )) : (
                                                                    <TableRow>
                                                                        <TableCell colSpan={5} align="center" style={{ fontFamily: 'Poppins , sans-serif' }} >
                                                                            No results found
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )

                                                                }
                                                            </TableBody>


                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        style={{ fontFamily: 'Poppins , sans-serif' }}
                                                        rowsPerPageOptions={[10, 25, 50]}
                                                        component="div"
                                                        count={completedFilteredData.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </div>

                                            )
                                    }
                                </div>
                            ) : (
                                <div>
                                    <div className='flex space-x-4 mt-4'>
                                        <input
                                            type="text"
                                            className='rounded-md border-2 w-full p-2 outline-none font-poppins text-[14px]'
                                            placeholder='Search'
                                            value={searchQuery}  // Bind input value to searchQuery state
                                            onChange={(e) => setSearchQuery(e.target.value)}  // Update searchQuery state on input change
                                        />
                                    </div>



                                    {
                                        isRetrivingData ?
                                            (
                                                <div className="flex justify-center items-center w-full mt-32">
                                                    <svg aria-hidden="true" role="status" className='inline h-12 w-12 md:w-28 md:h-28 me-3 text-primary-title animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                </div>

                                            ) : (
                                                <div className='mt-6 shadow-md font-poppins'>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow style={{ backgroundColor: '#2b5972' }}>
                                                                    <TableCell style={{ color: '#fff' }}> ID</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Name</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Address</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Email</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Phone</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Course</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Partner Training Center</TableCell>
                                                                    <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Status</TableCell>
                                                                    {/* <TableCell style={{ color: '#fff', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>Action</TableCell> */}
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {pendingPaginatedData.length > 0 ? paginatedData.map((row, index) => (
                                                                    <TableRow key={index} style={{ cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }} >
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{index + 1}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.name}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.address}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.email}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.phoneNumber}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.courseName}</TableCell>
                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }} >{row.trainingCenter}</TableCell>

                                                                        <TableCell onClick={() => handleSelectedUserChange(row.name, row.userId)} style={{ fontFamily: 'Poppins , sans-serif' }}

                                                                        >
                                                                            {
                                                                                row.completeStatus === true ?
                                                                                    (
                                                                                        <span className={` bg-green-500 p-2 rounded-md`}>
                                                                                            Completed
                                                                                        </span>

                                                                                    ) :
                                                                                    <span className={` bg-yellow-400 p-2 rounded-md`}>
                                                                                        Pending
                                                                                    </span>
                                                                            }
                                                                        </TableCell>

                                                                        {/* <TableCell>

                                                                            <IconButton onClick={() => handleDelete(row.id)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </TableCell> */}
                                                                    </TableRow>
                                                                )) : (
                                                                    <TableRow>
                                                                        <TableCell colSpan={5} align="center" style={{ fontFamily: 'Poppins , sans-serif' }} >
                                                                            No results found
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )

                                                                }
                                                            </TableBody>


                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        style={{ fontFamily: 'Poppins , sans-serif' }}
                                                        rowsPerPageOptions={[10, 25, 50]}
                                                        component="div"
                                                        count={pendingData.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </div>

                                            )
                                    }


                                </div>
                            )
                        }


                    </div>
                </div>
            </div>

            {
                showConfirmDialog && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 ">
                        <div className="bg-white  rounded-md shadow-lg w-full h-3/10 md:w-1/3 flex flex-col justify-between   ">
                            <div className="flex font-poppins sm:text-xs md:text-base lg: text-lg bg-primary-title text-white p-6">
                                <p>Are you sure you want to access {selectedUser} ?</p>

                            </div>
                            <div className="mt-4 flex justify-center gap-x-6 pb-5 ">
                                <button onClick={() => handleConfirmChange(selectedUserID)} className="bg-primary-title text-white font-poppins md:text-base lg:text-lg py-2 rounded mr-2 px-8">
                                    YES
                                </button>
                                <button onClick={handleCancelChange} className=" text-primary-title border-2 border-primary-title font-poppins md:text-base lg:text-lg py-2 rounded px-8">
                                    NO
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }


        </div>
    );
};

export default Index;
