// author: Kalyan Bikram Adhikari
// Created-date : 
// Modified-date: 2024/08/30


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import AdminSidebar from '../../components/AdminSidebar';
import Title from '../../components/Title';
import Navbar from '../../components/Navbar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ProfileTitle from '../../profile/ProfileTitle';
import dataService from '../../services/data-service';
import { getAdminData } from '../../store/reducerSlices/adminSlice';
import { userLogout } from '../../store/reducerSlices/userSlice';
import { adminLogout } from '../../store/reducerSlices/adminSlice';
import { updateAdminData } from '../../store/reducerSlices/adminSlice';
import base64toimage from 'base64code-to-image';


const Index = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [isSaving, setIsSaving] = useState('')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showProfileConfirmDialog, setShowProfileConfirmDialog] = useState(false);


    const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger);

    const adminData = JSON.parse(localStorage.getItem('admin')) || {};

    const { name, phoneNumber, email, adminId,imageUrl, address } = adminData;

    const [updatedName, setUpdatedName] = useState(name)

    // const firstName = name?.split(' ')[0] || '';
    // const lastName = name?.split(' ').slice(1).join(' ') || '';

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Name is required'),
        phonenumber: Yup.string()
            .matches(/^\d+$/, 'Phone Number is invalid')
            .required('Phone Number is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    });

    const handleSubmitForm = ({ name }) => {

        setShowConfirmDialog(true)
        setUpdatedName(name)

    };

    const handleConfirmUpdate = async () => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append('name', updatedName);

        try {
            const response = await dataService.putData(`/AdminAccount/update?adminId=${adminId}`, formData);
            console.log('update user response', response)

            dispatch(updateAdminData({...adminData, name:response.name}))

            // dispatch(userLogout());
            // dispatch(adminLogout());
            // navigate('/user/login');
            toast.success("Admin profile updated successfully");
            window.location.reload()

            setIsLoading(false);

        } catch (error) {
            toast.error(error.response?.data || 'Failed to update profile');
            console.error("Profile update error:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleCancelChange = () => {
        setShowConfirmDialog(false);
    };
    const handleCancelProfilePicChange = () => {
        setShowProfileConfirmDialog(false);
    };
    const handleConfirmImageChange = () => {
        setShowProfileConfirmDialog(true)
    }
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);

            setIsSaving(true)


            const formData = new FormData();
            // formData.append('candidateNumber', candidateNumber);
            // formData.append('name', name);
            // formData.append('phoneNumber', phoneNumber);
            // formData.append('address', address);
            formData.append('image', file);
            

            try {
                const response = await dataService.patchData(`/AdminAccount/uploadImage?adminId=${adminId}`, formData)
                console.log('response of user update', response)
                // toast.success("Profile Updated Successfully");

                // const admindata = JSON.parse(adminData)
                dispatch(updateAdminData({...adminData, imageUrl:response.imageUrl}))
                toast.success("Profile Pric Updated Successfully");
                window.location.reload()




            } catch (error) {
                toast.error(error.response.data || "Update failed");
            } finally {
                setIsSaving(false)
            }



        }
    };


    useEffect(() => {
        if (imageUrl) {
            const imageintoUrl = base64toimage(imageUrl)
            setImageSrc(imageintoUrl);
        }
    }, [imageUrl]);




    return (
        <div className={`block ${openMobileSideBar ? '' : 'flex'}`}>
            <div className={`hidden md:block ${isSidebarCollapsed ? 'w-20' : 'md:w-[250px] lg:w-[300px]'}`}>
                <AdminSidebar />
            </div>
            <div className={` ${openMobileSideBar ? 'block fixed top-0 z-20' : 'hidden'} w-lvw block md:hidden`}>
                <AdminSidebar />
            </div>
            <div className={`w-full ${isSidebarCollapsed ? 'w-[calc(100%-80px)]' : 'w-5/6'} ${openMobileSideBar ? 'w-full top-0' : 'w-full'}`}>
                <Navbar />
                <div className='m-4 md:m-8'>
                    <div className='md:flex justify-between'>
                        <Title title="Profile" />
                    </div>
                    <div className='mt-4 md:mt-8 flex flex-col md:flex md:flex-row gap-x-5'>
                        <div className='w-full bg-slate h-full md:bg-primary-background rounded-lg md:w-52 lg:w-72 shadow-md'>
                            <div className="flex w-full gap-2 md:flex-col">
                                {/* <div className="w-1/2 md:w-full md:rounded-t-lg flex justify-center items-center">
                                    <img
                                        className='h-44 w-44 object-cover rounded-full md:rounded-none md:h-60 md:w-full'
                                        src="assets/images/profile.png"
                                        alt="Profile"
                                    />
                                </div> */}

                                <div className="relative w-1/2 md:w-full md:rounded-t-lg md:p-0 flex justify-center items-center">

                                    {/* <img className='  h-44 w-44 object-cover object-top rounded-full md:rounded-none  md:h-60 md:w-full  ' src={imageSrc}  alt="" /> */}
                                    {/* Image with hover effect */}
                                    <img
                                        className='h-44 w-44 object-cover object-top rounded-full md:rounded-none md:h-60 md:w-full'
                                        src={imageSrc}
                                        alt=""
                                    />



                                    <span className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 cursor-pointer transition-opacity duration-300 rounded-full md:rounded-none' onClick={handleConfirmImageChange}>
                                        Change Image
                                    </span>
                                </div>
                                <div className='flex flex-col mt-4 w-[calc(60%-5px)] text-sm md:text-base md:w-full'>
                                    <div className='flex flex-col text-lg space-y-3 mb-4'>
                                        <div className='flex flex-col md:items-center md:justify-center w-full'>
                                            <span className='text-primary-title font-semibold text-sm md:text-[16px]'>
                                                {name} 
                                            </span>
                                        </div>
                                        <div className='flex md:justify-center text-sm md:text-lg'>
                                            <LocationOnIcon />
                                            <span className='text-[14px]'>{address}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:bg-primary-background xl:p-8 md:p-4 shadow-md">
                            <ProfileTitle title="Account Settings" />

                            <Formik
                                initialValues={{
                                    name,
                                    phonenumber: phoneNumber,
                                    email,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmitForm}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <div className='flex flex-col bg-primary-background mt-4'>
                                            <div className='space-y-10 bg-primary-background'>
                                                <div className='flex w-full flex-wrap gap-x-3 gap-y-5'>
                                                    {/* Name */}
                                                    <div className='flex flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                                                        <div className='flex flex-col gap-y-[5px]'>
                                                            <span className='font-poppins text-[14px] font-medium'>Name <span className=' text-red-600 font-medium text-xl'>* </span></span>
                                                            <input
                                                                type="text"
                                                                id='name'
                                                                value={values.name}
                                                                name='name'
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                placeholder='Enter your name'
                                                                className={`border-[1px] font-poppins text-[14px] border-gray-400 p-1 h-10 px-2 rounded-lg outline-none ${touched.name && errors.name ? 'border-red-500' : 'border-gray-400'
                                                                    }`}
                                                            />
                                                            {touched.name && errors.name && (
                                                                <span className='text-red-500 font-poppins'>{errors.name}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Phone Number */}
                                                    <div className='flex flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                                                        <div className='flex flex-col gap-y-[5px'>
                                                            <span className='font-poppins text-[14px] font-medium'>Phone Number <span className=' text-red-600 font-medium text-xl'> </span></span>
                                                            <input
                                                                type="text"
                                                                id='phonenumber'
                                                                value={values.phonenumber}
                                                                name='phonenumber'
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                placeholder='Enter your phone number'
                                                                className={`border-[1px] font-poppins text-[14px] border-gray-400 p-1 h-10 px-2 rounded-lg outline-none ${touched.phonenumber && errors.phonenumber ? 'border-red-500' : 'border-gray-400'
                                                                    }`}
                                                                disabled
                                                            />
                                                        </div>
                                                        {touched.phonenumber && errors.phonenumber && (
                                                            <span className='text-red-500 font-poppins'>{errors.phonenumber}</span>
                                                        )}
                                                    </div>

                                                    {/* Email */}
                                                    <div className='flex flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                                                        <div className='flex flex-col gap-y-[5px]'>
                                                            <span className='font-poppins text-[14px] font-medium'>Email Address</span>
                                                            <input
                                                                type="email"
                                                                id='email'
                                                                value={values.email}
                                                                name='email'
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                placeholder='johndoe@gmail.com'
                                                                className='border-[1px] font-poppins text-[14px] border-gray-400 p-1 h-10 px-2 rounded-lg outline-none'
                                                                disabled
                                                            />
                                                        </div>
                                                        {touched.email && errors.email && (
                                                            <span className='text-red-500 font-poppins'>{errors.email}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isLoading}
                                            className='mt-8 rounded-md px-8 bg-primary-title p-3 text-white text-[14px]'
                                        >
                                            {isLoading ? 'Saving...' : 'Save'}
                                        </button>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmDialog && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
                    <div className="bg-white  rounded-md shadow-lg w-full h-3/10 md:w-1/3 flex flex-col justify-between   ">
                        <div className="flex font-poppins sm:text-xs md:text-base lg: text-md bg-primary-title text-white p-6">
                            <div className="flex flex-col font-poppins text-sm md:text-base">
                                <p>Are you sure you want to update Profile?</p>
                                {/* <p>You will need to re-login once you update profile.</p> */}
                            </div>


                        </div>
                        <div className="mt-4 flex justify-center gap-x-6 pb-5 ">
                            <button onClick={() => handleConfirmUpdate()} className="bg-primary-title text-white font-poppins md:text-base lg:text-md py-2 rounded mr-2 px-8">
                                YES
                            </button>
                            <button onClick={handleCancelChange} className=" text-primary-title border-2 border-primary-title font-poppins md:text-base lg:text-md py-2 rounded px-8">
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {showProfileConfirmDialog && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
                    <div className="bg-white  rounded-md shadow-lg w-full h-3/10 md:w-1/3 flex flex-col justify-between   ">
                        <div className="flex font-poppins sm:text-xs md:text-base lg: text-md bg-primary-title text-white p-6">
                            <div className="flex flex-col font-poppins text-sm md:text-base">
                                <p>Are you sure you want to update Profile?</p>
                                {/* <p>You will need to re-login once you update profile.</p> */}
                            </div>


                        </div>
                        <div className="mt-4 flex justify-center gap-x-6 pb-5 ">
                            {/* Hidden file input */}
                            <input
                                type="file"
                                accept="image/*"
                                className="hidden"
                                id="imageInput"
                                onChange={handleImageChange}
                            />
                            <button className="bg-primary-title text-white font-poppins md:text-base lg:text-md py-2 rounded mr-2 px-8">
                                {
                                    isSaving ?
                                        (
                                            <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                        ) : (
                                            <label
                                                className='cursor-pointer'
                                                htmlFor="imageInput"
                                            >
                                                Change
                                            </label>
                                        )
                                }
                            </button>
                            <button onClick={handleCancelProfilePicChange} className=" text-primary-title border-2 border-primary-title font-poppins md:text-base lg:text-md py-2 rounded px-8">
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;
