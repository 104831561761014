import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../components/Button';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import LeftSection from './LeftSection';
import { getRegisterStepOneData } from '../store/reducerSlices/userSlice';
import { useDispatch } from 'react-redux';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import dataService from '../services/data-service';
import { toast } from 'react-toastify';

const AdminRegister = () => {
    const [isRegistering, setIsRegistering] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const [pic, setPic] = useState(null)
    const [profilePic, setProfilePic] = useState(null); // State to store selected profile picture
    // console.log('profilePic', profilePic)
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setPic(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePic(reader.result); // Update profilePic with the image URL 
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <Formik
                initialValues={{
                    name: '',
                    phonenumber: '',
                    address: '',
                    // gender: '',
                    profilepic: '',
                    email: '',
                    partnertrainingcenter: '',
                    password: '',
                    confirmpassword: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Name is required'),
                    phonenumber: Yup.number().required('Phone Number is required').required('Phone Number is required'),
                    address: Yup.string().max(255).required('Address is required'),
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required'),
                    confirmpassword: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .max(255)
                        .required('Confirm Password is required')
                })}
                onSubmit={async ({ name, phonenumber, email, partnertrainingcenter, password, confirmpassword, address }) => {
                    setIsRegistering(true)
                    console.log('admin register data', address, pic)
                    const formData = new FormData();
                    formData.append('name', name);
                    formData.append('email', email);
                    formData.append('address', address);
                    formData.append('partnerTrainingCenter', partnertrainingcenter);

                    formData.append('phoneNumber', phonenumber);
                    formData.append('password', password);
                    formData.append('confirmPassword', confirmpassword);
                    formData.append('file', pic);


                    const postDataIntoDB = async () => {
                        try {
                            const response = await dataService.postData('/AdminAccount/createadmin', formData)
                            console.log('Admin Register success response', response)
                            navigate('/admin')
                            toast.success("Registration Sucessfull")

                        } catch (error) {
                            toast.error(error.response.data)
                            console.log("Admin Register failure error", error);
                        } finally {
                            setIsRegistering(false)
                        }
                    }
                    postDataIntoDB();


                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className='flex flex-col lg:h-screen md:flex-row '>
                            {/* left section of the login code */}
                            <div className='flex flex-col  md:fixed md:h-screen md:left-0 md:w-1/2 md:p-8 md:gap-y-32 md:bg-primary-background ' >
                                <Link to="/">
                                    <div className='hidden md:block'>
                                        <img src="/assets/images/Logo.png" alt="" className='h-16' />

                                    </div>

                                </Link>
                                <div className="flex justify-center items-center mb-6 mt-12 md:hidden w-full">
                                    <span className='text-xl text-primary-title font-poppins font-semibold'>Add New Admin</span>

                                </div>
                                <div className='flex justify-center items-center'>
                                    <img src="/assets/images/login.png" alt="" className='h-60 md:h-80 ' />
                                </div>
                            </div>

                            {/* right section of the login code */}

                            <div className='w-full right-0 md:w-1/2 md:ml-auto'>
                                <div className='md:mt-12 lg:px-16  2xl:px-28 p-4 lg:p-10 md:space-y-[20px] '>
                                    {/* <div className=' flex justify-center items-center border-2  hidden md:block text-primary-title font-poppins text-xl font-semibold w-full'>
                                        <span className='text-2xl '>Add New Admin</span>


                                    </div > */}
                                    <div className='hidden md:flex justify-center text-primary-title font-poppins text-xl font-semibold'>
                                        <span className='text-2xl'>Add New Admin</span>


                                    </div >



                                    {/* secttion below of basic information */}

                                    <div className=' flex w-full text-sm '>
                                        <div className='flex flex-wrap justify-between gap-y-5'>
                                            <div className='flex flex-col space-y-1 md:gap-y-[5px] w-full xl:w-[260px] '>
                                                <span className='font-poppins  md:text-[14px] font-medium'>Name<span className=' text-red-600 font-medium text-xl'>* </span></span>
                                                <input
                                                    type="text"
                                                    id='name'
                                                    value={values.name}
                                                    name='name'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='Enter your name'

                                                    className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none ${touched.name && errors.name ? 'border-red-500' : 'border-gray-400'
                                                        }`} />
                                                {touched.name && errors.name && (
                                                    <span error className='text-red-500 text-[14px] font-poppins' >
                                                        {errors.name}
                                                    </span>
                                                )}
                                            </div>

                                            <div className='flex flex-col space-y-1 md:gap-y-[5px] w-full xl:w-[260px] '>
                                                <span className='font-poppins  md:text-[14px] font-medium'>Phone Number <span className=' text-red-600 font-medium text-xl'>* </span> </span>
                                                <input
                                                    type="text"
                                                    id='phonenumber'
                                                    value={values.phonenumber}
                                                    name='phonenumber'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='Enter your phonenumber'

                                                    className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none ${touched.phonenumber && errors.phonenumber ? 'border-red-500' : 'border-gray-400'
                                                        }`} />
                                                {touched.phonenumber && errors.phonenumber && (
                                                    <span error className='text-red-500 text-[14px] font-poppins' >
                                                        {errors.phonenumber}
                                                    </span>
                                                )}
                                            </div>

                                            <div className='flex flex-col space-y-1 md:gap-y-[5px] w-full xl:w-[260px]  '>
                                                <span className='font-poppins  md:text-[14px] font-medium'>Email Address <span className=' text-red-600 font-medium text-xl'>* </span></span>
                                                <input
                                                    type="email"
                                                    id='email'
                                                    value={values.email}
                                                    name='email'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='Enter your email'

                                                    className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none ${touched.email && errors.email ? 'border-red-500' : 'border-gray-400'
                                                        }`} />
                                                {touched.email && errors.email && (
                                                    <span error className='text-red-500 text-[14px] font-poppins' >
                                                        {errors.email}
                                                    </span>
                                                )}
                                            </div>

                                            {/* address */}
                                            <div className='flex flex-col space-y-1 md:gap-y-[5px] w-full xl:w-[260px] '>
                                                <span className='font-poppins  md:text-[14px] font-medium'>Address <span className=' text-red-600 font-medium text-xl'>* </span></span>
                                                <input
                                                    type="text"
                                                    id='address'
                                                    value={values.address}
                                                    name='address'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='Enter your address'

                                                    className='border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none '
                                                />

                                                {touched.address && errors.address && (
                                                    <span error className='text-red-500 text-[14px] font-poppins' >
                                                        {errors.address}
                                                    </span>
                                                )}

                                            </div>

                                            {/* parterner training center */}

                                            <div className='flex flex-col space-y-1 md:gap-y-[5px] w-full xl:w-[260px] '>
                                                <span className='font-poppins  md:text-[14px] font-medium'>Partner Training Center </span>
                                                <select
                                                    type="text"
                                                    id='partnertrainingcenter'
                                                    value={values.partnertrainingcenter}
                                                    name='partnertrainingcenter'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='Enter your partnertrainingcenter'

                                                    className='border-[1px] font-poppins border-gray-400 text-[14px] font-poppins  p-1 h-10 px-2 rounded-lg outline-none'
                                                >


                                                    <option value=''>None</option>
                                                    <option value='Bhaktapur Academy Of Tourism And Hotel Management
'>Bhaktapur Academy Of Tourism And Hotel Management
                                                    </option>
                                                    <option value='Birjung College of Hotel Management
'>Birjung College of Hotel Management
                                                    </option>
                                                    <option value='Culinary and Hospital Academy
'>Culinary and Hospital Academy
                                                    </option>
                                                    <option value='Dewal Technical Institute
'>Dewal Technical Institute
                                                    </option>
                                                    <option value='Dhalkebar College of Hotel Management
'>Dhalkebar College of Hotel Management
                                                    </option>
                                                    <option value='Galaxy Hotel and Traning School
'>Galaxy Hotel and Traning School
                                                    </option>
                                                    <option value='Siddhartha Institute of Hospitality
'>Siddhartha Institute of Hospitality
                                                    </option>
                                                    <option value='Aspire College
'>Aspire College
                                                    </option>
                                                    <option value='EECOM Technicals
'>EECOM Technicals
                                                    </option>
                                                    <option value='Kumakh College of Management and Technical Science
'>Kumakh College of Management and Technical Science
                                                    </option>

                                                    {/* <option value='male'>Male</option>
                          <option value='male'>Male</option>
                          <option value='male'>Male</option> */}


                                                </select>

                                            </div>


                                            {/* password */}
                                            <div className='flex flex-col space-y-1 md:gap-y-[5px] w-full relative xl:w-[260px]'>
                                                <span className='font-poppins  md:text-[14px] font-medium'>Password <span className=' text-red-600 font-medium text-xl'>* </span> </span>
                                                <div className='relative'>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        id='password'
                                                        value={values.password}
                                                        name='password'
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder='Enter your password'

                                                        className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none w-full ${touched.password && errors.password ? 'border-red-500' : 'border-gray-400'
                                                            }`}
                                                    />
                                                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={handleClickShowPassword}>
                                                        {showPassword ? <VisibilityOffIcon className="w-5 h-5" /> : <RemoveRedEyeIcon className="w-5 h-5" />}
                                                    </div>
                                                </div>

                                                {touched.password && errors.password && (
                                                    <span error className='text-red-500 text-[14px] font-poppins' >
                                                        {errors.password}
                                                    </span>
                                                )}
                                            </div>

                                            {/* confirm password */}
                                            <div className="flex flex-col space-y-1 md:gap-y-[5px] w-full relative xl:w-[260px]">
                                                <span className="font-poppins md:text-[14px]  font-medium">Confirm Password <span className=' text-red-600 font-medium text-xl'>* </span> </span>
                                                <div className="relative">
                                                    <input
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        id="confirmpassword"
                                                        value={values.confirmpassword}
                                                        name="confirmpassword"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder="Re-enter your password"
                                                        className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none w-full ${touched.password && errors.password ? 'border-red-500' : 'border-gray-400'
                                                            }`}
                                                    />
                                                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={handleClickShowConfirmPassword}>
                                                        {showConfirmPassword ? <VisibilityOffIcon className="w-5 h-5" /> : <RemoveRedEyeIcon className="w-5 h-5" />}
                                                    </div>
                                                </div>
                                                {touched.confirmpassword && errors.confirmpassword && (
                                                    <span className="text-red-500 text-[14px] font-poppins">{errors.confirmpassword}</span>
                                                )}
                                            </div>




                                            {/* <div className='flex flex-col space-y-1 md:gap-y-[5px] w-full xl:w-[260px] '>
                                                <span className='font-poppins  md:text-[14px] font-medium'>Gender </span>
                                                <select
                                                    id='gender'
                                                    value={values.gender}
                                                    name='gender'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className='border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none'
                                                >
                                                    <option value=''>Select your gender</option>
                                                    <option value='male'>Male</option>
                                                    <option value='female'>Female</option>
                                                    <option value='prefer_not_to_say'>Prefer not to say</option>
                                                </select>


                                            </div> */}


                                            <div className='flex flex-col gap-y-[5px] w-[260px]'>
                                                <span className="font-poppins md:text-[14px]  font-medium">Upload Profile Picture</span>
                                                <div className='bg-primary-background border-2 border-dotted flex justify-start items-center p-2 rounded-lg gap-y-[5px]'>
                                                    <input
                                                        type="file"
                                                        id='profilepic'
                                                        accept="image/*"
                                                        onChange={handleFileChange}
                                                        className="hidden"
                                                    />
                                                    <label htmlFor="profilepic" className="cursor-pointer">
                                                        {profilePic ? (
                                                            <img src={profilePic} alt="Profile Preview" className='h-20 rounded-lg' />
                                                        ) : (
                                                            <div className='flex justify-center items-center w-full py-2'>
                                                                <img src="assets/images/one.png" alt="" className='h-7' />
                                                                <span className='ml-2'>Upload picture</span>
                                                            </div>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>


                                        </div>


                                    </div>
                                    <div className='flex space-x-4 justify-center'>
                                        <Link to="/admin">
                                            {/* <Button title="Home" className=' rounded-md bg-white border-2 border-primary-title text-[#0b1538]' />
                                             */}
                                            <button type='button' className='rounded-md bg-white border-2 border-primary-title text-primary-title  p-3 px-12 text-[14px]  font-poppins'>Home</button>


                                        </Link>
                                        {/* <Button title="Register" className='text-white  px-11 bg-primary-title rounded-md' /> */}
                                        <button type='submit' className='bg-primary-title p-2 px-12 text-[14px] text-white rounded-md font-poppins'>
                                            {
                                                isRegistering ? (
                                                    <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                ) : (
                                                    <span>Add Admin</span>

                                                )

                                            }
                                        </button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div >
    )
}

export default AdminRegister