import React from 'react'
import PropTypes from 'prop-types';

const Button = ({ title, className }) => {
  return (
    <button className={` bg-primary-title p-2 px-4 text-[14px] text-white rounded-[5px] font-poppins ${className}`}>
      {title}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};



export default Button;
