import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        // padding: 30,
        fontFamily: 'Helvetica'
    },
    header: {
        paddingHorizontal: 30,

        fontSize: 22,
        marginBottom: 20,
        textAlign: 'left',
        color: '#2b5972',
        fontWeight: 'bold'
    },
    field: {

        flexDirection: 'row',
        marginBottom: 10
    },
    label: {
        fontWeight: 'bold',
        width: '40%',
        fontSize: 12,
        color: '#333'
    },
    value: {
        width: '60%',
        fontSize: 12,
        color: '#666'
    },
    section: {
        paddingHorizontal: 30,

        marginTop: 10,

        marginBottom: 10,
        fontSize: 12
    },
    gradingSystems: {
        // padding: 30,

        // marginTop: 10,
        marginBottom: 10,
        fontSize: 14,
        fontWeight: 'ultrabold',

    },
    gradingText: {
        fontSize: 12,
        color: '#333'
    }
});

const PerformanceAppraisalPDF = ({ fields, fieldValues }) => (
    <Document>
        <Page style={styles.page}>
            <Text style={styles.header}>Performance Appraisal Form</Text>
            <View style={{ backgroundColor: '#f6f8f9', padding: 30 }}>
                {fields.map((field, index) => (
                    <View style={styles.field} key={index}>
                        <Text style={styles.label}>{field}</Text>
                        <Text style={styles.value}>{fieldValues[index]}</Text>
                    </View>
                ))}
            </View>

            <View style={styles.section}>
                <Text>Please describe the trainee’s performance in the following areas. You are welcome to share your own observations on the trainee that may help his or her overall success during the placement.</Text>
            </View>
            <View style={[styles.section, { display: 'flex', flexDirection: 'row', flex: 'wrap', justifyContent: 'space-between', paddingBottom: '-20px' }]}>
                <Text style={styles.gradingSystems}>Grading Systems:</Text>
                <Text style={styles.gradingText}>1-Unsatisfactory</Text>
                <Text style={styles.gradingText}>2-Fair</Text>
                <Text style={styles.gradingText}>3-Good</Text>
                <Text style={styles.gradingText}>4-Excellent</Text>
            </View>
        </Page>
    </Document>
);

export default PerformanceAppraisalPDF;
