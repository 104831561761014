import React, { useEffect, useState } from 'react';
import IconList from './IconList';
import dataService from '../../services/data-service';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../store/reducerSlices/userSlice';
import { adminLogout } from '../../store/reducerSlices/adminSlice';
import { useNavigate } from 'react-router-dom';

const CourseDetail = () => {
  const [selectedProgram, setSelectedProgram] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [newProgram, setNewProgram] = useState('');
  const [newProgramID, setNewProgramID] = useState('')
  const [confirmChange, setConfirmChange] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var user = localStorage.getItem('user');
  var admin = localStorage.getItem('admin');
  var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)
  var loggedInData = null;

  if (user) {
    loggedInData = JSON.parse(user);
    console.log('Logged in data from profile left (user):', loggedInData);
  } else if (userClickedByAdmin) {
    loggedInData = userClickedByAdmin;
    console.log('data of particular user clicked by admin:', loggedInData);
  } else {
    console.log('No user logged in or admin has not clicked any user');
    // Handle the case where no one is logged in
  }
  const { userId, courseCode, courseName, gender, supervisorName, trainingCenter, role, courseId } = loggedInData || {};
  console.log('courseId', courseId)


  // this below admin data will only be used for the validation case of if admin with role PartnerCenter and userclicked by admin also then we dont allow them to edit any user
  const adminData = JSON.parse(admin)
  const adminRole = adminData?.role

  // console.log('adminRole', adminData)


  useEffect(() => {
    if (courseId === 1) {
      setSelectedProgram('Diploma in Professional Cookery (DPC)');
    } else if (courseId === 2) {
      setSelectedProgram('Diploma in Hotel Management (DHM)');
    } else if (courseId === 3) {
      setSelectedProgram('Higher Diploma in Hotel Management');
    }


    if (newProgram === 'Diploma in Hotel Management (DHM)') {
      setNewProgramID(2)
    } else if (newProgram === 'Diploma in Professional Cookery (DPC)') {
      setNewProgramID(1)

    } else if (newProgram === 'Higher Diploma in Hotel Management') {
      setNewProgramID(3)
    }
  }, [courseId, newProgram]);
  console.log('courseId', courseId, 'selectedprogram', selectedProgram)
  console.log('newprogramID', newProgramID, 'newProgram', newProgram)

  const details = [
    { label: 'Course Name', value: courseName },
    { label: 'Duration', value: '6 months' },
  ];

  const learningObjectives = [
    "To experience functional department of the hotels.",
    "To get practical hands-on experience and observation of the hotel business.",
    "To be able to work in a team.",
  ];

  const learningOutcomes = [
    "After completion of this internship, students are expected to be versed with the key operational areas of a hotel.",
    "Able to understand the hospitality culture.",
    "Acquire skills in all or any of the following departments: Food and beverages service, Food production, Housekeeping and Front office department of the hotel.",
  ];

  const handleSelectedProgramChange = (e) => {
    const program = e.target.value;
    setNewProgram(program);
    setShowConfirmDialog(true);
  };

  const handleConfirmChange = () => {

    // when this get request is called with the changed name of program, when we try to relogin
    // or we try to look at the data of the loggedInUser, courseId will automatically get changed to the id of the updated course

    const fetchData = async () => {
      const response = await dataService.getData(`UserInformation/getcourse?courseId=${newProgramID}&userId=${userId}`)
      console.log('response', response)
    }
    fetchData();

    // logging out to make sure courseId has now changed:
    navigate('/user/login');
    dispatch(userLogout());
    dispatch(adminLogout());
    toast.success('Logged Out Sucessfully')
    setSelectedProgram(newProgram);
    setShowConfirmDialog(false);
  };

  const handleCancelChange = () => {
    setShowConfirmDialog(false);
  };

  return (
    <div>
      <div className={showConfirmDialog ? 'blurred' : ''}>
        <div className='mt-5 space-y-4'>
          <div className="flex flex-col md:flex-row md:items-center justify-between text-[#212529]">
            <div className='flex space-x-6'>
              <span className='font-poppins font-normal text-sm md:text-[14px] text-[#212529]'>Course Code</span>
              <span className='font-poppins font-normal border-b-2 border-black text-sm md:text-[14px] '>{courseCode}</span>
            </div>
            <div className='flex justify-between items-center space-x-5 mt-3 md:mt-0'>
              <span className='font-poppins text-sm md:text-[14px] text-[#212529]'>Program:</span>
              <select
                value={selectedProgram}
                onChange={handleSelectedProgramChange}
                disabled={adminRole === 'PartnerCenter'}

                className="border-2 border-gray-400 py-1 md:py-2 px-2 md:px-4 bg-gray-200 font-poppins text-xs md:text-sm focus:outline-none focus:ring-0 rounded-md"
              >
                <option value="Diploma in Professional Cookery (DPC)" className='py-2 text-xs md:text-sm'>Diploma in Professional Cookery (DPC)</option>
                {adminRole === 'Administrator' && (
                  <option value="Higher Diploma in Hotel Management" className='py-2 text-xs md:text-sm'>Higher Diploma in Hotel Management</option>
                )}
                <option value="Diploma in Hotel Management (DHM)" className='py-2 text-xs md:text-sm'>Diploma in Hotel Management (DHM)</option>
              </select>
            </div>
          </div>

          {details.map((detail, index) => (
            <div key={index} className='flex space-x-6'>
              <div className='flex-2 mt-4'>
                <span className='font-poppins font-normal text-sm md:text-[14px] text-[#212529]'>{detail.label}</span>
              </div>
              <div className='flex-7 text-start mt-4'>
                <span className='font-poppins font-normal text-sm md:text-[14px] text-gray-400'>{detail.value}</span>
              </div>
            </div>
          ))}
        </div>

        <div className='mt-4'>
          <hr className='border-t-2 border-gray-300' />
        </div>

        <div className='text-sm '>
          <IconList title="Learning Objectives" items={learningObjectives} />
          <div className="mt-4">
            <hr className="border-t-2 border-gray-300" />
          </div>
          <IconList title="Learning Outcomes" items={learningOutcomes} />
        </div>
      </div>

      {showConfirmDialog && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-white  rounded-md shadow-lg w-full h-3/10 md:w-1/3 flex flex-col justify-between   ">
            <div className="flex font-poppins sm:text-xs md:text-base lg: text-md bg-primary-title text-white p-6">
              <div className="flex flex-col font-poppins text-sm md:text-[14px]">
                <p>Are you sure you want to change the program?</p>
                <p>You will need to re-login once you change program.</p>
              </div>


            </div>
            <div className="mt-4 flex justify-center gap-x-6 pb-5 ">
              <button onClick={() => handleConfirmChange()} className="bg-primary-title text-white font-poppins md:text-base lg:text-md py-2 rounded mr-2 px-8">
                YES
              </button>
              <button onClick={handleCancelChange} className=" text-primary-title border-2 border-primary-title font-poppins md:text-base lg:text-md py-2 rounded px-8">
                NO
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDetail;
