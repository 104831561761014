
// Author: Kalyan Bikram Adhikari
// Created-Date: 2024/06/27
// Modified-Date: 

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const userAPIs = createApi({
    reducerPath: 'userAPIs',

    // baseQuery: fetchBaseQuery({
    //     baseUrl: 'https://localhost:7209/api',
        baseQuery: fetchBaseQuery({
            baseUrl: 'https://s2e.tech-glazers.com/api',
            // baseUrl: 'http://192.168.1.96:8001/',


        // prepareHeaders: (headers, { getState }) => {
        //     const token = getState()
        //     console.log('token', token.user.user.token)

        //     // If we have a token set in state, let's assume that we should be passing it.
        //     if (token) {
        //         headers.set('Authorization', `Bearer ${token}`)
        //     }else{
        //         console.log('No Authorization Header')
        //     }

        //     return headers
        // }
    }),


    endpoints: (builder) => ({

        // this is equivalent to 
        getUserData: builder.query({
            query: () => {
                return {
                    url: '/UserInformation/getall/user'

                }
            }
        }),
        getUserDataByID: builder.query({
            query: (id) => {
                console.log('id', id)
                return {
                    url: `/UserInformation/getbyid/user?userId=${id}`

                }
            }
        }),
        // login for user
        login: builder.mutation({
            query: (data) => {
                console.log(data)
                return {
                    url: '/UserAccount/login',
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                    }
                }
            }
        }),
        register: builder.mutation({
            query: (data) => {
                console.log(data)
                return {
                    url: '/Certificate/create',
                    method: 'POST',
                    body: data,
                    headers: {
                        // 'Content-type':'application/json;charset=UTF-8',
                        'Content-type': 'multipart/form-data'

                    }
                }
            },


        }),

        // uploadCertificate: builder.mutation({
        //     query: (data) => {

        //         return {
        //             url: '/register',
        //             method: 'POST',
        //             body: data,
        //             headers: {
        //                 // 'Content-type':'application/json;charset=UTF-8',
        //                 'Content-type': 'multipart/form-data'

        //             }
        //         }
        //     },


        // }),


        changePassword: builder.mutation({
            query: (data) => {

                return {
                    url: '/change-password',
                    method: 'POST',
                    body: data,
                    headers: {
                        // 'Content-type':'application/json;charset=UTF-8',
                        'Content-type': 'multipart/form-data'

                    }
                }
            }
        }),
        resetPassword: builder.mutation({
            query: (data) => {

                return {
                    url: '/reset-password',
                    method: 'POST',
                    body: data,
                    headers: {
                        // 'Content-type':'application/json;charset=UTF-8',
                        'Content-type': 'multipart/form-data'

                    }
                }
            }
        }),

        // saveAbstract: builder.mutation({
        //     query: (data) => {
        //         return {
        //             url: '/userinformation/create/abstract',
        //             method: 'POST',
        //             body: data,
        //             headers: {
        //                 'Content-type':'application/json;charset=UTF-8',
        //                 //'Content-type': 'multipart/form-data'

        //             }
        //         }
        //     },


        // }),
        saveAbstract: builder.mutation({
            query: (data) => {
                console.log('data', data)
                return {
                    url: '/userinformation/create/abstract',
                    method: 'PATCH',
                    body: data,
                    // by default content-type is application/json so doesnot matter if we do not even mention it
                    // headers: {
                    //     'Content-type': 'application/json;charset=UTF-8',
                    // }
                }
            }
        }),



    }),
})

export const { useLoginMutation, useGetUserDataQuery, useSaveAbstractMutation } = userAPIs