import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import CourseDetail from './CourseDetail'
import ProfileLeft from '../../components/ProfileLeft'
import { Title } from '@mui/icons-material'
import ProfileTitle from '../ProfileTitle'

const index = () => {
  return (


    // <div className='flex'>




        <div className=' p-2 space-y-3 md:bg-primary-background rounded-lg lg:p-10 shadow-md'>

          {/* <ProfileTitle  title="Course Details"/> */}
          <ProfileTitle title="Course Details" />

          {/* <CourseDetail/> */}
          <CourseDetail />





      </div>
    // </div>

  )
}

export default index