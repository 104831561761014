// Author: Kalyan Bikram Adhikari
// modified data: 2024/08/30

import { createSlice } from '@reduxjs/toolkit';
import { store } from '../index'

const broadcastChannel = new BroadcastChannel('user-data-channel');

// Retrieve user data from localStorage
const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null, steponeregisterdata: null };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserData: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
      // Broadcast the updated user data to other tabs
      broadcastChannel.postMessage({ type: 'GET_USER', payload: action.payload });
    },
    // in below, action will contain user's updated data sent as like: 
    // dispatch(updateUserData({ ...user, imageUrl: response.imageUrl }));  
    updateUserData: (state, action) => {

      console.log('action.payload', action.payload)
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
      broadcastChannel.postMessage({ type: 'UPDATE_USER', payload: action.payload });

    },
    updateClickedUser: (state, action) => {

      console.log('action.payload', action.payload)
      state.user = action.payload;
      localStorage.setItem('clickedUser', JSON.stringify(action.payload));
      broadcastChannel.postMessage({ type: 'UPDATE_CLICKED_USER', payload: action.payload });

    },

    userLogout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('admin')
      // Broadcast the logout event to other tabs
      broadcastChannel.postMessage({ type: 'LOGOUT' });
    },
    getRegisterStepOneData: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.steponeregisterdata = action.payload;
    },
  },
});

// Delay the initial login check to allow cross-tab communication
function checkUserDataWithDelay() {
  return new Promise((resolve) => {
    setTimeout(() => {
      const userData = localStorage.getItem('user');
      resolve(userData ? JSON.parse(userData) : null);
    }, 100); // Adjust delay as needed for your setup
  });
}

// Listen for broadcasted messages
broadcastChannel.onmessage = async (event) => {
  if (event.data.type === 'GET_USER') {
    // localStorage.setItem('user', JSON.stringify(event.data.payload));
    store.dispatch(getUserData(event.data.payload));

    // Optionally, you can dispatch a Redux action to update the state
  } if (event.data.type === 'UPDATE_USER') {
    store.dispatch(updateUserData(event.data.payload));
    // localStorage.setItem('user', JSON.stringify(event.data.payload));

    // Optionally, you can dispatch a Redux action to update the state
  } if (event.data.type === 'UPDATE_CLICKED_USER') {
    store.dispatch(updateClickedUser(event.data.payload));
    // localStorage.setItem('user', JSON.stringify(event.data.payload));

    // Optionally, you can dispatch a Redux action to update the state
  } else if (event.data.type === 'LOGOUT') {
    // Clear user data in the current tab
    localStorage.removeItem('user');

    // Optionally, dispatch a Redux action to reset the state
    store.dispatch(userLogout());

    // Optionally navigate to the login page in all tabs
    window.location.href = '/user/login';
  } else if (event.data.type === 'REQUEST_USER_DATA') {
    // If this tab has the user data, send it to the new tab
    const userData = localStorage.getItem('user');
    if (userData) {
      broadcastChannel.postMessage({ type: 'UPDATE_USER', payload: JSON.parse(userData) });
    }
  }
};

// When a new tab is opened and finds no session data, it requests the data
if (!localStorage.getItem('user')) {
  broadcastChannel.postMessage({ type: 'REQUEST_USER_DATA' });

  // Delay the initial user check until data is retrieved
  checkUserDataWithDelay().then((userData) => {
    if (userData) {
      localStorage.setItem('user', JSON.stringify(userData));
      // Optionally, update your Redux state here if needed
    }
  });
}

// Action creators are generated for each case reducer function
export const { getUserData, userLogout, getRegisterStepOneData, updateUserData, updateClickedUser } = userSlice.actions;

export default userSlice.reducer;
