import React, { useState } from 'react'
import TopField from './individual_week/TopField'
import Title from '../components/Title'
import SubTitle from './SubTitle';
import TextEditor from '../components/TextEditor';
import ImageUploader from '../components/ImageUploader';
import Button from '../components/Button';

const SampleLogBook = () => {
    // const samplelogField = ["Sample LogBook"];
    // const [activeField, setActiveField] = useState("Sample LogBook");
    const [date, setDate] = useState('2024-03-01');
    const [endDate, setEndDate] = useState('2024-08-32');

    const [selectedShift, setSelectedShift] = useState('morning');
    const [department, setDepartment] = useState('Kitchen');
    const [section, setSection] = useState('Section Grill');
    const defaultParagraph = "<p>I worked as an apprentice where my duty was to help in food production and assist other kitchen staff such as Commis or Chef. I assist in the preparation of food and handle food wastage areas. </p> <p>I learned how a kitchen operates and learned how to present the food, prepare the food, and food quality standards. I also operated a dishwasher during my time in this department. I supplied mead, vegetables, fruits, and canned food from the storeroom to the kitchen in a basket or trolley.l also assisted the kitchen team in replenishing food items, cookery, cutlery, etc. during special events or rush hours. I also learned about the quality assured standards of the organization specifically for the food production department. This kind of exposure helped me understand the significance of maintaining the quality standards and its benefits which was to accentuate the customer satisfaction while keeping their trust and safety intact. Not only it helps us to provide a quality experience to the clients but also helps us to smoothly carry out the day to day task with right practices. Here I also learned about how to plan in advance. Working in this department was a really great experience as it taught me about management, right practices, planning and how to provide our guests with an explicit service.REMARKS: (To be filled by trainer/Handwritten)</p>"
    const defaultParaTwo = `
        <ul>
            <li>Learned about food preparation.</li>
            <li>Did different type of cutting.</li>
            <li>Proper control of temperature and condition for storing food.</li>
            <li>Handling cooking equipment such as Salamander/Oven/Grill/Walk in freezer/Microwave.</li>
            <li>General maintenance of food production.</li>
        </ul>
    `;

    const editable = false
    return (
        <div className='mt-2 w-full'>
            {/* <div className='flex justify-between'>
                <Title title="Reference" />
            </div> */}




            <div className='mt-2'>
                <TopField date={date}
                    startDate={date}
                    endDate={endDate}
                    selectedShift={selectedShift}
                    department={department}
                    section={section}
                    handleDate={(e) => setDate(e.target.value)}
                    handleShiftChange={(e) => setSelectedShift(e.target.value)}
                    handleDepartment={(e) => setDepartment(e.target.value)}
                    handleSection={(e) => setSection(e.target.value)}
                    editable={editable}

                />
            </div>
            <div>
                <div className=''>
                    <SubTitle title="Task Carried Out" />
                </div>
                <div className='flex flex-col gap-x-5 gap-y-5 p-4 px-8 bg-primary-background rounded-lg py-4'>
                    <span className='font-poppins text-lg  text-gray-600'>Please fill in with bullets with tasks you have performed this week.</span>
                    <TextEditor defaultValue={defaultParaTwo} editable={false} />
                </div>
            </div>
            <div>
                <div className=''>
                    <SubTitle title="Observation" />
                </div>
                <div className='flex flex-col gap-x-5 gap-y-5 p-4 px-8 bg-primary-background rounded-lg py-4'>
                    <span className='font-poppins text-lg  text-gray-600'>Please write your observation this week in 150 to 200 words.</span>
                    <TextEditor defaultValue={defaultParagraph} editable={false} />
                </div>
            </div>
            <div>
                <div className=''>
                    <SubTitle title="Pictures" />
                </div>
                <div className='flex flex-col gap-x-5 gap-y-5 p-4 px-8 bg-primary-background rounded-lg py-4'>
                    <span className='font-poppins text-lg  text-gray-600'>Minimum of 2 pictures are required and maximum of 4 pictures</span>
                    <div className='flex flex-wrap gap-3'>
                        <ImageUploader defaultText="This is the first image" src="/assets/images/sampleone.png" />
                        <ImageUploader defaultText="This is the second image" src="/assets/images/sampletwo.png" />
                        <ImageUploader defaultText="This is the third image" src="/assets/images/samplethree.png" />
                        <ImageUploader defaultText="This is the fourth image" src="/assets/images/samplefour.png" />
                    </div>


                </div>
            </div>
            {/* <div className='flex mt-4 justify-end'>
                <Button title="Save" className="rounded-md px-8" />

            </div> */}

        </div>
    )
}

export default SampleLogBook