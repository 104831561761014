import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
// import Button from '../components/Button';
import ImageBox from '../components/ImageBox';


// mui dialog
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


// import axios from 'axios'
import dataService from '../services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UploadPhoto from './UploadPhoto';
import UploadInternshipCompletionPhoto from './UploadIntershipCompletionPhoto';
import SkelotonLoaderCertificate from '../components/SkelotonLoaderCertificate';


const Certificates = () => {
    // const [imageSrc, setImageSrc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDeletingCertificate, setIsDeletingCertificate] = useState(false)
    const [isDeletingCompletitionCertificate, setIsDeletingCompletitionCertificate] = useState(false)



    // this belol will store data retrieved after fetching the get all certificates api
    const [allCertificates, setAllCertificates] = useState([])
    console.log('allCertificates', allCertificates)

    // this below will store individual image of allCertificates. ie. Only certificates image
    const [certificateImage, setCertificateImage] = useState([]);
    // this below will store internship completion certificate
    const [internCompletionCertificate, setInternCompletionCertificate] = useState('')
    console.log('internCompletionCertificate', internCompletionCertificate)

    // this below will store image that we will upload from our device in the form of file. 
    // previously when i tried to store data in the form of url, we could preview image but backend looked for file
    // so to preview image, we need to handle seperate state now
    // const [imageUpload, setimageUpload] = useState(null) //in file format
    // const [previewImage, setPreviewImage] = useState(null) //in url format

    const certificatesField = ["All Images", "Internship Completion Certificates"];
    const [activeField, setActiveField] = useState("All Images");


    const [openCertificate, setOpenCertificate] = useState(false);
    const [selectedCertificateIndex, setSelectedCertificateIndex] = useState(null);

    // 

    const navigate = useNavigate()
    var user = localStorage.getItem('user');
    var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)

    var admin = localStorage.getItem('admin');
    var loggedInData = null;
    if (user) {
        loggedInData = JSON.parse(user);
        console.log('Logged in data(user):', loggedInData);
    } else if (userClickedByAdmin) {
        loggedInData = userClickedByAdmin;
        console.log('data of particular user clicked by admin:', loggedInData);
    } else {
        console.log('No user logged in or admin has not clicked any user');
        // Handle the case where no one is logged in
    }
    const { userId, imageUrl, courseName } = loggedInData


    // this below admin data will only be used for the validation case of if admin with role PartnerCenter and userclicked by admin also then we dont allow them to edit any user
    const adminData = JSON.parse(admin)
    const adminRole = adminData?.role

    const { openMobileSideBar } = useSelector((state) => state.hamburger)





    const theme = useTheme();
    const fullScreenCertificate = useMediaQuery(theme.breakpoints.down('md'));
    const fullScreenCompletitionCertificate = useMediaQuery(theme.breakpoints.down('md'));






    const handleCertificateOpen = (index) => {
        setSelectedCertificateIndex(index);

        console.log('clicked dialog open')
    };
    const handleCompletitionCertificateOpen = () => {
        setOpenCertificate(true);
        console.log('clicked dialog open')
    };
    console.log('openCertificate', openCertificate)

    const handleCertificateClose = () => {
        setSelectedCertificateIndex(null);
    }
    const handleCompletitionCertificateClose = () => {
        setOpenCertificate(false)
    }





    const handleDeleteCertificate = (certificateId) => {
        setIsDeletingCertificate(true)
        console.log('index', certificateId)

        const deleteData = async () => {
            try {
                const response = await dataService.deleteData(`/Certificate/deletecertificate?userId=${userId}&certificateId=${certificateId}`)
                console.log('response', response)
                navigate('/')
                toast.success('Certificate Deleted Sucessfully')
                setIsDeletingCertificate(false)

            } catch (error) {
                console.log('error', error)
                toast.error('Certificate Deletiion failed')
                setIsDeletingCertificate(false)
            }



        }

        deleteData()
    }
    const handleDeleteCompletitionCertificate = () => {
        setIsDeletingCompletitionCertificate(true)
        const deleteData = async () => {

            try {
                const response = await dataService.deleteData(`/UserInformation/deleteInternshipCertificate?userId=${userId}`)
                console.log('response', response)
                toast.success('Internship Completition Certificate Deleted Successfully')
                navigate('/')
                setIsDeletingCompletitionCertificate(false)
            } catch (error) {
                console.log('error', error)
                toast.error('Failed to delete Internship Completition Certificate')
                navigate('/')
                setIsDeletingCompletitionCertificate(false)

            }


        }

        deleteData()
    }

    // const handleUpload = (event) => {
    //     const files = event.target.files;
    //     if (files && files.length > 0) {
    //         const newImages = [...images];
    //         let index = 0;
    //         for (let i = 0; i < newImages.length; i++) {
    //             if (newImages[i] === null && index < files.length) {
    //                 newImages[i] = URL.createObjectURL(files[index]);
    //                 index++;
    //             }
    //         }
    //         setImages(newImages);
    //     }
    // };



    // Function to determine MIME type based on image extension
    const getMimeType = (imageUrl) => {
        console.log(imageUrl)
        // You may need to inspect the base64 string or use additional metadata if available
        if (imageUrl.startsWith('/9j/')) {
            return 'image/jpeg';
        } else if (imageUrl.startsWith('iVBORw0KGgo')) {
            return 'image/png';
        } else if (imageUrl.startsWith('R0lGODlh')) {
            return 'image/gif';
        }
        return 'image/jpeg'; // Default to JPEG if unknown
    };


    // useEffect to call the api to fetch all images in the database that has been uploaded
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const response = await dataService.getData(`/Certificate/getall?userId=${userId}`);
                setAllCertificates(response)
                setIsLoading(false)
                // console.log('logbookdatafromdb', response);
            } catch (error) {

                console.error('Error fetching data:', error);
                setIsLoading(false)
            }
        };
        fetchData();

        const fetchInternshipCompletionData = async () => {
            try {
                const response = await dataService.getData(`/UserInformation/getInternshipCertificate?userId=${userId}`);
                console.log('response', response)
                setInternCompletionCertificate(response)
            } catch (error) {

                console.error('Error fetching internshipcompletition certificate data:', error);
            }
        }

        fetchInternshipCompletionData();

    }, [])

    useEffect(() => {
        if (allCertificates) {

            const updatedImages = allCertificates.map((item) => {
                const mimeType = getMimeType(item.certificateImage);
                const dataUrl = `data:${mimeType};base64,${item.certificateImage}`;
                return dataUrl
            });

            setCertificateImage(updatedImages)


        }
        if (internCompletionCertificate && internCompletionCertificate.internCertificate) {
            console.log('yes internship completition certificate')
            const mimeTypeOfCompletitionCertificate = getMimeType(internCompletionCertificate.internCertificate)
            const dataUrlOfCompletitionCertificate = `data:${mimeTypeOfCompletitionCertificate};base64,${internCompletionCertificate.internCertificate}`;

            setInternCompletionCertificate(dataUrlOfCompletitionCertificate)
        }
    }, [allCertificates, internCompletionCertificate]);

    // useEffect(() => {

    //     const timer = setTimeout(() => {
    //         setIsLoading(false);
    //     }, 1800);

    //     // Clean up timer if the component unmounts
    //     return () => clearTimeout(timer);
    // }, []);


    return (
        <div className='m-4 md:m-8'>
            <div className='flex justify-between'>
                <div className={` hidden md:block `} >
                    <Title title="Update your certificate if you have any." />

                </div>
                <div className='block md:hidden w-full'>
                    <Title title="Update your certificate." />

                </div>
            </div>

            {/* <div className='flex justify-between mt-4'>
                <div className='flex space-x-8 items-end'>
                    <span className='font-poppins text-gray-500'>All Images</span>
                    <span className='font-poppins text-gray-500'>Internship Completion Certificates</span>
                </div>
                <div>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        // onChange={handleUpload}
                        style={{ display: 'none' }}
                        id="upload-input"
                    />
                    <label htmlFor="upload-input">
                        <Button className='flex justify-end items-center bg-primary-title rounded-md px-6'>Upload a Photo</Button>
                    </label>
                </div>
            </div>

            <hr className='border border-slate-300 mt-3 -mx-8' /> */}

            <div className='flex justify-between mt-4 '>
                <div className='flex  items-end  w-full  space-x-5'>
                    {certificatesField.map((item, index) => (
                        <span
                            key={index}
                            className={`font-poppins text-gray-500  cursor-pointer  lg:px-8 pb-4 text-xs md:text-[13.33px] ${activeField === item ? 'border-b-[3px] border-primary-title  ' : 'border-b-[3px] border-transparent'}`}
                            onClick={() => setActiveField(item)}
                        >
                            {item}
                        </span>
                    ))}
                </div>


            </div>

            <hr className='border border-slate-300  -mx-8' />

            {/* <div className='flex gap-5 mt-8 flex-wrap '>
                {images.map((image, index) => (
                    <ImageBox key={index} src={image} image_name={`Image${index + 1}`} />
                ))}
                
            </div> */}

            {/* image of certificates. All images */}
            {
                activeField === 'All Images' && (
                    <div>
                        <div className='flex gap-5 mt-8 flex-wrap '>
                            {
                                isLoading ?
                                    (
                                        <SkelotonLoaderCertificate />
                                    ) : allCertificates.length > 0 ? (
                                        allCertificates.map((item, index) => (
                                            // <img key={index} src={src} alt={`Certificate ${index}`} />
                                            <div key={index} className=' w-[calc(50%-10px)] lg:w-[calc(25%-15px)]'>
                                                <div onClick={() => handleCertificateOpen(index)} className='cursor-pointer '>
                                                    <ImageBox key={index} src={certificateImage[index]} image_name={`Certificate ${index + 1}`} />

                                                </div>

                                                {/* pop up for image */}
                                                <Dialog
                                                    open={selectedCertificateIndex === index}

                                                    fullScreen={fullScreenCertificate}
                                                    // open={openCertificate}
                                                    onClose={handleCertificateClose}
                                                    aria-labelledby="responsive-dialog-title"
                                                >
                                                    {/* <DialogTitle id="responsive-dialog-title" className='text-primary-title bg-primary-background font-poppins '>
                                    {"Upload Certificate"}
                                </DialogTitle> */}
                                                    <DialogContent style={{ padding: '40px' }}>
                                                        <div className="flex w-full">
                                                            <img src={certificateImage[index]} alt="" className='xl:h-80 xl:w-full object-cover border-2 border-gray-500 rounded-md ' />
                                                            <hr className='border-[1px] border-gray-300' />
                                                        </div>

                                                        <div className="flex mt-10">
                                                            <span className="font-poppins">
                                                                {item.caption}                                                            </span>
                                                        </div>



                                                    </DialogContent>
                                                    <DialogActions className='bg-primary-background flex justify-end space-x-3' style={{ padding: '15px' }}>


                                                        {
                                                            adminData?.role !== 'PartnerCenter' && (
                                                                <Button onClick={() => { handleDeleteCertificate(item.certificateId) }} style={{ backgroundColor: 'red', padding: '8px 20px', color: '#ffffff', fontWeight: '600' }}>
                                                                    {
                                                                        isDeletingCertificate ?
                                                                            (
                                                                                <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                                </svg>
                                                                            ) : (
                                                                                <span>Delete</span>
                                                                            )

                                                                    }

                                                                </Button>
                                                            )
                                                        }


                                                        <Button onClick={handleCertificateClose} style={{ border: '1px solid ', backgroundColor: '#2b5972', padding: '8px 20px', color: '#ffffff', fontWeight: '600' }}>
                                                            Cancel
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                            </div>


                                        ))
                                    ) : (
                                        <div className='font-poppins gap-4 text-[14px]'>
                                            <p>No certificates available</p>
                                            {
                                                adminRole !== 'PartnerCenter' && (
                                                    <p>Upload Certificates by clicking <b className='font-poppins text-primary-title text-[14px]'>Upload a Photo</b> button</p>

                                                )

                                            }
                                        </div>
                                    )}
                        </div>

                        <div className=''>
                            <UploadPhoto />
                        </div>
                    </div>
                )
            }


            {/* code for the internship compeltion certificate */}
            {
                activeField === 'Internship Completion Certificates' && (
                    <div>

                        <div className='flex gap-5 mt-8 flex-wrap '>
                            {
                                internCompletionCertificate && internCompletionCertificate.length > 0 ? (
                                    <div className=' w-[calc(50%-10px)] lg:w-[calc(25%-15px)]'>

                                        <div onClick={handleCompletitionCertificateOpen} className='cursor-pointer '>
                                            <ImageBox src={internCompletionCertificate} image_name="Internship Completition Certificate" />

                                        </div>
                                        <Dialog
                                            fullScreen={fullScreenCompletitionCertificate}
                                            open={openCertificate}
                                            onClose={handleCompletitionCertificateClose}
                                            aria-labelledby="responsive-dialog-title"
                                        >

                                            <DialogContent style={{ padding: '40px' }}>
                                                <div className="flex w-full">
                                                    <img src={internCompletionCertificate} alt="" className='xl:h-80 xl:w-full object-cover border-2 border-gray-500 rounded-md ' />
                                                    <hr className='border-[1px] border-gray-300' />
                                                </div>

                                                <div className="flex mt-10">
                                                    <span className="font-poppins">
                                                        This certificate is awarded to you for the completition of requirement for the course of {courseName}
                                                    </span>
                                                </div>



                                            </DialogContent>
                                            <DialogActions className='bg-primary-background flex justify-end space-x-3' style={{ padding: '15px' }}>

                                                {
                                                    adminData?.role !== 'PartnerCenter' && (
                                                        <Button onClick={handleDeleteCompletitionCertificate} style={{ backgroundColor: 'red', padding: '8px 20px', color: '#ffffff', fontWeight: '600' }}>
                                                            {
                                                                isDeletingCompletitionCertificate ?
                                                                    (
                                                                        <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                        </svg>
                                                                    ) : (
                                                                        <span>Delete</span>
                                                                    )

                                                            }
                                                        </Button>
                                                    )
                                                }


                                                <Button onClick={handleCompletitionCertificateClose} style={{ border: '1px solid ', backgroundColor: '#2b5972', padding: '8px 20px', color: '#ffffff', fontWeight: '600' }}>
                                                    Cancel
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                    </div>
                                ) : (
                                    <div className='font-poppins gap-4 text-[14px]'>
                                        <p>No certificates available</p>
                                        {
                                            adminRole !== 'PartnerCenter' && (
                                                <p>Upload Certificates by clicking <b className='font-poppins text-primary-title text-[14px] '>Upload a Photo</b> button</p>

                                            )

                                        }
                                    </div>
                                )
                            }

                        </div>

                        <div className=''>
                            <UploadInternshipCompletionPhoto />
                        </div>

                    </div>


                )
            }




        </div>
    );
};

export default Certificates;
