import React from 'react'

const IconList = ({ title, items }) => {
    return (
        <div className='flex gap-x-5 mt-5'>
            <div className="flex-2">
                <span className='font-poppins text-[#212529] text-[14px]'>{title}</span>
            </div>
            <div className='flex-7 space-y-3 '>
                {
                    items.map((item, index) => {
                        return (
                            <div className='flex flex-4 space-x-3  items-center' key={index} >
                                <img className='h-4 lg:h-5' src="/assets/images/tick.png" alt="" />
                                <span  className='font-poppins text-[#212529] text-[14px]'>{item}</span>

                            </div>
                        )


                    })
                }
            </div>

        </div>
    )
}

export default IconList