import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import LeftSection from './LeftSection';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSelector } from 'react-redux';
import dataService from '../services/data-service'

// toast
import { toast } from 'react-toastify';


import axios from 'axios'

const RegisterStepTwo = () => {
  const navigate = useNavigate()
  const [isRegistering, setIsRegistering] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // profilePic will be in url format to preview and pic will be in file format to send to backend
  const [pic, setPic] = useState(null)
  const [profilePic, setProfilePic] = useState(null); // State to store selected profile picture
  // console.log('profilePic', profilePic)
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setPic(file)
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result); // Update profilePic with the image URL 
      };
      reader.readAsDataURL(file);
    }
  };

  const registersteponedata = useSelector((state) => state.user.steponeregisterdata)
  console.log('registersteponedata', registersteponedata)

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmpassword: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
          confirmpassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .max(255)
            .required('Confirm Password is required')


        })}
        onSubmit={async ({ email, password, confirmpassword }) => {
          setIsRegistering(true)
          // const isRegistered = await dataService.postData('/register',{name,email,password,name,refreshToken})
          // console.log({ email, password, confirmpassword, ...registersteponedata }, registersteponedata)
          const registersteptwodata = { email, password, confirmpassword }
          console.log(registersteponedata.name)
          // console.log('registersteptwodata', registersteptwodata)
          // const register_data = { ...registersteptwodata, ...registersteponedata };
          // console.log('register_data', register_data)

          console.log('image', {
            'files': pic
          })

          const formData = new FormData();
          formData.append('candidateNumber', registersteponedata.candidateregisterationnumber);
          formData.append('gender', registersteponedata.gender);
          formData.append('name', registersteponedata.name);
          formData.append('email', email);
          formData.append('userName', "techglazer");
          formData.append('password', password);
          formData.append('confirmPassword', confirmpassword);
          formData.append('files', pic);
          formData.append('phoneNumber', registersteponedata.phonenumber);
          formData.append('address', registersteponedata.address);
          formData.append('supervisorname', registersteponedata.supervisorname);
          formData.append('trainingcenter', registersteponedata.partnertrainingcenter);
          formData.append('course', registersteponedata.course);








          // formData.append('image', picture);
          const postDataIntoDB = async () => {

            try {
              const response = await dataService.postData('/UserAccount/register', formData)
              console.log('Register success response', response)
              navigate('/')
              window.location.reload()
              toast.success("Registration Sucessfull")

            } catch (error) {
              toast.error(error.response.data)
              console.log("Register failure error", error);
            } finally {
              setIsRegistering(false)

            }

          }
          postDataIntoDB();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='flex flex-col lg:h-screen md:flex-row  '>
              {/* left section of the login code */}
              <div className='flex flex-col  md:fixed md:h-screen md:left-0 md:w-1/2 md:p-8 md:gap-y-32 md:bg-primary-background ' >
                <Link to="/">
                  <div className='hidden md:block'>
                    <img src="/assets/images/Logo.png" alt="" className='h-16' />

                  </div>

                </Link>
                <div className="flex justify-center items-center mb-6 mt-12 md:hidden w-full">
                  <span className='text-xl text-primary-title font-poppins font-semibold'>Register</span>

                </div>
                <div className='flex justify-center items-center'>
                  <img src="/assets/images/login.png" alt="" className='h-60 md:h-80 ' />
                </div>
              </div>

              {/* right section of the login code */}

              <div className='w-full right-0 md:w-1/2 md:ml-auto'>
                <div className='md:mt-12 lg:px-16  2xl:px-28 p-4 lg:p-10 md:space-y-[20px] '>
                  <div className='hidden md:flex justify-center text-primary-title font-poppins text-xl font-semibold'>
                    <span className='text-2xl'>Register</span>


                  </div >

                  <div className='hidden md:block '>
                    <div className="flex justify-between items-center  w-full max-w-xl relative   md:px-20 lg:px-32 ">
                      <div className="  mb-8">
                        <div className="w-10 h-10 flex items-center justify-center bg-primary-title text-white rounded-full">
                          1
                        </div>

                      </div>

                      <div className="relative flex-grow">
                        <div className="absolute left-0 -top-4 transform -translate-y-1/2 w-full h-1 bg-primary-title"></div>
                      </div>

                      <div className="mb-8">
                        <div className="w-10 h-10 flex items-center justify-center text-white rounded-full  bg-primary-title">
                          2
                        </div>
                      </div>
                    </div>
                    <div className='  flex justify-around'>
                      <span className=" text-center font-poppins text-[14px]">Basic Information</span>
                      <span className=" text-center font-poppins text-[14px]">Sign-up Information</span>


                    </div>
                  </div>

                  <div style={{ marginTop: '60px' }}>
                    <span className='font-poppins text-primary-title text-md md:text-xl font-semibold'>Signup Information</span>
                  </div>

                  {/* secttion below of basic information */}

                  <div className='  flex w-full text-sm '>
                    <div className='flex flex-col justify-between w-full gap-y-5'>
                      <div className='flex flex-col space-y-1 md:gap-y-[5px]  w-full  '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Email Address <span className=' text-red-600 font-medium text-xl'>* </span></span>
                        <input
                          type="email"
                          id='email'
                          value={values.email}
                          name='email'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your email'

                          className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none ${touched.email && errors.email ? 'border-red-500' : 'border-gray-400'
                            }`} />
                        {touched.email && errors.email && (
                          <span error className='text-red-500 font-poppins text-[14px]' >
                            {errors.email}
                          </span>
                        )}
                      </div>
                      {/* password */}

                      <div className='flex flex-col space-y-1 md:gap-y-[5px]  w-full relative '>
                        <span className='font-poppins  md:text-[14px] font-medium'>Password <span className=' text-red-600 font-medium text-xl'>* </span> </span>
                        <div className='relative'>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id='password'
                            value={values.password}
                            name='password'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Enter your password'

                            className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none w-full ${touched.password && errors.password ? 'border-red-500' : 'border-gray-400'
                              }`}
                          />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOffIcon className="w-5 h-5" /> : <RemoveRedEyeIcon className="w-5 h-5" />}
                          </div>
                        </div>

                        {touched.password && errors.password && (
                          <span error className='text-red-500 font-poppins text-[14px]' >
                            {errors.password}
                          </span>
                        )}
                      </div>



                      <div className="flex flex-col space-y-1 md:gap-y-[5px]  w-full relative">
                        <span className="font-poppins  md:text-[14px]  font-medium">Confirm Password <span className=' text-red-600 font-medium text-xl'>* </span> </span>
                        <div className="relative">
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmpassword"
                            value={values.confirmpassword}
                            name="confirmpassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Re-enter your password"
                            className={`border-[1px] border-gray-400 text-[14px] font-poppins p-1 h-10 px-2 rounded-lg outline-none w-full ${touched.password && errors.password ? 'border-red-500' : 'border-gray-400'
                              }`}
                          />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={handleClickShowConfirmPassword}>
                            {showConfirmPassword ? <VisibilityOffIcon className="w-5 h-5" /> : <RemoveRedEyeIcon className="w-5 h-5" />}
                          </div>
                        </div>
                        {touched.confirmpassword && errors.confirmpassword && (
                          <span className="text-red-500">{errors.confirmpassword}</span>
                        )}
                      </div>
                      {/* <div className='flex flex-col space-y-4 py-4 w-[260px]'>
                        <span className='font-poppins  md:text-[14px] font-medium'>Upload Profile Picture </span>

                        <div className='bg-primary-background border-2 border-dotted  flex justify-start items-center p-2 rounded-lg'>
                          <div className='flex justify-center items-center w-full py-2'>
                            <img src="assets/images/one.png" alt="" className='h-7' />
                            <span>Upload picture</span>
                          </div>
                        </div>

                      </div> */}

                      <div className='flex flex-col gap-y-[5px] w-[260px]'>
                        <span className='font-poppins  md:text-[14px] font-medium'>Upload Profile Picture</span>
                        <div className='bg-primary-background border-2 border-dotted flex justify-start items-center p-2 rounded-lg'>
                          <input
                            type="file"
                            id='profilepic'
                            accept="image/*"
                            onChange={handleFileChange}
                            className="hidden"
                          />
                          <label htmlFor="profilepic" className="cursor-pointer">
                            {profilePic ? (
                              <img src={profilePic} alt="Profile Preview" className='h-20 rounded-lg' />
                            ) : (
                              <div className='flex justify-center items-center w-full py-2'>
                                <img src="assets/images/one.png" alt="" className='h-7' />
                                <span className='ml-2 text-[14px] font-poppins'>Upload picture</span>
                              </div>
                            )}
                          </label>
                        </div>
                      </div>


                    </div>


                  </div>
                  <div className='flex space-x-4 justify-center'>
                    <Link to="/registerstepone">
                      {/* <Button title="Previous" className='text-black bg-white border-2 px-11   border-primary-title rounded-md ' />  */}
                      <button type='button' className='rounded-md bg-white border-2 border-primary-title text-primary-title  p-3 px-12 text-[14px]  font-poppins'>Previous</button>


                    </Link>

                    {/* <Button title="Register" className='text-white border-2 px-11 bg-primary-title rounded-md' /> */}
                    <button type='submit' className='bg-primary-title p-2 px-12 text-[14px] text-white rounded-md font-poppins'>
                      {
                        isRegistering ? (
                          <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                          </svg>
                        ) : (
                          <span>Register</span>

                        )

                      }
                    </button>



                  </div>




                </div>
              </div>
            </div>
          </form >
        )}
      </Formik >
    </div >
  )
}

export default RegisterStepTwo