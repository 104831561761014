import React, { useEffect, useState } from 'react'
import { store } from '../store'
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSidebarCollapsed, setOpenMobileSidebar } from '../store/reducerSlices/hamburgerSlice';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import dataService from '../services/data-service';

import { getClickedUserData } from '../store/reducerSlices/adminClickedSlice';



// const Navbar = ({ toggleSidebar, toggleMobileSidebar }) => {
const Navbar = ({ showStudent }) => {
    //image changed form base64 to image
    const [imageSrc, setImageSrc] = useState('');
    // console.log('imageSrc', imageSrc)


    const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger)
    // console.log('isSidebarCollapsed', isSidebarCollapsed)
    // console.log('openMobileSideBar', openMobileSideBar)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const idClickedByAdmin = location.state?.userId
    console.log('idClickedByAdmin', idClickedByAdmin)

    // const fetchClickedUserData = async (id) => {

    //     if (id) {
    //         const response = await dataService.getData(`/UserInformation/getbyid/user?userId=${id}`);
    //         // const clickedIDdata = response.data;


    //         console.log('clickedIDdata', response);
    //         dispatch(getClickedUserData(response))
    //         return response;
    //     } else {
    //         console.log('ID is null');
    //         return null;
    //     }

    // };

    // useEffect(() => {
    //     if (idClickedByAdmin) {
    //         console.log('token coming')
    //         fetchClickedUserData(idClickedByAdmin);
    //         console.log('admin just clicked some id')
    //     }
    // }, [idClickedByAdmin]);

    const handleToggle = () => {
        dispatch(setIsSidebarCollapsed())
    }

    const handleMobileToggle = () => {
        dispatch(setOpenMobileSidebar())
    }


    //  need to create a collapsed side pannel not hamburger menu
    var user = localStorage.getItem('user');
    var admin = localStorage.getItem('admin');
    var loggedInData = null;

    if (user) {
        loggedInData = JSON.parse(user);
        console.log('Logged in data from navbar left (user):', loggedInData);
    } else if (admin) {
        loggedInData = JSON.parse(admin);
        console.log('Logged in data from navbar left (admin):', loggedInData);
    } else {
        console.log('No user or admin is logged in');
        navigate('/user/login')



        // Handle the case where no one is logged in
    }

    const { name, role, shortCode, imageUrl,partnerTrainingCenter } = loggedInData

    // const { name, role } = loggedInData
    // const loggedInData = store.getState().admin.admin || store.getState().user.user
    // const { name, role, shortCode, imageUrl } = loggedInData

    if (role === 'User') {
        var loggedInUserRole = 'Student'
    }else if (role === 'PartnerCenter'){
        var loggedInUserRole = 'PartnerCenter'
    }else {
        loggedInUserRole = "Admin"
    }


    const clickedUserByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)
    console.log('clickedUserByAdmin', clickedUserByAdmin)

    // Function to determine MIME type based on image extension
    const getMimeType = (imageUrl) => {
        // You may need to inspect the base64 string or use additional metadata if available
        if (imageUrl.startsWith('/9j/')) {
            return 'image/jpeg';
        } else if (imageUrl.startsWith('iVBORw0KGgo')) {
            return 'image/png';
        } else if (imageUrl.startsWith('R0lGODlh')) {
            return 'image/gif';
        }
        return 'image/jpeg'; // Default to JPEG if unknown
    };

    useEffect(() => {
        if (imageUrl) {
            const mimeType = getMimeType(imageUrl);
            const dataUrl = `data:${mimeType};base64,${imageUrl}`;
            setImageSrc(dataUrl);
        }
    }, [imageUrl]);


    return (
        <div className='flex items-center justify-between  p-4 md:px-8 border-b-[1.5px] border-[#DDDDDD] sticky top-0 bg-white z-10 shadow-sm'>
            <div className="hidden gap-x-4 md:flex">
                <button onClick={handleToggle} >
                    <MenuIcon />

                </button>

                {clickedUserByAdmin && showStudent &&
                    <div className="flex font-poppins items-center">
                        {clickedUserByAdmin.name}
                    </div>
                }

            </div>

            <div className='flex gap-4 md:hidden'>
                <button onClick={handleMobileToggle} >
                    <i className="fa-solid fa-bars"></i>
                </button>

                {clickedUserByAdmin && showStudent &&
                    <div className="flex font-poppins items-center">
                        {clickedUserByAdmin.name}
                    </div>
                }
            </div>

            <div className='flex items-center'>
                <img src={imageSrc} className='h-11 w-11 rounded-full object-cover object-top' alt="" />
                <div className='flex flex-col ml-3'>
                    {
                        loggedInUserRole === 'Student' && (
                            <div className='flex flex-col font-poppins '>
                                <span className='text-cyan-800 font-bold text-[13.33px]'>{name}</span>
                                <span className='text-[13.33px] '>{loggedInUserRole}<span className='text-cyan-800 font-bold'>({shortCode})</span></span>
                            </div>)

                    }
                    {loggedInUserRole === "Admin" &&
                        (
                            <div className='flex flex-col font-poppins text-[13.33px]'>
                                <span className='text-cyan-800 font-bold'>{name}</span>
                                <span className='text-[13.33px] '>{loggedInUserRole}</span>

                            </div>
                        )
                    }
                    {loggedInUserRole === "PartnerCenter" &&
                        (
                            <div className='flex flex-col font-poppins'>
                                <span className='text-cyan-800 font-bold text-[13.33px]'>{name}</span>
                                <span className='text-[13.33px] '>{partnerTrainingCenter}</span>

                            </div>
                        )
                    }
                    {/* {clickedUserByAdmin &&
                        (
                            <div className='flex flex-col font-poppins'>
                                <span className='text-cyan-800 font-bold'>{name}</span>
                                <span>{loggedInUserRole}</span>

                            </div>
                        )
                    } */}

                </div>
            </div>


        </div >
    )
}

export default Navbar