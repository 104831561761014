import React, { useState, useEffect } from 'react';
import Title from '../../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import TopField from './TopField';
import SubTitle from '../SubTitle';
import SampleLogBook from '../SampleLogBook';
import TextEditor from '../../components/TextEditor';
import ImageUploader from '../../components/ImageUploader';
import Button from '../../components/Button';
import { store } from '../../store'
import dataService from '../../services/data-service';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { DatePicker } from "antd";
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;


const IndividualWeek = () => {
    const [isRetrivingData, setIsRetrivingData] = useState(false)


    const { weekNumber } = useParams();
    // console.log('weekNumber', weekNumber)
    const [logbookDataFromDB, setLogbookDataFromDB] = useState({})
    console.log('logbookDataFromDB', logbookDataFromDB)
    const [isLogBookSaving, setIsLogBookSaving] = useState(false);

    var user = localStorage.getItem('user');
    var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)

    var admin = localStorage.getItem('admin');
    var loggedInData = null;



    if (user) {
        loggedInData = JSON.parse(user);
        console.log('Logged in data(user):', loggedInData);
    } else if (userClickedByAdmin) {
        loggedInData = userClickedByAdmin;
        console.log('data of particular user clicked by admin:', loggedInData);
    } else {
        console.log('No user logged in or admin has not clicked any user');
        // Handle the case where no one is logged in
    }
    const { userId, role, shortCode } = loggedInData

    // this below admin data will only be used for the validation case of if admin with role PartnerCenter and userclicked by admin also then we dont allow them to edit any user
    const adminData = JSON.parse(admin)
    const adminRole = adminData?.role
    //  console.log('adminRole', adminRole)

    const editableYesOrNot = () => {
        if (adminRole === 'PartnerCenter') {
            return false
        } else {
            return true
        }
    }
    const editable = editableYesOrNot()





    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const defaultTaskContent = `<ul>
        <li></li>
        <li></li>
        <li></li>
    </ul>`;



    // check for the logbook data of individual week if data is already in the database:
    var logbookDataWeek = 0;
    if (role === 'User') {
        var loggedInUserRole = 'Student'
    } else {
        loggedInUserRole = "Admin"
    }

    // const editable = true
    const weekFields = [`Logbook week-${weekNumber}`, "Sample Logbook"];
    const [activeField, setActiveField] = useState(`Logbook week-${weekNumber}`);

    const [date, setDate] = useState('');
    const [selectedShift, setSelectedShift] = useState('');
    const [department, setDepartment] = useState('');
    const [section, setSection] = useState('');

    const [taskContent, setTaskContent] = useState(defaultTaskContent);

    console.log('taskContent', taskContent)
    const [observationContent, setObservationContent] = useState('');

    const [images, setImages] = useState(['null', 'null', 'null', 'null']);
    console.log('images check', images)
    const [imageTexts, setImageTexts] = useState(['', '', '', '']);
    console.log('imageTexts', imageTexts)


    // Function to determine MIME type based on image extension
    const getMimeType = (imageUrl) => {
        // You may need to inspect the base64 string or use additional metadata if available
        if (imageUrl.startsWith('/9j/')) {
            return 'image/jpeg';
        } else if (imageUrl.startsWith('iVBORw0KGgo')) {
            return 'image/png';
        } else if (imageUrl.startsWith('R0lGODlh')) {
            return 'image/gif';
        }
        return 'image/jpeg'; // Default to JPEG if unknown
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleShiftChange = (event) => {
        setSelectedShift(event.target.value);
    };

    const handleDepartment = (e) => {
        setDepartment(e.target.value);
    };

    const handleSection = (e) => {
        setSection(e.target.value);
    };

    const handleTaskChange = (content) => {

        console.log('content', content)
        setTaskContent(content);
    };

    const handleObservationChange = (content) => {
        setObservationContent(content);
    };

    const handleImageChange = (index, file) => {
        console.log('index and file of image uploader', index, file)
        // this new constant newImages, contains same images as constant  images but modifying this new wont affect original

        const newImages = [...images];
        newImages[index] = file;
        console.log('newImages', newImages)
        setImages(newImages);
    };

    const handleImageTextChange = (index, text) => {
        const newTexts = [...imageTexts];
        newTexts[index] = text;
        setImageTexts(newTexts);
    };
    const extractTextFromHtml = (html) => {
        return html.replace(/<\/?p>|<br\s*\/?>/g, '');
    };
    


    const handleSaveClick = async () => {
        setIsLogBookSaving(true)


        const taskContentt = extractTextFromHtml(taskContent);
        const observationContentt = extractTextFromHtml(observationContent);

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        };

        const imageURLS = await Promise.all(
            images.map(async (image) => {
                if (image && image instanceof File) {
                    return await convertToBase64(image);
                } else if( typeof image ==='string' && image.startsWith('data:')) {
                    return image;
                }else{
                    return null
                }
            })

        )


        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('name', 'week' + weekNumber);
        formData.append('logStartDate', startDate);
        formData.append('logEndDate', endDate);

        formData.append('shift', selectedShift);
        formData.append('department', department);
        formData.append('section', section);
        formData.append('task', taskContentt);
        formData.append('observation', observationContentt);
        formData.append('createdBy', userId);
        formData.append('taskImagesDescriptions', imageTexts);
        imageURLS.forEach((image,index)=>{
            formData.append('files', image)
        })

        // Append each file individually
        // images.forEach((image, index) => {
        //     console.log('image', image)
        //     // if (image && typeof image === 'object') {
        //     formData.append('files', image);
        //     // }
        // });
        



        console.log('data being sent to backend/db', {
            'userId': userId,
            'name': 'week' + weekNumber,
            'files': imageURLS,
            'logStartDate': startDate,
            'logEndDate': endDate,
            'shift': selectedShift,
            'department': department,
            'section': section,
            'task': taskContentt,
            'observation': observationContentt,
            'createdBy': userId,

            'taskImagesDescriptions': imageTexts,

        })

        try {
            // {} is considered as truth value in js
            if (logbookDataFromDB && Object.keys(logbookDataFromDB).length > 0) {
                // Update existing data
                const response = await dataService.putData(`/logbook/update/?userId=${userId}&name=Week ${weekNumber}`, formData);
                console.log('response by update logbook', response)
                toast.success('Logbook updated successfully');
                setIsLogBookSaving(false)
                navigate('/logbook')
                // window.location.reload();

            } else {
                const response = await dataService.postData('/logbook/create', formData)
                    .then(function (response) {
                        console.log("Logbook saved sucessfully")
                        console.log('response by create logbook', response)

                        toast.success('Logbook Saved sucessfully')
                        setIsLogBookSaving(false)
                        navigate('/logbook')
                        // window.location.reload();




                    })
            }


        }
        catch (error) {

            console.log('error', error.response.data)
            toast.error(error.response.data.title)
            setIsLogBookSaving(false)


        }


    }
    const formatDateToYYYYMMDD = (dateString) => {
        return format(new Date(dateString), 'yyyy-MM-dd');
    };



    useEffect(() => {
        const fetchData = async () => {
            setIsRetrivingData(true)
            try {
                const response = await dataService.getData(`/logbook/getIndividualLogBook?userid=${userId}&logbookname=Week ${weekNumber}`);
                setLogbookDataFromDB(response)
                // console.log('logbookdatafromdb', response);
                setIsRetrivingData(false)
            } catch (error) {

                console.error('Error fetching data:', error);
                setIsRetrivingData(false)
            }
        };

        fetchData();
    }, [userId, weekNumber]);


    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        var logStartDate = logbookDataFromDB?.logStartDate || today
        if (logStartDate !== '') {
            logStartDate = formatDateToYYYYMMDD(logStartDate)
            setStartDate(logStartDate);
            // setEndDate()
        }
        var logEndDate = logbookDataFromDB?.logEndDate || today
        if (logEndDate !== '') {
            logEndDate = formatDateToYYYYMMDD(logEndDate)
            setEndDate(logEndDate);
            // setEndDate()
        }
        // console.log(logbookDataFromDB.shift)

        setSelectedShift(logbookDataFromDB.shift || '');
        setDepartment(logbookDataFromDB?.department || '')
        setSection(logbookDataFromDB?.section || '') 
        setTaskContent(logbookDataFromDB?.task || defaultTaskContent)
        setObservationContent(logbookDataFromDB?.observation || '')
        // setImages(logbookDataFromDB?.taskImages||[null, null, null, null])

        // console.log('logbookDataFromDB?.taskImages.length', logbookDataFromDB?.taskImages.length)


        const base64toImage = logbookDataFromDB?.taskImages?.map((item) => {
            if (item !== 'null') {
                const mimeType = getMimeType(item);
                const dataUrl = `data:${mimeType};base64,${item}`;
                return dataUrl
            }else if(item === 'null'){
                return null
            }

        });
        // const filteredImages = base64toImage?.filter((image) => image !== null) || [null, null, null, null];
        // setImages(filteredImages);
        base64toImage && setImages([base64toImage[0], base64toImage[1], base64toImage[2], base64toImage[3]] || [null, null, null, null])

        setImageTexts(logbookDataFromDB?.taskImagesDescriptions || ['', '', '', ''])
    }, [logbookDataFromDB]);

    return (
        <div className='mt-2 w-full '>
            <div className='flex justify-between'>
                <Title title={` Week ${weekNumber}`} />
            </div>

            <div className='flex justify-between mt-4'>
                <div className='flex items-end'>
                    {weekFields.map((item, index) => (
                        <span
                            key={index}
                            className={`font-poppins text-gray-500 cursor-pointer px-8 pb-4 text-xs md:text-[13.33px] ${activeField === item ? 'border-b-[3px] border-primary-title ' : ''}`}
                            onClick={() => setActiveField(item)}
                        >
                            {item}
                        </span>
                    ))}
                </div>
            </div>

            <hr className='border border-slate-300 -mx-8' />

            {
                isRetrivingData ?
                    (
                        <div className="flex justify-center items-center w-full mt-32">
                            <svg aria-hidden="true" role="status" className='inline h-12 w-12 md:w-28 md:h-28 me-3 text-primary-title animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                        </div>
                    ) : (
                        <div className='mt-4'>
                            {activeField === `Logbook week-${weekNumber}` && (
                                <div>
                                    <div className='mt-2'>
                                        <TopField
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedShift={selectedShift}
                                            department={department}
                                            section={section}
                                            handleStartDateChange={handleStartDateChange}
                                            handleEndDateChange={handleEndDateChange}
                                            handleShiftChange={handleShiftChange}
                                            handleDepartment={handleDepartment}
                                            handleSection={handleSection}
                                            editable={editable}
                                        />
                                    </div>
                                    <div>
                                        <SubTitle title="Task Carried Out" />
                                        <div className='flex flex-col gap-x-5 gap-y-5 p-4 px-8 bg-primary-background rounded-lg py-4'>
                                            <span className='font-poppins text-lg text-[#6E7C87] text-[16px]'>Please fill in with bullets with tasks you have performed this week.</span>
                                            <TextEditor value={taskContent} onChange={handleTaskChange} editable={editable} />
                                        </div>
                                    </div>

                                    <div>
                                        <SubTitle title="Observation" />
                                        <div className='flex flex-col gap-x-5 gap-y-5 p-4 px-8 bg-primary-background rounded-lg py-4'>
                                            <span className='font-poppins text-lg text-[#6E7C87] text-[16px]'>Please write your observation this week in 150-200 words.</span>
                                            <TextEditor value={observationContent} onChange={handleObservationChange} maxWords={200} editable={editable} />
                                        </div>
                                    </div>

                                    <SubTitle title="Pictures" />
                                    <div className='flex flex-col gap-x-5 gap-y-5 p-4 px-8 bg-primary-background rounded-lg py-4'>
                                        <span className='font-poppins text-lg text-[#6E7C87] text-[16px]'>Minimum of 2 pictures are required and maximum of 4 pictures</span>
                                        <div className='flex flex-wrap gap-3  '>
                                            {images.map((image, index) => (
                                                <ImageUploader
                                                    key={index}
                                                    index={index}
                                                    image={image}
                                                    handleImageChange={handleImageChange}
                                                    imageText={imageTexts[index]}
                                                    handleImageTextChange={handleImageTextChange}
                                                    changeImage="True"
                                                    weekNumber={weekNumber}
                                                    userId={userId}
                                                    editable={editable}
                                                />
                                            ))}
                                        </div>
                                    </div>

                                    {
                                        adminData?.role !== 'PartnerCenter' && (
                                            <div className='flex justify-end mt-8 space-x-4'>
                                                {/* <Button title="Save as draft" className="px-8 py-3 rounded-md bg-white border-2 border-primary-title text-[#2b5972] font-medium hover:bg-primary-title hover:text-white" /> */}
                                                <button disabled={isLogBookSaving} type='button' className="px-8 py-3 rounded-md bg-white border-2 border-primary-title text-[#2b5972] font-medium hover:bg-primary-title hover:text-white" onClick={handleSaveClick}>
                                                    {
                                                        isLogBookSaving ? (
                                                            <svg aria-hidden="true" role="status" class='inline w-8 h-8 me-3 text-primary-title animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                            </svg>
                                                        ) : (
                                                            <span className='font-poppins text-[14px]'>Save Week {weekNumber}</span>
                                                        )


                                                    }
                                                </button>
                                                {/* <Button title="Completed" className="px-8 py-3 border-2 border-primary-title rounded-md font-medium hover:bg-white hover:text-primary-title" /> */}
                                            </div>
                                        )
                                    }


                                </div>
                            )}
                            {activeField === "Sample Logbook" && (
                                <SampleLogBook />
                            )}
                        </div>
                    )
            }


        </div>
    );
};

export default IndividualWeek;
