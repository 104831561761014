import { createSlice } from '@reduxjs/toolkit'

const clickedUser = JSON.parse(localStorage.getItem('clickedUser'));

const initialState = clickedUser ? {  clickedUser } : {  clickedUser: null };




export const adminClickedUser = createSlice({
    name: 'adminclickeduser',
    initialState,
    reducers: {
        getClickedUserData: (state, action) => {

           
            state.clickedUser = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem('clickedUser', JSON.stringify(action.payload));
        },
        

    },
})

// Action creators are generated for each case reducer function
export const { getClickedUserData } = adminClickedUser.actions

export default adminClickedUser.reducer