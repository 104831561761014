import React from 'react'

const ProfileTitle = ({ title }) => {
    return (
        <div>
            <span className='text-[#11232E] font-semibold text-lg md:text-[19px] font-poppins'>{title}</span>
            <div className='hidden md:block mt-4'>
                <hr className='border-t-2 border-gray-300' />

            </div>
        </div>
    )
}

export default ProfileTitle