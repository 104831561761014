import React, { useEffect, useState } from 'react'

// mui dialog
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import dataService from '../services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UploadPhoto = () => {
  const [open, setOpen] = useState(false);
  const [caption, setCaption] = useState('');
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);


  // this below will store image that we will upload from our device in the form of file. 
  // previously when i tried to store data in the form of url, we could preview image but backend looked for file
  // so to preview image, we need to handle seperate state now
  const [previewImage, setPreviewImage] = useState(null) //in url format
  const [imageUpload, setimageUpload] = useState(null) //in file format
  const navigate = useNavigate()

  var user = localStorage.getItem('user');
  var admin = localStorage.getItem('admin');
  var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)

  var loggedInData = null;
  if (user) {
    loggedInData = JSON.parse(user);
    console.log('Logged in data (user):', loggedInData);
  } else if (userClickedByAdmin) {
    loggedInData = userClickedByAdmin;
    console.log('data of particular user clicked by admin:', loggedInData);
  } else {
    console.log('No user logged in or admin has not clicked any user');
    // Handle the case where no one is logged in
  }
  const { userId, imageUrl } = loggedInData


  // this below admin data will only be used for the validation case of if admin with role PartnerCenter and userclicked by admin also then we dont allow them to edit any user
  const adminData = JSON.parse(admin)
  const adminRole = adminData?.role

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleUploadImage = () => {
    document.getElementById('hiddenFileInput').click();
  };
  const handleTextAreaChange = (e) => {
    setCaption(e.target.value)
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) { // Check if the file size is less than or equal to 2MB
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //     setimageUpload(reader.result);
      // };
      // reader.readAsDataURL(file);


      // above code worked fine but the image was then converted into url like:
      // /9j/4AAQSkZJRgABAQEAYABgAAD/2wCEAAgGBgcGBQg. 
      // the issue was that backend handled data as file and not url 
      setimageUpload(file)
    } else {
      toast.error("File size exceeds the 2MB limit");
    }
  };

  // const handleUploadImageInDB = () => {
  const handleUploadImageInDB = async () => {
    setIsUploadingPhoto(true)

    console.log('upload img clicked')
    console.log('formdata upload certificate', {
      'userId': userId,
      'files': imageUpload,
      'caption': caption
    })

    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('files', imageUpload);
    // formData.append('certificateName', "Certificate");

    formData.append('certificateImage', 'abcd');
    formData.append('caption', caption)

    try {
      const response = await dataService.postData('/Certificate/create', formData)
      console.log('certificate upload success response', response)
      toast.success('Certificate Uploaded Sucessfully')
      window.location.reload()
      setIsUploadingPhoto(false)
    } catch (error) {
      toast.error(error.response.data.title || error.response.data || 'Please provide both image and caption')
      console.log("Certificate upload failure error", error);
      setIsUploadingPhoto(false)

    }
  }

  useEffect(() => {
    // this code should convert file image to the url so that we can preview the image.
    if (imageUpload) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(imageUpload);

    }
  }, [imageUpload])

  return (
    <div className=''>
      <div className='hidden md:flex mt-5 md:justify-end'>
        {/* <button className="bg-primary-title cursor-pointer px-8 pb-4 text-lg font-poppins rounded-md flex justify-center items-center text-white ">Upload a Photo</button> */}

        {
          adminData?.role !== 'PartnerCenter' && (
            <button type="button" className='flex font-poppins justify-end items-center bg-primary-title rounded-md px-6 p-3 text-white transition ease-in-out delay-150 hover:-translate-y-2 hover:scale-110 duration-300 absolute bottom-5 right-4 md:right-8 text-[14px]' onClick={handleClickOpen}>Upload a Photo</button>

          )
        }

        {/* dialog starts */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" className='text-primary-title text-lg font-semibold bg-primary-background font-poppins '>
            {"Upload Certificate"}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
                                Let Google help apps determine location. This means sending anonymous
                                location data to Google, even when no apps are running.
                            </DialogContentText> */}
            <div onClick={handleUploadImage} className='flex flex-col rounded-md border-dashed border-2 cursor-pointer w-full justify-center items-center p-8 mt-4 md:px-32 lg:px-12' >
              {previewImage ? (
                <>
                  <img src={previewImage} alt="Preview" className='w-full h-72 z-10 object-cover' />
                  <input type="file" id="hiddenFileInput" onChange={handleImageChange} style={{ display: 'none' }} />
                </>
              ) : (
                <>
                  <img src="assets/images/uploadimg.png" alt="" className='h-24' />
                  <div className="flex mt-4">
                    <span className="font-poppins font-bold text-primary-title">Upload Images</span>
                    {/* <div className="span font-poppins">or drag or drop</div> */}
                  </div>
                  <div className="flex">
                    <span>JPEG, PNG up to 2MB</span>
                  </div>
                  <input type="file" id="hiddenFileInput" onChange={handleImageChange} style={{ display: 'none' }} />
                </>
              )}

            </div>

            <div className='mt-4 mb-3 font-poppins text-primary-title'>Caption</div>
            <textarea name="" id="" value={caption} onChange={handleTextAreaChange} className='outline-none rounded-md bg-primary-background w-full h-28 p-2 resize-none' placeholder='Write your certificate description'></textarea>
          </DialogContent>
          <div className='flex p-4 justify-end gap-x-4 bg-primary-background'>
            {/* <Button autoFocus className='bg-primary-title' onClick={handleUploadImageInDB} >
              Upload
            </Button> */}
            <button onClick={handleUploadImageInDB} className='bg-primary-title  text-white text-lg font-base p-2 font-poppins rounded-md px-4'>
              {
                isUploadingPhoto ? (
                  <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                  </svg>

                ) : (
                  <span>Upload</span>
                )
              }
            </button>
            {/* <Button autoFocus>
              Cancel
            </Button> */}
            <button onClick={handleClose} className='text-primary-title  border-2 border-primary-title font-poppins text-lg font-base p-2 rounded-md px-4'>Cancel</button>

          </div>
        </Dialog>
      </div>


      {/* upload for the mobile view */}
      <div className='mt-4 md:hidden'>
        {/* <button className="bg-primary-title cursor-pointer px-8 pb-4 text-lg font-poppins rounded-md flex justify-center items-center text-white ">Upload a Photo</button> */}
        {
          adminData?.role !== 'PartnerCenter' && (
            <button type="button" className='flex font-poppins justify-center items-center bg-primary-title rounded-sm  text-white w-full p-2 px-4 transition ease-in-out delay-150 hover:-translate-y-2 hover:scale-110 duration-300 text-[14px]' onClick={handleClickOpen}>Upload a Photo</button>

          )
        }

        {/* dialog starts */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className='md:hidden'
        >
          <DialogTitle id="responsive-dialog-title" className='text-primary-title bg-primary-background font-poppins '>
            {"Upload Certificate"}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
                                Let Google help apps determine location. This means sending anonymous
                                location data to Google, even when no apps are running.
                            </DialogContentText> */}
            <div onClick={handleUploadImage} className='flex flex-col rounded-sm border-dashed border-2 cursor-pointer w-full justify-center items-center p-2 mt-4 h-80 ' >
              {previewImage ? (
                <>
                  <img src={previewImage} alt="Preview" className='w-full h-72 rounded-md    z-10 object-cover' />
                  <input type="file" id="hiddenFileInput" onChange={handleImageChange} style={{ display: 'none' }} />
                </>
              ) : (
                <>
                  <img src="assets/images/uploadimg.png" alt="" className='h-24' />
                  <div className="flex mt-4">
                    <span className="font-poppins font-bold text-primary-title">Upload Images</span>
                    {/* <div className="span font-poppins">or drag or drop</div> */}
                  </div>
                  <div className="flex">
                    <span>JPEG, PNG up to 2MB</span>
                  </div>
                  <input type="file" id="hiddenFileInput" onChange={handleImageChange} style={{ display: 'none' }} />
                </>
              )}

            </div>

            <div className='mt-4 mb-3 font-poppins text-primary-title'>Caption</div>
            <textarea name="" id="" value={caption} onChange={handleTextAreaChange} className='outline-none rounded-md bg-primary-background w-full h-28 p-2 resize-none' placeholder='Write your certificate description'></textarea>
          </DialogContent>
          <DialogActions>
            {/* <Button autoFocus onClick={handleUploadImageInDB} >
              Upload
            </Button> */}
            <button onClick={handleUploadImageInDB} className='bg-primary-title  text-white text-lg font-base p-2 font-poppins rounded-md px-4'>
              {
                isUploadingPhoto ? (
                  <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                  </svg>

                ) : (
                  <span>Upload</span>
                )
              }
            </button>
            {/* <Button onClick={handleClose} autoFocus>
              Cancel
            </Button> */}
            <button onClick={handleClose} className='text-primary-title  border-2 border-primary-title font-poppins text-lg font-base p-2 rounded-md px-4'>Cancel</button>

          </DialogActions>
        </Dialog>
      </div>
    </div>

  )
}

export default UploadPhoto