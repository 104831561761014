import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ImageBox = ({ src, image_name }) => {
    return (
        <div className='bg-primary-background auto flex flex-col justify-center p-3 pb-4 md:pb-8 md:space-y-3 sm:rounded-sm md:rounded-md px-4 shadow-lg'>
            <div className='hidden md:justify-end md:flex'>
                <MoreVertIcon />
            </div>
            <div className='relative  md:hidden'>
                <MoreVertIcon style={{ fontSize: '1rem' }} className='absolute -top-2 -right-4' />
            </div>
            <div className='flex justify-center border-b-2 border-gray-400 pb-3 md:pb-6'>
                {src ? (
                    <img src={src} alt="certificate" className='sm:h-16 md:h-40 w-auto' />
                ) : (
                    <img src="assets/images/certificate.png" alt="" className='sm:h-16 md:h-40 w-auto' />)}


            </div>

            <div className='pt-2 md:px-4'>
                <span className='text-primary-title text-sm md:text-lg font-bold'>{image_name}.png</span>
            </div>

        </div>
    )
}
export default ImageBox