import React from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Marksheets from './Marksheets'
import { useSelector } from 'react-redux'

const Index = () => {
  const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger)

  return (
    <div className={`block ${openMobileSideBar ? '' : 'flex'}`}>


      {/* <div className={` hidden md:block ${isSidebarCollapsed ? 'w-20' : 'w-[300px]'}`}> */}
      <div className={` hidden md:block ${isSidebarCollapsed ? 'w-20  ' : 'md:w-[250 px] lg:w-[300px]'}`}>

        <Sidebar />

      </div>
      {/* <div className={` ${isSidebarCollapsed ? 'w-20' : 'w-[300px]'}`}> */}
      <div className={` ${openMobileSideBar ? 'block fixed top-0 z-20' : 'hidden'} w-lvw block md:hidden`}>


        <Sidebar />

      </div>
      <div className={`w-full  border-red-700 ${isSidebarCollapsed ? 'w-[100%-80px ]' : 'w-5/6'} ${openMobileSideBar ? 'w-full top-0' : 'w-full'}`} >
        <Navbar showStudent={true}/>
         
          <Marksheets/>
          {/* <IndividualWeek/>  */}
          

          
        </div>

      </div> 
  )
}

export default Index;