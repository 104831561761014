import { createSlice } from '@reduxjs/toolkit'
const admin = JSON.parse(localStorage.getItem('admin'));
const initialState = admin ? { isLoggedIn: true, admin } : { isLoggedIn: false, admin: null };

// const initialState = {
//     adminLoginData: {},
//     // token: ''

// }

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        getAdminData: (state, action) => {
            console.log('data after being logged in one', action.payload)

            state.isLoggedIn = true;
            state.admin = action.payload.admin;
            // localStorage.setItem('admin', JSON.stringify(action.payload.admin));
            localStorage.setItem('admin', JSON.stringify(action.payload));
            console.log('data after being logged in two', action.payload)


            // state.token = action.payload.token

            // localStorage.setItem('admin', JSON.stringify(action.payload))
        },

        updateAdminData: (state, action) => {

            console.log('action.payload', action.payload)
            state.admin = action.payload;
            localStorage.setItem('admin', JSON.stringify(action.payload));
          },

        adminLogout: (state, action)=>{
          console.log('hi back');
          state.isLoggedIn = false;
          state.user = null;
          localStorage.removeItem('admin');
          localStorage.removeItem('user')
          localStorage.removeItem('clickedUser')
            // localStorage.clear();
        },

    },
})

// Action creators are generated for each case reducer function
export const { getAdminData, adminLogout , updateAdminData} = adminSlice.actions

export default adminSlice.reducer