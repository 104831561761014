import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import SidebarButton from '../sidebarButton/SidebarButton';
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../store/reducerSlices/userSlice';
import { adminLogout } from '../store/reducerSlices/adminSlice';
import { setOpenMobileSidebar } from '../store/reducerSlices/hamburgerSlice';

// toast
import { toast } from 'react-toastify';


const AdminSidebar = () => {


    const location = useLocation();
    const [activeButton, setActiveButton] = useState('Admin');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const admin = useSelector((state) => state.admin.admin)
    console.log('admin role', admin.role)



    const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger)
    // console.log('isSidebarCollapsed', isSidebarCollapsed)
    // console.log('openMobileSideBar', openMobileSideBar)

    useEffect(() => {
        switch (location.pathname) {
            case '/admin':
                setActiveButton('Admin');
                break;
            case '/admin/students':
                setActiveButton('Students');
                break;
            case '/admin/profile':
                setActiveButton('Profile');
                break;

            default:
                setActiveButton('');
                break;
        }
    }, [location.pathname]);

    const handleLogout = () => {
        try {
            
            dispatch(userLogout());
            dispatch(adminLogout());
            navigate('/user/login')
            navigate('/admin/login');
            window.location.reload()
            toast.success('Logged Out Sucessfully')
        } catch (error) {
            console.error('Navigation error:', error);
            toast.error('Logged Out Failed')

        }
    };

    return (
        <>
            {
                openMobileSideBar ? (
                    <div className=' w-full h-lvh text-white sticky left-0 flex'>
                        <div className="w-5/6  bg-primary-title h-full">
                            <Link to="/admin">
                                <div className={` ${isSidebarCollapsed ? 'mt-10 ' : ''}flex justify-center`}>
                                    <img src="/assets/images/Logo.png" alt="Logo" className={`h-16 ${isSidebarCollapsed ? 'hidden ' : 'block'}`} />
                                </div>
                            </Link>
                            <div className="mt-10">
                                {
                                    admin?.role === "Administrator" && (
                                        <Link to="/admin">
                                            <SidebarButton
                                                icon={HomeRoundedIcon}
                                                title="Admin"
                                                isActive={activeButton === 'Admin'}
                                                onClick={() => { setActiveButton('Admin'); dispatch(setOpenMobileSidebar()) }}

                                            />
                                        </Link>
                                    )
                                }

                                <Link to="/admin/students">
                                    <SidebarButton
                                        icon={WorkspacePremiumRoundedIcon}
                                        title="Students"
                                        isActive={activeButton === 'Students'}
                                        onClick={() => { setActiveButton('Students'); dispatch(setOpenMobileSidebar()) }}

                                    />
                                </Link>
                                <Link to="/admin/profile">
                                    <SidebarButton
                                        icon={AccountCircleRoundedIcon}
                                        title="Profile"
                                        isActive={activeButton === 'Profile'}
                                        onClick={() => { setActiveButton('Profile'); dispatch(setOpenMobileSidebar()) }}

                                    />
                                </Link>


                            </div>
                            <div className="absolute bottom-5 w-5/6 " onClick={handleLogout}>
                                <button type='button' className='bg-[#9A3C3C] flex justify-center  p-2 px-4 text-lg text-white font-poppins w-full'>Logout</button>

                                {/* <Button title="Logout" className="rounded-md bg-[#9A3C3C] w-full" /> */}
                            </div>
                        </div>
                        <div className="w-1/6 bg-neutral-700 opacity-45" onClick={() => dispatch(setOpenMobileSidebar())}>

                        </div>

                    </div >
                )

                    : (
                        <div className={`p-5 bg-[#F6F8F9] h-screen sticky top-0 transition-all duration-300    md:${isSidebarCollapsed ? 'w-20  ' : 'md:w-64 lg:w-72  '}  `}>

                            <Link to="/admin">
                                <div className={` ${isSidebarCollapsed ? 'mt-10 ' : ''}flex justify-center`}>
                                    <img src="/assets/images/Logo.png" alt="Logo" className={`h-16 ${isSidebarCollapsed ? 'hidden ' : 'block'}`} />
                                </div>
                            </Link>
                            <div className="mt-10">
                                {
                                    admin?.role === "Administrator" && (
                                        <Link to="/admin">
                                            <SidebarButton
                                                icon={HomeRoundedIcon}
                                                title="Admin"
                                                isActive={activeButton === 'Admin'}
                                                onClick={() => { setActiveButton('Admin') }}

                                            />
                                        </Link>
                                    )
                                }
                                <Link to="/admin/students">
                                    <SidebarButton
                                        icon={WorkspacePremiumRoundedIcon}
                                        title="Students"
                                        isActive={activeButton === 'Students'}
                                        onClick={() => { setActiveButton('Students') }}

                                    />
                                </Link>
                                <Link to="/admin/profile">
                                    <SidebarButton
                                        icon={AccountCircleRoundedIcon}
                                        title="Profile"
                                        isActive={activeButton === 'Profile'}
                                        onClick={() => { setActiveButton('Profile') }}

                                    />
                                </Link>
                            </div>
                            <div className={`absolute bottom-5   ${isSidebarCollapsed ? 'md:w-12 ' : 'md:w-44 lg:w-60 '}`} onClick={handleLogout} >
                                <button className={`bg-[#9A3C3C] w-full px-4 text-lg text-white rounded-xl font-poppins flex justify-center items-center ${isSidebarCollapsed ? "p-3" : "p-2"}`} type='button'>
                                    {!isSidebarCollapsed && <span>Logout</span>}
                                    <i className={`fa-solid fa-arrow-right-from-bracket  ${isSidebarCollapsed ? '' : 'ml-12'}`}></i>
                                </button>
                                {/* <Button title="Logout" className="rounded-md bg-red-600 w-full" /> */}
                            </div>
                        </div>
                    )




            }
        </>

    );
};

export default AdminSidebar;
