import React from 'react';
import { useSelector } from 'react-redux';

const ProfileLeftButton = ({ icon: Icon, title, isProfileLeftActive, onClick }) => {
    const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger)



    return (
        <div
            className={`flex items-center space-x-4 lg:space-x-4 pt-3 pb-3 pl-3 mt-3  cursor-pointer hover:bg-[#ebf6ff] ${isProfileLeftActive ? 'bg-[#D7EDFF] font-bold text-primary-title' : 'bg-transparent'} ${openMobileSideBar ? 'rounded-none bg-[#D7EDFF] hover:bg-slate-300' : 'rounded'} `}
            onClick={onClick}
        >
            {/*  */}
            <span className={`font-poppins text-[14px]  ${openMobileSideBar && isProfileLeftActive ? '#primary-title' : openMobileSideBar ? '#ffffff' : '#252C32'}`}>{title}</span>
        </div>
    );
};

export default ProfileLeftButton;