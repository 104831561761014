import React from 'react';
import { useSelector } from 'react-redux';

const SidebarButton = ({ icon: Icon, title, isActive, onClick }) => {
    const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger)
    console.log('openMobileSideBar', openMobileSideBar)



    return (
        <div
            className={`flex p-3 items-center   gap-x-4 lg:gap-x-4 pt-3 pb-3  mt-2  cursor-pointer hover:bg-[#ebf6ff] ${isActive ? 'bg-[#D7EDFF] font-bold text-primary-title' : 'bg-transparent'} ${isSidebarCollapsed? 'justify-center':''} ${openMobileSideBar ? 'rounded-none bg-[#D7EDFF] hover:bg-slate-300 pl-4 flex ' : 'rounded pl-3 '} `}
            onClick={onClick}
        >


            {Icon && (
                <Icon isActive={isActive} openMobileSideBar={openMobileSideBar} />
            )}
            {!isSidebarCollapsed &&
                <span
                    className="font-poppins text-[14px] "
                    style={{
                        color: openMobileSideBar && isActive ? '#2b5972' :
                            openMobileSideBar ? '#ffffff' :
                                isActive ? '#2b5972' : '#252C32'
                    }}
                >
                    {title}
                </span>}
        </div>
    );
};

export default SidebarButton;

// import React from 'react';

// const SidebarButton = ({ icon: Icon, title, isActive, onClick, isCollapsed }) => {
//     return (
//         <div
//             className={`flex items-center p-2 my-2 cursor-pointer transition-colors duration-200 ${
//                 isActive ? 'bg-blue-500 text-white' : 'text-gray-700'
//             }`}
//             onClick={onClick}
//         >
//             <Icon className="mr-2" />
//             {!isCollapsed && <span>{title}</span>}
//         </div>
//     );
// };

// export default SidebarButton;

