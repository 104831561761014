import React from 'react'
import ProfileTitle from '../ProfileTitle'
import Account from './Account'
import AccountTest from './AccountTest'

const Index = () => {
  return (
    <div className='w-full md:p-10  md:bg-primary-background rounded-lg lg:p-10 md:shadow-md '>

      {/* <ProfileTitle  title="Course Details"/> */}
      <ProfileTitle title="Account Settings" />

      {/* <CourseDetail/> */}
      <Account />
      {/* <AccountTest/> */}


    </div>
  )
}

export default Index