import React, { useEffect, useState } from 'react'
import Title from '../components/Title'
import { useSelector } from 'react-redux'
import dataService from '../services/data-service'
import Button from '../components/Button'
import { toast } from 'react-toastify'

const Marksheets = () => {
    const [isRetrivingData, setIsRetrivingData] = useState(false)
    const [isSubmittingMarksheet, setIsSubmittingMarksheet] = useState(false)
    console.log('isSubmittingMarksheet', isSubmittingMarksheet)


    const [date, setDate] = useState('')
    const [shortCode, setShortCode] = useState('')
    const [marksheetsData, setMarksheetsData] = useState('')


    // state for different marks obtained
    const [presFirst, setPresFirst] = useState('')
    const [presSecond, setPresSecond] = useState('')
    const [presThird, setPresThird] = useState('')
    const [presFourth, setPresFourth] = useState('')
    const [presFifth, setPresFifth] = useState('')

    const [contentFirst, setContentFirst] = useState('')
    const [contentSecond, setContentSecond] = useState('')
    const [contentThird, setContentThird] = useState('')

    const [pictureFirst, setPictureFirst] = useState('')
    const [pictureSecond, setPictureSecond] = useState('')
    const [pictureThird, setPictureThird] = useState('')
    // const [pictureFourth, setPictureFourth] = useState('')

    const [performance, setPerformance] = useState('')

    const [numberFirst, setNumberFirst] = useState('')
    const [numberSecond, setNumberSecond] = useState('')
    const [totalMark, setTotalMark] = useState(0)
    // const [internalVerifier, setInternalVerifier] = useState('')
    console.log('totalMark', totalMark)
    // var totalMark = presFirst + presSecond
    // Add this function at the beginning of your component
    const calculateTotalMarks = () => {
        return Number(presFirst) + Number(presSecond) + Number(presThird) +
            Number(presFourth) + Number(presFifth) + Number(contentFirst) +
            Number(contentSecond) + Number(pictureFirst) + Number(pictureSecond) +
            Number(pictureThird) + Number(performance) + Number(numberFirst) +
            Number(numberSecond);
    };

    // Replace the existing useEffect for total mark calculation
    useEffect(() => {
        setTotalMark(calculateTotalMarks());
    }, [presFirst, presSecond, presThird, presFourth, presFifth, contentFirst,
        contentSecond, pictureFirst, pictureSecond, pictureThird, performance,
        numberFirst, numberSecond]);

    // const [numberSecond, setNumberSecond] = useState('')
    // const [numberThird, setNumberThird] = useState('')
    // const [numberFourth, setNumberFourth] = useState('')
    // const [numberFifth, setNumberFifth] = useState('')




    var user = localStorage.getItem('user');
    var admin = localStorage.getItem('admin');
    var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)
    var adminRole = null
    var loggedInData = null;

    if (user) {
        loggedInData = JSON.parse(user);
        console.log('Logged in data  (user):', loggedInData);
    } else if (userClickedByAdmin) {
        loggedInData = userClickedByAdmin;
        console.log('data of particular user clicked by admin:', loggedInData);
    } else {
        console.log('No user logged in or admin has not clicked any user');
        // Handle the case where no one is logged in
    }

    var role = null
    if (admin) {
        const adminData = JSON.parse(admin)
        role = adminData.role


    } else {
        role = 'User'
    }

    console.log('role', role)
    const { courseId, userId, courseCode } = loggedInData



    const handleDateChange = (e) => {
        setDate(e.target.value)

    }
    useEffect(() => {
        if (courseId === 1) {
            setShortCode('DPC');
        } else if (courseId === 2) {
            setShortCode('DHM');
        } else if (courseId === 3) {
            setShortCode('HDHM');
        }
        console.log('shortCode', shortCode)


    })

    const handleSubmitMarksheet = async () => {
        setIsSubmittingMarksheet(true)


        try {

            marksheetsData.Criteria['Presentation of Logbook'][1].obtained = presFirst
            marksheetsData.Criteria['Presentation of Logbook'][2].obtained = presSecond
            marksheetsData.Criteria['Presentation of Logbook'][3].obtained = presThird
            marksheetsData.Criteria['Presentation of Logbook'][4].obtained = presFourth
            marksheetsData.Criteria['Presentation of Logbook'][5].obtained = presFifth


            marksheetsData.Criteria['Content'][1].obtained = contentFirst
            marksheetsData.Criteria['Content'][2].obtained = contentSecond
            // marksheetsData.Criteria['Content'][3].obtained = contentThird

            marksheetsData.Criteria['Pictures'][1].obtained = pictureFirst
            marksheetsData.Criteria['Pictures'][2].obtained = pictureSecond
            marksheetsData.Criteria['Pictures'][3].obtained = pictureThird

            marksheetsData.Criteria['Performance Appraisal Form'][1].obtained = performance

            marksheetsData.Criteria['Number of departments/sections'].obtained = numberFirst
            marksheetsData.Criteria['Recognition or award from Hotel'].obtained = numberSecond

            marksheetsData['Total Marks'].obtained = totalMark


            const stringifiedData = JSON.stringify(marksheetsData)
            console.log(typeof (stringifiedData))
            console.log('stringifiedData', stringifiedData)


            const response = await dataService.patchData(`/Marksheet/uploadMarksheet?userId=${userId}`, { "marksheetData": stringifiedData })
            console.log('response', response)
            toast.success('Marksheet Data Updated Sucessfully')



        }
        catch (error) {
            console.log('error.message', error.message)
            toast.error('Updating marksheet Failed')
        } finally {
            setIsSubmittingMarksheet(false)
        }


    }



    useEffect(() => {
        if (shortCode) {
            console.log('shortCode', shortCode)
            const fetchMarksheetData = async () => {
                setIsRetrivingData(true)

                try {
                    const response = await dataService.getData(`/Marksheet/marksheet?shortCode=${shortCode}&userId=${userId}`)
                    console.log('response', response)
                    setIsRetrivingData(false)

                    console.log('typeof data', typeof (response.marksheetData))
                    // the data received was in the form of JSON string like below:
                    // const jsonString = '{"name": "John", "age": 30}'; and we cannot access jsonString.name which gives and gave undefined 
                    const stringData = response.marksheetData
                    // parsing converts string to JS object
                    const parsedData = JSON.parse(stringData)
                    console.log('type of data after parsing', typeof (parsedData))
                    console.log('data after parsing', parsedData)
                    const presFirstHai = parsedData.Criteria['Presentation of Logbook'][1].obtained
                    console.log('presFirstHai', presFirstHai)


                    setMarksheetsData(parsedData)

                    setPresFirst(parsedData && parsedData.Criteria['Presentation of Logbook'][1].obtained >= 0 ? parsedData.Criteria['Presentation of Logbook'][1].obtained : '')
                    setPresSecond(parsedData && parsedData.Criteria['Presentation of Logbook'][2].obtained >= 0 ? parsedData.Criteria['Presentation of Logbook'][2].obtained : '')
                    setPresThird(parsedData && parsedData.Criteria['Presentation of Logbook'][3].obtained >= 0 ? parsedData.Criteria['Presentation of Logbook'][3].obtained : '')
                    setPresFourth(parsedData && parsedData.Criteria['Presentation of Logbook'][4].obtained >= 0 ? parsedData.Criteria['Presentation of Logbook'][4].obtained : '')
                    setPresFifth(parsedData && parsedData.Criteria['Presentation of Logbook'][5].obtained >= 0 ? parsedData.Criteria['Presentation of Logbook'][5].obtained : '')

                    setContentFirst(parsedData && parsedData.Criteria['Content'][1].obtained >= 0 ? parsedData.Criteria['Content'][1].obtained : '')
                    setContentSecond(parsedData && parsedData.Criteria['Content'][2].obtained >= 0 ? parsedData.Criteria['Content'][2].obtained : '')
                    // setContentThird(parsedData && parsedData.Criteria['Content'][3].obtained)

                    setPictureFirst(parsedData && parsedData.Criteria['Pictures'][1].obtained >= 0 ? parsedData.Criteria['Pictures'][1].obtained : '')
                    setPictureSecond(parsedData && parsedData.Criteria['Pictures'][2].obtained >= 0 ? parsedData.Criteria['Pictures'][2].obtained : '')
                    setPictureThird(parsedData && parsedData.Criteria['Pictures'][3].obtained >= 0 ? parsedData.Criteria['Pictures'][3].obtained : '')

                    setPerformance(parsedData && parsedData.Criteria['Performance Appraisal Form'][1].obtained >= 0 ? parsedData.Criteria['Performance Appraisal Form'][1].obtained : '')

                    setNumberFirst(parsedData && parsedData.Criteria['Number of departments/sections'].obtained >= 0 ? parsedData.Criteria['Number of departments/sections'].obtained : '')
                    setNumberSecond(parsedData && parsedData.Criteria['Recognition or award from Hotel'].obtained >= 0 ? parsedData.Criteria['Recognition or award from Hotel'].obtained : '')

                    setTotalMark(parsedData && parsedData['Total Marks'].obtained >= 0 ? parsedData['Total Marks'].obtained : 0)



                } catch (error) {
                    toast.error('Error retriving data')
                    setIsRetrivingData(false)

                }

            }
            fetchMarksheetData();

        }


    }, [shortCode])
    console.log('marksheetsData', marksheetsData)
    console.log(presFirst)
    // console.log('Courses', marksheetsData )
    return (
        <div className='m-4 md:m-8 font-poppins '>
            <div className='flex justify-between'>
                <Title title="Marking Guideline" />
            </div>
            {
                isRetrivingData ?
                    (
                        <div className="flex justify-center items-center w-full mt-32">
                            <svg aria-hidden="true" role="status" className='inline h-12 w-12 md:w-28 md:h-28 me-3 text-primary-title animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                        </div>
                    ) : (
                        <div className='grid mt-4 text-xs md:text-sm'>
                            <div className=" grid grid-cols-8  text-white md:font-poppins bg-primary-title text-xs md:text-sm ">
                                <div className=" col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Courses}</div>
                                <div className="col-start-7 col-end-9 grid justify-center border-2 px-2 py-1 border-black">Marks</div>


                            </div>
                            <div className="bg-primary-title grid grid-cols-8 text-white md:font-poppins ">
                                <div className=" col-start-1 col-end-7 border-[1px] border-black px-2 py-1 text-xs md:text-sm ">Criteria</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">Total</div>
                                <div className=" col-start-8 col-end-9 grid justify-center border-[1px] border-black md:px-2 py-1">Obtained</div>
                            </div>
                            <div className="bg-primary-background px-2 py-1 font-poppins font-semibold  md:text-base border-[1px] border-black">
                                A. {marksheetsData && Object.keys(marksheetsData.Criteria)[0]}
                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][1].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][1].total}</div>
                                {/* {
                                    role === 'User' &&

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={presFirst} onChange={(e) => (setPresFirst(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][1].obtained}</div>

                                    )

                                }
                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][2].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][2].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][2].obtained}</div>


                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={presSecond} onChange={(e) => (setPresSecond(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][2].obtained}</div>

                                    )

                                }
                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][3].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][3].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][3].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={presThird} onChange={(e) => (setPresThird(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][3].obtained}</div>

                                    )

                                }
                                {/* {
                                    adminRole === 'Administrator' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={presThird} onChange={(e) => (setPresThird(e.target.value))} /></div>

                                } */}
                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][4].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][4].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][4].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={presFourth} onChange={(e) => (setPresFourth(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][4].obtained}</div>

                                    )

                                }
                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][5].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][5].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][5].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={presFifth} onChange={(e) => (setPresFifth(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Presentation of Logbook'][5].obtained}</div>

                                    )

                                }
                            </div>




                            {/* contents */}
                            <div className="bg-primary-background px-2 py-1 font-poppins font-semibold md:text-base border-[1px] border-black">
                                B. {marksheetsData && Object.keys(marksheetsData.Criteria)[1]}
                            </div>
                            <div className="">
                                <div className=" border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'].Heading}</div>

                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][1].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][1].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][1].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={contentFirst} onChange={(e) => (setContentFirst(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][1].obtained}</div>

                                    )

                                }

                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][2].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][2].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][2].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={contentSecond} onChange={(e) => (setContentSecond(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][2].obtained}</div>

                                    )

                                }

                            </div>
                            {/* <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][3].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][3].total}</div>
                                {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Content'][3].obtained}</div>

                                }
                                {
                                    adminRole === 'Administrator' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={contentThird} onChange={(e) => (setContentThird(e.target.value))} /></div>

                                }

                            </div> */}



                            {/* Pictures */}
                            <div className="bg-primary-background px-2 py-1 font-poppins font-semibold md:text-base border-[1px] border-black">
                                C.{marksheetsData && Object.keys(marksheetsData.Criteria)[2]}

                            </div>
                            <div className="">
                                <div className=" border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'].Heading}</div>

                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][1].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][1].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][1].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={pictureFirst} onChange={(e) => (setPictureFirst(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][1].obtained}</div>

                                    )


                                }

                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][2].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][2].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][2].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={pictureSecond} onChange={(e) => (setPictureSecond(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][2].obtained}</div>

                                    )

                                }


                            </div>
                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][3].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][3].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][3].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={pictureThird} onChange={(e) => (setPictureThird(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Pictures'][3].obtained}</div>

                                    )

                                }

                            </div>

                            {/*     performance appraisal */}
                            <div className="bg-primary-background px-2 py-1 font-poppins font-semibold md:text-base border-[1px] border-black">
                                D.{marksheetsData && Object.keys(marksheetsData.Criteria)[3]}

                            </div>

                            <div className="grid grid-cols-8">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Performance Appraisal Form'][1].text}</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Performance Appraisal Form'][1].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Performance Appraisal Form'][1].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={performance} onChange={(e) => (setPerformance(e.target.value))} /></div>

                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Performance Appraisal Form'][1].obtained}</div>

                                    )

                                }

                            </div>


                            {/* Number of department/section */}
                            <div className="bg-primary-background px-2 py-1 font-poppins font-semibold md:text-base border-[1px] border-black">
                                E. {marksheetsData && Object.keys(marksheetsData.Criteria)[4]}
                            </div>
                            <div className="">
                                <div className=" border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'].Heading}</div>

                            </div>
                            <div className='grid  grid-rows-4 grid-cols-8'>
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'][1].text}</div>
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'][2].text}</div>
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'][3].text}</div>
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'][4].text}</div>
                                <div className="grid row-start-1 row-end-5 col-start-7 col-end-8  px-2 py-1 justify-center items-center border-[1px] border-black">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="grid row-start-1 row-end-5 col-start-8 col-end-9  px-2 py-1 justify-center items-center border-[1px] border-black">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'].obtained}</div>


                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="grid row-start-1 row-end-5 col-start-8 col-end-9  px-2 py-1 justify-center items-center border-[1px] border-black"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={numberFirst} onChange={(e) => (setNumberFirst(e.target.value))} /></div>

                                    ) : (
                                        <div className="grid row-start-1 row-end-5 col-start-8 col-end-9  px-2 py-1 justify-center items-center border-[1px] border-black">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'].obtained}</div>

                                    )

                                }

                            </div>




                            {/* Recognition or reward */}
                            <div className="bg-primary-background px-2 py-1 font-poppins font-semibold md:text-base border-[1px] border-black">
                                F. {marksheetsData && Object.keys(marksheetsData.Criteria)[5]}
                            </div>
                            <div className="">
                                <div className=" border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Recognition or award from Hotel'].Heading}</div>

                            </div>
                            <div className='grid  grid-rows-4 grid-cols-8'>
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Recognition or award from Hotel'][1].text}</div>
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Recognition or award from Hotel'][2].text}</div>
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Recognition or award from Hotel'][3].text}</div>
                                {/* <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'][4].text}</div> */}
                                <div className="grid row-start-1 row-end-4 col-start-7 col-end-8  px-2 py-1 justify-center items-center border-[1px] border-black">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="grid row-start-1 row-end-4 col-start-8 col-end-9  px-2 py-1 justify-center items-center border-[1px] border-black">{marksheetsData && marksheetsData.Criteria['Number of departments/sections'].obtained}</div>


                                } */}
                                {
                                    role === 'Administrator' ? (
                                        <div className="grid row-start-1 row-end-4 col-start-8 col-end-9  px-2 py-1 justify-center items-center border-[1px] border-black"><input type='text' className='w-full h-full outline-none flex items-center justify-center' value={numberSecond} onChange={(e) => (setNumberSecond(e.target.value))} /></div>

                                    ) : (
                                        <div className="grid row-start-1 row-end-4 col-start-8 col-end-9  px-2 py-1 justify-center items-center border-[1px] border-black">{marksheetsData && marksheetsData.Criteria['Recognition or award from Hotel'].obtained}</div>

                                    )

                                }

                            </div>


                            {/* totoal marks */}
                            {/* {
                                // for DHM
                                courseId === 2 && ( */}
                            <div className="grid grid-cols-8 bg-primary-background font-poppins font-semibold">
                                <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1 grid justify-center">TOTAL MARKS (A+B+C+D+E+F)</div>
                                <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData['Total Marks'].total}</div>
                                {/* {
                                    role === 'User' &&
                                    <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData['Total Marks'].obtained}</div>

                                } */}
                                {
                                    role === 'Administrator' ? (
                                        // <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' onChange={handleTotalChange} /></div>
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{totalMark}</div>


                                    ) : (
                                        <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData['Total Marks'].obtained}</div>

                                    )

                                }
                            </div>
                            {/* )
                            } */}


                            {/* {
                                courseId === 1 && (
                                    <div className="grid grid-cols-8 bg-primary-background font-poppins font-semibold">
                                        <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1 grid justify-center">{marksheetsData && marksheetsData.Marks.text}</div>
                                        <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Marks.total}</div>
                                        {
                                            role === 'User' &&
                                            <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Marks.obtained}</div>

                                        }
                                        {
                                            adminRole === 'Administrator' &&
                                            <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' onChange={(e) => (setPresFirst(e.target.value))} /></div>

                                        }

                                    </div>
                                )
                            } */}
                            {/* {
                                courseId === 3 && (
                                    <div className="grid grid-cols-8 bg-primary-background font-poppins font-semibold">
                                        <div className="col-start-1 col-end-7 border-[1px] border-black px-2 py-1 grid justify-center">{marksheetsData && marksheetsData.Marks.text}</div>
                                        <div className="col-start-7 col-end-8 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Marks.total}</div>
                                        {
                                            role === 'User' &&
                                            <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1">{marksheetsData && marksheetsData.Marks.obtained}</div>

                                        }
                                        {
                                            adminRole === 'Administrator' &&
                                            <div className="col-start-8 col-end-9 grid justify-center border-[1px] border-black px-2 py-1"><input type='text' className='w-full h-full outline-none flex items-center justify-center' onChange={(e) => (setPresFirst(e.target.value))} /></div>

                                        }

                                    </div>
                                )
                            } */}



                            {/* second last div */}
                            {/* <div className='flex flex-col gap-y-6 p-8 border-[1px] border-black mt-4'>
                                <div className='flex flex-col md:flex-row md:items-center gap-y-4 gap-x-4 md:gap-x-8  lg:gap-x-16'> */}
                            {/* <div className="flex space-x-2 ">
                                        <span className=' font-semibold'>Name of Internal Verifier: </span>
                                        <input type="text" onChange={setInternalVerifier(e.target.value)} />
                                        <span>{marksheetsData && marksheetsData['Supervisor'].name}</span>
                                    </div> */}
                            {/* <div className="flex space-x-4  items-center">
                                        <span className=' font-semibold'>Date:</span>
                                        <input type="date" value={date.} onChange={handleDateChange} className='outline-none border-2 border-gray-400 rounded-md p-1' />
                                    </div> */}
                            {/* </div> */}
                            {/* <div className="flex items-center justify-start  space-x-5">
                                    <span>Signature of the Internal Verifier: </span>
                                    <img src="assets/images/signature.png" alt="" className='h-10' />
                                </div> */}

                            {/* </div> */}
                            {
                                role === 'Administrator' && (

                                    <button className='bg-primary-title  text-white text-[14px] mt-4 font-base p-3 font-poppins rounded-[5px] px-4 w-48' onClick={handleSubmitMarksheet} disabled={isSubmittingMarksheet}>
                                        {
                                            isSubmittingMarksheet ? (
                                                <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            ) : (
                                                <span>Submit Marksheet</span>
                                            )
                                        }
                                        {/* <Button title="Submit Marksheet" /> */}
                                    </button>
                                )

                            }

                        </div>
                    )
            }



        </div>
    )
}

export default Marksheets