import { lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import { store } from '../store'
import React from 'react'
import Dashboard from '../components/Dashboard';
import Certificates from '../certificates';
import LogBook from '../logbooks';
import LogBooks from '../logbooks/LogBook'
import IndividualWeek from '../logbooks/individual_week/IndividualWeek';
import PerformanceAppraisal from '../performanceAppraisal';
import Abstract from '../abstract';
import Marksheets from '../marksheets';
import StudentDecleration from '../profile/studentDecleration';
import CourseDetail from '../profile/courseDetails';


import Login from '../authentication/Login';
import Register from '../authentication/Register';
import RegisterStepTwo from '../authentication/RegisterStepTwo';
import ChangePassword from '../authentication/ChangePassword';
import ForgotPassword from '../authentication/ForgotPassword';
import PageNotFound from '../pageNotFound'

// admin import
import Students from '../admindashboard/students'
// import Users from '../users'
import Admins from '../admindashboard/admin'
import AdminProfile from '../admindashboard/profile';
import Profile from '../profile';
import Account from '../profile/account';
import UserLogin from '../authentication/UserLogin';
import SampleLogBook from '../logbooks/SampleLogBook';
import LogBox from '../logbooks/LogBox';
import { useDispatch, useSelector } from 'react-redux';
import AdminRegister from '../authentication/AdminRegister';
import AccountTest from '../profile/account/AccountTest';
import { userLogout } from '../store/reducerSlices/userSlice';
import { adminLogout } from '../store/reducerSlices/adminSlice';
import { toast } from 'react-toastify';


// check if admin is loggedIn or user is loggedIn if any of them have not loggedIn then set the default to user

//  Following code was giving issue related to the stale state of loggedInUser. When navigating after logging out i.e. clicking the logout button or like clicking the submit button on login
// though we had used navigate('/route') things would get re-rendered  but though the state in store changed, the change was not reflected here and loggedInUser took the previous value which it had during the first render.

//  This happens because the loggedInUser variable is initialized once when UserRoute component is first rendered, and it doesn't update when the state in store changes (in this case, when logging out).
// const loggedInUser = store.getState().admin.admin || store.getState().user.user
// console.log(loggedInUser)
// const loggedInUserType = loggedInUser === 'user'?.role || loggedInUser === 'user'?.role || 'User'
// console.log(loggedInUserType)





function UserRoute() {
    const dispatch = useDispatch()
    const loggedInUser = useSelector(state => state.admin.admin || state.user.user);

    // const [expirationTime, setExpirationTime] = useState(null);

    useEffect(() => {
        if (loggedInUser) {
            const storedLoginTime = loggedInUser.loginTime
            // console.log('loginTime', storedLoginTime)

            if (storedLoginTime) {
                // Convert the stored loginTime string into a Date object
                const loginTime = new Date(storedLoginTime);
                console.log('loginTime', loginTime)

                const expireAt = new Date(loginTime.getTime() + 238*60 * 1000);
                console.log('expireAt', expireAt.getTime())
                console.log('Date.now()', new Date(Date.now()))
                const currentTime = new Date(Date.now())
                console.log('Date.now()',currentTime.getTime())



                // Calculate the remaining time (milliseconds) until expiration
                const remainingTime = expireAt.getTime() - currentTime.getTime();
                console.log('remainingTime', remainingTime)

                if (remainingTime > 0) {
                    // Set a timer for the remaining time to log out the user
                    const timer = setTimeout(() => {
                        dispatch(userLogout());
                        dispatch(adminLogout());

                        toast.info("Session expired. Please log in again.");

                        // Optionally, clear the loginTime from sessionStorage
                        sessionStorage.removeItem('loginTime');
                    }, remainingTime);

                    // Clean up the timer on unmount or when loggedInUser changes
                    return () => clearTimeout(timer);
                } else {
                    // If the token has already expired, log out immediately
                    dispatch(userLogout());
                    dispatch(adminLogout());
                    toast.info("Session expired. Please log in again.");

                    // Optionally, clear the loginTime from sessionStorage
                    sessionStorage.removeItem('loginTime');
                }
            }
            // const tokenExpireTime = 1000 * 1000; // 162 minutes in milliseconds
            // // const expireAt = loginTime + tokenExpireTime;
            // // console.log('expireAt', expireAt)
            // console.log('tokenExpireTime', tokenExpireTime)

            // // setExpirationTime(expireAt);

            // const timer = setTimeout(() => {
            //     dispatch(userLogout());
            //     dispatch(adminLogout());

            //     toast.info("Session expired. Please log in again.");
            //     // }, expireAt - Date.now());
            // }, tokenExpireTime);


            // return () => clearTimeout(timer);
        }
    }, [dispatch, loggedInUser]);



    return (
        <Routes>
            {
                console.log('into user route')
            }
            <Route path="/user/login" element={loggedInUser ? <Navigate to="/" /> : <UserLogin />} />
            <Route path="/" element={loggedInUser ? <Dashboard /> : <Navigate to="/user/login" />} />


            <Route path="/profile" element={loggedInUser ? <Profile /> : <Navigate to="/user/login" />} >
                <Route index element={<Account />} />
                <Route path='account' element={<Account />} />
                <Route path='studentdecleration' element={<StudentDecleration />} />
                <Route path='coursedetail' element={<CourseDetail />} />

            </Route>

            {/* <Route path='/testprofile' element={<AccountTest />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/profile/account' element={<AccountTest />} /> */}
            {/* <Route path='/profile' element={<AccountTest />} /> */}




            {/* <Route path='/logbook' element={loggedInUser ? <LogBook /> : <Navigate to="/user/login" />} > */}
            <Route path='/logbook' element={<LogBook />} >

                <Route index element={<LogBooks />} />

                <Route path='samplelogbook' element={<SampleLogBook />} />
                <Route path='individualweek/:weekNumber' element={<IndividualWeek />} />

            </Route>







            <Route path='/certificates' element={loggedInUser ? <Certificates /> : <Navigate to="/user/login" />} />
            <Route path='performanceappraisal' element={loggedInUser ? <PerformanceAppraisal /> : <Navigate to="/user/login" />} />
            <Route path='/abstract' element={loggedInUser ? <Abstract /> : <Navigate to="/user/login" />} />
            <Route path='/markingguideline' element={loggedInUser ? <Marksheets /> : <Navigate to="/user/login" />} />

            {/* <Route path='/admin/login' element={<Login />} /> */}
            <Route path="/admin/login" element={loggedInUser ? <Navigate to="/" /> : <Login />} />
            <Route path="/admin" element={loggedInUser ? <PageNotFound /> : <Navigate to="/admin/login" />} />


            {/* <Route path='/user/login' element={<UserLogin />} /> */}

            <Route path='/registerstepone' element={<Register />} />
            <Route path='/registersteptwo' element={<RegisterStepTwo />} />
            <Route path='/changepassword' element={loggedInUser ? <ChangePassword /> : <Navigate to="/user/login" />} />
            <Route path='/forgotpassword' element={loggedInUser ? <ForgotPassword /> : <Navigate to="/user/login" />} />
            <Route path='*' element={<PageNotFound />} />
            {/* <Route path='/admin' element={<PageNotFound />} /> */}



            {/* admin routes */}
            {/* <Route path='/students' element={<Students />} />
            <Route path='/users' element={<Users />} /> */}







        </Routes>
    )
}

const AdminRoute = () => {
    const loggedInUser = useSelector(state => state.admin.admin || state.user.user);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (loggedInUser) {
            const storedLoginTime = loggedInUser.loginTime
            // console.log('loginTime', storedLoginTime)

            if (storedLoginTime) {
                // Convert the stored loginTime string into a Date object
                const loginTime = new Date(storedLoginTime);
                console.log('loginTime', loginTime)

                const expireAt = new Date(loginTime.getTime() + 238*60 * 1000);
                console.log('expireAt', expireAt.getTime())
                console.log('Date.now()', new Date(Date.now()))
                const currentTime = new Date(Date.now())
                console.log('Date.now()',currentTime.getTime())



                // Calculate the remaining time (milliseconds) until expiration
                const remainingTime = expireAt.getTime() - currentTime.getTime();
                console.log('remainingTime', remainingTime)

                if (remainingTime > 0) {
                    // Set a timer for the remaining time to log out the user
                    const timer = setTimeout(() => {
                        dispatch(userLogout());
                        dispatch(adminLogout());
                        navigate('/admin/login')

                        toast.info("Session expired. Please log in again.");

                        // Optionally, clear the loginTime from sessionStorage
                        sessionStorage.removeItem('loginTime');
                    }, remainingTime);

                    // Clean up the timer on unmount or when loggedInUser changes
                    return () => clearTimeout(timer);
                } else {
                    // If the token has already expired, log out immediately
                    dispatch(userLogout());
                    dispatch(adminLogout());
                    navigate('/admin/login')
                    toast.info("Session expired. Please log in again.");

                    // Optionally, clear the loginTime from sessionStorage
                    sessionStorage.removeItem('loginTime');
                }
            }
            // const tokenExpireTime = 1000 * 1000; // 162 minutes in milliseconds
            // // const expireAt = loginTime + tokenExpireTime;
            // // console.log('expireAt', expireAt)
            // console.log('tokenExpireTime', tokenExpireTime)

            // // setExpirationTime(expireAt);

            // const timer = setTimeout(() => {
            //     dispatch(userLogout());
            //     dispatch(adminLogout());

            //     toast.info("Session expired. Please log in again.");
            //     // }, expireAt - Date.now());
            // }, tokenExpireTime);


            // return () => clearTimeout(timer);
        }
    }, [dispatch, loggedInUser]);
    return (
        <Routes>
            {
                console.log('into admin route')
            }

            {
                loggedInUser?.role === 'Administrator' ? (
                    <>
                        <Route path="/" element={loggedInUser ? <Navigate to="/admin" /> : <Login />} />

                        <Route path="/admin/login" element={loggedInUser ? <Navigate to="/admin" /> : <Login />} />
                        <Route path="/admin" element={loggedInUser ? <Admins /> : <Navigate to="/admin/login" />} />
                    </>
                ) : (
                    <>
                        <Route path="/admin/login" element={loggedInUser ? <Navigate to="/admin/students" /> : <Login />} />
                        <Route path="/admin/students" element={loggedInUser ? <Students /> : <Navigate to="/admin/login" />} />
                    </>
                )
            }


            <Route path='/dashboard' element={<Dashboard />} />


            <Route path="/profile" element={<Profile />} >
                <Route index element={<Account />} />
                <Route path='account' element={<Account />} />
                <Route path='studentdecleration' element={<StudentDecleration />} />
                <Route path='coursedetail' element={<CourseDetail />} />

            </Route>
            <Route path='/logbook' element={<LogBook />} >
                <Route index element={<LogBooks />} />

                <Route path='samplelogbook' element={<SampleLogBook />} />
                <Route path='individualweek/:weekNumber' element={<IndividualWeek />} />

                {/* <Route path='individualweek' element={<IndividualWeek />} /> */}

            </Route>





            <Route path='/certificates' element={<Certificates />} />
            <Route path='performanceappraisal' element={<PerformanceAppraisal />} />
            <Route path='/abstract' element={<Abstract />} />
            <Route path='/markingguideline' element={<Marksheets />} />

            {/* <Route path='/admin/login' element={<Login />} /> */}
            {/* <Route path='/user/login' element={<UserLogin />} /> */}

            <Route path='/admin/register' element={<AdminRegister />} />
            <Route path='/registersteptwo' element={<RegisterStepTwo />} />
            <Route path='/changepassword' element={<ChangePassword />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />


            {/* admin routes */}
            {
                loggedInUser?.role === 'Administrator' && (
                    <Route path='/admin' element={<Admins />} />

                )
            }
            <Route path='/admin/students' element={<Students />} />
            <Route path='/admin/profile' element={<AdminProfile />} />









        </Routes>
    )
}

export default function AppRoutes() {
    const loggedInUser = useSelector(state => state.admin.admin || state.user.user);
    console.log('loggedInUser', loggedInUser)
    // const loggedInUserType = loggedInUser === 'user'?.role || loggedInUser === 'admin'?.role || 'User'
    // console.log('loggedInUserType', loggedInUserType)
    const loggedInUserType = loggedInUser?.role || 'User'



    return (
        <>
            {console.log('loggedInUserType', loggedInUserType)}
            { }
            {loggedInUserType === 'User' && <UserRoute />}
            {loggedInUserType === 'Administrator' && <AdminRoute />}
            {loggedInUserType === 'PartnerCenter' && <AdminRoute />}



        </>
    )
}
