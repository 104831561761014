
import './App.css';
import Routes from './routes'
import Abstract from './abstract/Abstract';
import Certificates from './certificates/Certificates';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import LogBook from './logbooks/LogBook';
import IndividualWeek from './logbooks/individual_week/IndividualWeek';
import PerformanceAppraisal from './performanceAppraisal/PerformanceAppraisal';
import Profile from './profile/Profile';


// react tostify

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateClickedUser, updateUserData, userLogout } from './store/reducerSlices/userSlice';
import { adminLogout, updateAdminData } from './store/reducerSlices/adminSlice';



function App() {

  // const dispatch = useDispatch()

  //   // if(e.storage)
  //   console.log('Setting up storage event listener');
  //   const handleStorageChange = (e) => {
  //     console.log('e.key', e.key)
  //     if (e.key === 'user') {
  //       if (e.newValue) {
  //         const userData = JSON.parse(e.newValue);
  //         dispatch(updateUserData(userData));
  //       } else {
  //         dispatch(userLogout());
  //       }
  //     } else if (e.key === 'clickedUser') {
  //       if (e.newValue) {
  //         const clickedUserData = JSON.parse(e.newValue);
  //         dispatch(updateClickedUser(clickedUserData));
  //       } else {
  //         dispatch(userLogout());
  //       }
  //     } else if (e.key === 'admin') {
  //       if (e.newValue) {
  //         const adminData = JSON.parse(e.newValue);
  //         dispatch(updateAdminData(adminData));
  //       } else {
  //         dispatch(adminLogout());
  //       }
  //     }

  //   }

  //     window.addEventListener('storage', handleStorageChange);
 
  

  return (
    <div className="App">
      {/* <Header/> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />


      <Routes />



    </div>
  );
}

export default App;
