import React from 'react'
import Sidebar from '../components/Sidebar'
// import Navbar from '../components/Navbar'
import Button from '../components/Button'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Navbar from '../components/Navbar'

const Index = () => {
  const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger)

  return (
    <div className={`block ${openMobileSideBar ? '' : 'flex'}`}>


      {/* <div className={` hidden md:block ${isSidebarCollapsed ? 'w-20' : 'w-[300px]'}`}> */}
      <div className={` hidden md:block ${isSidebarCollapsed ? 'w-20  ' : 'md:w-[250 px] lg:w-[300px]'}`}>

        <Sidebar />

      </div>
      {/* <div className={` ${isSidebarCollapsed ? 'w-20' : 'w-[300px]'}`}> */}
      <div className={` ${openMobileSideBar ? 'block fixed top-0 z-20' : 'hidden'} w-lvw block md:hidden`}>


        <Sidebar />

      </div>
      <div className={`w-full h-full border-red-700 ${isSidebarCollapsed ? 'w-[100%-80px ]' : 'w-5/6'} ${openMobileSideBar ? 'w-full top-0' : 'w-full'}`} >
        <Navbar />
        <div className='bg-primary-background h-screen flex flex-col justify-center items-center gap-y-7'>
          <div className='h-40 w-40 bg-cyan-400 rounded-full flex justify-center items-center '>
            <span className='text-white font-poppins text-5xl md:text-6xl'>404</span>

          </div>
          <div className='flex flex-col gap-y-3 mt-6'>
            <span className='text-cyan-400 font-poppins text-md md:text-xl lg:text-6xl'>Page Not Found</span>
            <span className='text-gray-500 font-poppins text-md md:text-xl lg:text-xl'>We looked everywhere for this page.</span>
            <span className='text-gray-500 font-poppins text-md md:text-xl lg:text-xl'>Are you sure the website URL is correct ?</span>
            <div>
              <Link to="/">
                <Button title='Go Back Home' /></Link>
            </div>
          </div>



        </div>



      </div>

    </div>
  )
}

export default Index