import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Profile from './Profile'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom';
import Title from '../components/Title';
import ProfileLeft from '../components/ProfileLeft';
import { useSelector } from 'react-redux';
import SkelotoLoaderProfileLeft from '../components/SkelotoLoaderProfileLeft';
import AccountTest from './account/AccountTest';

const Index = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { isSidebarCollapsed, openMobileSideBar } = useSelector((state) => state.hamburger)

    useEffect(() => {

        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 800);

        // Clean up timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`block ${openMobileSideBar ? '' : 'flex'}`}>


            {/* <div className={` hidden md:block ${isSidebarCollapsed ? 'w-20' : 'w-[300px]'}`}> */}
            <div className={` hidden md:block ${isSidebarCollapsed ? 'w-20  ' : ' md:w-[250 px] lg:w-[300px]'}`}>

                <Sidebar />

            </div>
            {/* <div className={` ${isSidebarCollapsed ? 'w-20' : 'w-[300px]'}`}> */}
            <div className={` ${openMobileSideBar ? 'block fixed top-0 z-20' : 'hidden'} w-lvw block md:hidden`}>
                <Sidebar />

            </div>
            <div className={`w-full  ${isSidebarCollapsed ? 'w-[100%-80px ]' : 'w-5/6'} ${openMobileSideBar ? 'w-full top-0' : 'w-full'}`} >
                <Navbar showStudent={true} />
                <div className='m-4 md:m-8'>


                    <div className='flex justify-between'>
                        <Title title="Profile" />
                    </div>

                    <div className='   '>
                        <div className='mt-4 md:mt-8 flex flex-col md:flex md:flex-row  gap-5 '>
                            <div className="">
                            <ProfileLeft />

                            </div>

                            <div className="hidden md:block">
                                {/* outlet is where nested routes will be rendered */}
                                <Outlet />

                                {/* <AccountTest /> */}
                            </div>

                        </div>
                    </div>

                </div>



            </div>
        </div>
    )
}

export default Index