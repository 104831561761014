import React from 'react'

const SkelotonLoaderLogbox = () => {
    return (
        // <div className='animate-pulse rounded-md h-44   md:h-60  md:rounded-2xl flex flex-col items-center justify-center  shadow-md bg-primary-background gap-y-6 p-4 w-[calc(50%-10px)] md:w-[calc(33%-15px)] lg:w-[calc(33%-15px)]  '>
        <div className='bg-primary-background animate-pulse h-44 md:h-60 w-[calc(50%-10px)] lg:w-[calc(33%-12px)] xl:w-[calc(25%-1rem)] xl: rounded-md md:rounded-md flex flex-col items-center justify-center space-y-8 shadow-md hover:-translate-y-1 hover:scale-105 px-9'>

            <div className=" flex justify-center items-center">
                <div className="h-28 rounded-full w-28 bg-slate-200"></div>

            </div>
            <div className='bg-slate-200 h-3 w-full rounded'></div>
            {/* <span></span> */}
        </div>
    )
}

export default SkelotonLoaderLogbox