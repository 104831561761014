import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

const TextEditor = ({ defaultValue = '', value, onChange, editable = true, maxWords }) => {
    const [internalValue, setInternalValue] = useState(value ? value : defaultValue);
    console.log('internalValue', internalValue)
    const [wordLimitExceeded, setWordLimitExceeded] = useState(false);

    useEffect(() => {
        console.log('defaultValue', defaultValue)
        if (value !== undefined) {
            console.log('value', value)
            setInternalValue(value);
        } else {
            console.log('defaultValue', defaultValue)
            setInternalValue(defaultValue);

        }
    }, [defaultValue, value]);

    // useEffect(() => {

    // }, [value]);

    const handleChange = (content) => {
        const wordsArray = content.split(/\s+/).filter(word => word); // Split content into words
        const wordCount = wordsArray.length;

        if (maxWords && wordCount > maxWords) {
            // Limit the content to maxWords and join it back
            const limitedContent = wordsArray.slice(0, maxWords).join(' ');
            setInternalValue(limitedContent); // Set the trimmed content
            if (!wordLimitExceeded) {
                setWordLimitExceeded(true);
                toast.error(`Word limit of ${maxWords} reached!`); // Show toast error
            }
            if (onChange) {
                onChange(limitedContent); // Call onChange with the trimmed content
            }
        } else {
            setInternalValue(content); // Set the untrimmed content
            if (onChange) {
                onChange(content);
            }
        }
    };

    return (
        <div className="text-editor h-64 w-full overflow-hidden overflow-y-auto">
            <ReactQuill
                value={internalValue}
                onChange={handleChange}
                modules={TextEditor.modules}
                formats={TextEditor.formats}
                readOnly={!editable}
                className='font-poppins text-xs md:text-xl h-52 w-full'
            />
            {/* Display word count if maxWords is defined */}
            {maxWords && (
                <div className="text-right text-sm text-gray-500 mt-2">
                    {internalValue && internalValue.split(/\s+/).filter(word => word).length}/{maxWords} words
                </div>
            )}
        </div>
    );
};

TextEditor.modules = {
    toolbar: [
        [
            // { 'header': '1' }, { 'header': '2' }, { 'header': '3' }, { 'header': '4' }, 
            { 'font': [] }],

        // ['bold', 'italic', 'underline'],
        [
            // { 'list': 'ordered' }, 
            { 'list': 'bullet' }],
    ],
};

TextEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

export default TextEditor;
