import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PerformanceAppraisalPDF from './PerformanceAppraisalPDF';
import { toByteArray } from 'base64-js';



// icons
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FileUpload } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useSelector } from 'react-redux';
import dataService from '../services/data-service';
import { toast } from 'react-toastify';

const PerformanceAppraisal = () => {
  const [isUploading, setIsUploading] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  console.log('uploadedFile', uploadedFile)
  const [performanceImage, setPerformanceImage] = useState()

  // const [previewPerformance, setPreviewPerformance] = useState()
  // console.log('previewPerformance', previewPerformance)
  const [previewImage, setpreviewImage] = useState(null);
  console.log('previewImage', previewImage)
  var user = localStorage.getItem('user');
  var admin = localStorage.getItem('admin');
  var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)
  var loggedInData = null;

  if (user) {
    loggedInData = JSON.parse(user);
    console.log('Logged in data from profile left (user):', loggedInData);
  } else if (userClickedByAdmin) {
    loggedInData = userClickedByAdmin;
    console.log('data of particular user clicked by admin:', loggedInData);
  } else {
    console.log('No user logged in or admin has not clicked any user');
    // Handle the case where no one is logged in
  }
  const { name, address, gender, supervisorName, trainingCenter, imageUrl, candidateNumber, courseName, internStartDate, internEndDate, userId } = loggedInData

  // this below admin data will only be used for the validation case of if admin with role PartnerCenter and userclicked by admin also then we dont allow them to edit any user
  const adminData = JSON.parse(admin)
  const adminRole = adminData?.role
  console.log('adminRole', adminRole)



  const fields = [
    'Name :',
    'Candidate Registration No :',
    'Name of Organization :',
    'Department(S):',
    'Duration From :',
    'Duration To :'
  ];

  const fieldValues = [
    name,
    candidateNumber,
    trainingCenter,
    courseName,
    internStartDate.split('T')[0],
    internEndDate.split('T')[0]
  ];
  const handleUploadImage = () => {
    document.getElementById('hiddenFileInput').click();
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file)

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        // setPreviewPerformance(result); // Set the preview image
        setpreviewImage(result); // Set this too for previewing in the form
      };
      reader.readAsDataURL(file);
    }
  };



  const handleDownloadAppraisal = () => {
    const fetchData = async () => {
      try {
        // Fetch the base64-encoded PDF data
        const response = await dataService.getData(
          `/PerformanceAppraisal/downloadPerformanceAppraisal?userid=${userId}`
        );

        // Assuming response contains base64 string
        let base64String = response; // This should be your base64 encoded PDF

        // Check if the string is URL-safe and replace characters if needed
        base64String = base64String.replace(/-/g, '+').replace(/_/g, '/');

        // Add padding if necessary
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        base64String += padding;

        // Decode base64 string to binary data using base64-js
        const byteArray = toByteArray(base64String);

        // Create a Blob from the binary data
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a link to download the PDF
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = 'Performance_Appraisal.pdf'; // Filename for the PDF
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log('PDF downloaded successfully.');
      } catch (err) {
        console.error('Error downloading performance appraisal:', err);
      }
    };

    fetchData();
  };









  const handleImageUpload = async () => {

    const formData = new FormData();
    formData.append('performanceForm', uploadedFile);
    setIsUploading(true)

    // const uploadPdf = async () => {
    try {
      console.log('uploadedFile', uploadedFile)

      const response = await dataService.patchData(`/PerformanceAppraisal/upload?UserId=${userId}`, formData)
      console.log('response', response)
      toast.success('Uploaded Sucessfully')
      window.location.reload();
    } catch (err) {
      toast.error('Error uploading performance apprisal')
      console.log('Error uploading performance apprisal', err)
    } finally {
      setIsUploading(false)
    }

    // }
    // uploadPdf()


  }
  const getMimeType = (imageUrl) => {
    console.log(imageUrl)
    // You may need to inspect the base64 string or use additional metadata if available
    if (imageUrl.startsWith('/9j/')) {
      return 'image/jpeg';
    } else if (imageUrl.startsWith('iVBORw0KGgo')) {
      return 'image/png';
    } else if (imageUrl.startsWith('R0lGODlh')) {
      return 'image/gif';
    }
    return 'image/jpeg'; // Default to JPEG if unknown
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await dataService.getData(`/PerformanceAppraisal/get?userId=${userId}`);
        // console.log('response', response.performanceForm)
        setPerformanceImage(response.performanceForm)

      } catch (error) {
        console.log('error', error)
        toast.error('Could not retrive data')
      } finally {
        setIsLoading(false)
      }

    }
    fetchData();

  }, [userId])

  useEffect(() => {
    if (performanceImage) {
      const mimeType = getMimeType(performanceImage);
      const dataUrl = `data:${mimeType};base64,${performanceImage}`;
      // setPreviewPerformance(dataUrl)
      setpreviewImage(dataUrl)
    }
  }, [performanceImage])



  return (
    <div className='m-4 md:m-8'>
      <div className='flex justify-between'>
        <div className={` hidden md:block `} >
          <Title title="Performance Appraisal Form" />

        </div>
        <div className='block md:hidden w-full'>
          <Title title="Performance Appraisal" />

        </div>
        <div className="hidden md:block">
          <button
            className='flex justify-end items-center text-[14px] gap-x-1 p-2 bg-primary-title rounded-md px-6 text-white cursor-pointer' onClick={handleDownloadAppraisal}
          >
            <FileDownloadIcon />
            Download
          </button>
        </div>

      </div>

      <div className='grid grid-cols-1 text-sm md:text-lg md:grid-cols-2 lg:grid-cols-3 gap-6 -mx-8 mt-4 bg-primary-background p-8'>
        {fields.map((field, index) => (
          <div key={index} className='flex gap-x-4 md:text-sm xl:text-[14px]'>
            <div className='font-poppins '>{field}</div>
            <div className='font-poppins text-gray-400 '>{fieldValues[index]}</div>
          </div>
        ))}
      </div>

      <div className='mt-6'>
        <span className='font-poppins text-sm md:text-[14px] '>Please describe the trainee’s performance in the following areas. You are welcome to share your own observations on the trainee that may help his or her overall success during the placement.</span>
      </div>
      <div className='mt-6'>
        <div className='flex flex-col gap-2 md:flex md:flex-row lg:gap-20 text-sm md:text-[14px]'>
          <span className='font-poppins font-bold '>Grading Systems:</span>
          <span className='font-poppins'>1-Unsatisfactory</span>
          <span className='font-poppins'>2-Fair</span>
          <span className='font-poppins'>3-Good</span>
          <span className='font-poppins'>4-Excellent</span>
        </div>
      </div>

      <div className=" mt-6  md:hidden">
        <span className='font-poppins font-bold '>Download Appraisal Form</span>

        <button
          className='flex justify-center mt-2 items-center  text-lg gap-x-1 p-2 bg-primary-title rounded-md px-4 text-white cursor-pointer w-full' onClick={handleDownloadAppraisal}
        >
          <FileDownloadIcon />
          Download
        </button>


      </div>

      {
        isLoading ? (
          <div className="flex justify-center items-center w-full mt-32">
            <svg aria-hidden="true" role="status" className='inline h-12 w-12 md:w-28 md:h-28 me-3 text-primary-title animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
            </svg>
          </div>
        ) : (
          <div className="">
            <div className="flex cursor-pointer md:w-1/2 bg-primary-background border-2 border-dotted border-gray-400 rounded-md p-2" onClick={handleUploadImage}>
              {
                previewImage ? (
                  <div>
                    <img src={previewImage} alt="Performance Preview" className='h-64' />



                  </div>
                ) : (
                  <div>
                    {
                      adminData?.role !== 'PartnerCenter' && (
                        <div className="flex  gap-2 ">
                          <span className='color-gray'><FileUploadIcon /></span>

                          <span className='font-poppins font-bold text-[14px]'>Upload Appraisal</span>

                        </div>
                      )
                    }


                  </div>
                )}
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                id='hiddenFileInput'
                className='hidden'
                style={{ display: 'none' }}
                onChange={handleChangeImage}
                disabled={adminRole === 'PartnerCenter'}
              />


            </div>



            <div className='mt-4'>
              <button onClick={handleImageUpload} type='button' className='flex justify-end items-center gap-x-1 p-2 bg-primary-title rounded-md px-6 text-white cursor-pointer mt-5 text-lg'>
                {
                  isUploading ? (
                    <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                  ) : (
                    <span>Confirm Upload</span>
                  )
                }
              </button>
            </div>
          </div>

        )
      }







    </div>
  );
}

export default PerformanceAppraisal;
