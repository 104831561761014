// author: Kalyan Bikram Adhikari
// Created-date : 
// Modified-date: 2024/06/28


import React, { useEffect, useState } from 'react'

import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../../components/Button'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useDispatch, useSelector } from 'react-redux';
import { userAPIs } from '../../store/APIFeatures/userAPIs';
import dataService from '../../services/data-service';
import { toast } from 'react-toastify';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'antd/dist/reset.css'; // Ant Design styles

import { updateClickedUser, userLogout } from '../../store/reducerSlices/userSlice';
import { adminLogout } from '../../store/reducerSlices/adminSlice';
import { useNavigate } from 'react-router-dom';
import { updateUserData } from '../../store/reducerSlices/userSlice';

import imageOfBase64 from 'base64code-to-image';

const { RangePicker } = DatePicker;


const Account = () => {
  const [isSaving, setisSaving] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [updateValue, setUpdateValues] = useState('')
  console.log('updateValue', updateValue)

  const [imageAsFile, setImageAsFile] = useState('')
  console.log('imageAsFile', imageAsFile)
  const [previewImage, setPreviewImage] = useState(null) //in url format
  // console.log('previewImage', previewImage)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loggedInInfo = useSelector((state) => state.user)

  // const { name, candidateNumber, phoneNumber, email, address, internStartDate } = loggedInInfo.userLoginData

  var user = localStorage.getItem('user');
  var admin = localStorage.getItem('admin');
  var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)
  var loggedInData = null;
  if (user) {
    loggedInData = JSON.parse(user);
    // console.log('Logged in data from account  (user):', loggedInData);
  } else if (userClickedByAdmin) {
    loggedInData = userClickedByAdmin;
    // console.log('data of particular user clicked by admin:', loggedInData);
  } else {
    console.log('No user logged in or admin has not clicked any user');
    // Handle the case where no one is logged in
  }
  const { name, address, candidateNumber, gender, supervisorName, trainingCenter, email, phoneNumber, internStartDate, internEndDate, userId, imageUrl, studentSignature } = loggedInData

  // const firstName = name.split(' ')[0]
  // console.log('firstName', firstName)
  // const lastName = name.split(' ').length > 1 ? name.split(' ')[name.split(' ').length - 1] : '';
  // console.log(lastName)


  // this below admin data will only be used for the validation case of if admin with role PartnerCenter and userclicked by admin also then we dont allow them to edit any user
  const adminData = JSON.parse(admin)
  const adminRole = adminData?.role


  const handleUploadImage = () => {
    document.getElementById('hiddenFileInput').click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageAsFile(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // debugger
        setPreviewImage(reader.result);
        // debugger
      };
      reader.readAsDataURL(file);
    }
  };




  const handleSubmitForm = (values) => {
    console.log('values', values)

    setShowConfirmDialog(true)
    setUpdateValues(values)

  };
  const handleConfirmUpdate = async () => {
    setisSaving(true);

    const formData = new FormData();
    // formData.append('candidateNumber', candidateNumber);
    formData.append('name', updateValue.name);
    // formData.append('phoneNumber', phoneNumber);
    formData.append('address', updateValue.address);
    // formData.append('imageUrl', imageUrl);
    // formData.append('trainingcenter', trainingCenter);
    // formData.append('gender', gender);
    // formData.append('supervisorName', supervisorName);

    formData.append('studentSignature', imageAsFile);
    formData.append('internStartDate', updateValue.internstartend[0]);
    formData.append('internEndDate', updateValue.internstartend[1]);

    try {
      const response = await dataService.putData(`/userinformation/update/user?userId=${userId}`, formData);
      console.log('response of user update', response)
      // const userdata = JSON.parse(loggedInData)
      console.log('hi');

      console.log('full data', { ...loggedInData, name: response.name, address: response.address, studentSignature: response.studentSignature, internStartDate: response.internStartDate, internEndDate: response.internEndDate });

      if (user) {
        // const userdata = JSON.parse(user)
        dispatch(updateUserData({ ...loggedInData, name: response.name, address: response.address, studentSignature: response.studentSignature, internStartDate: response.internStartDate, internEndDate: response.internEndDate }));


      } else if (userClickedByAdmin) {
        console.log('into userclicked');

        // const userdata = userClickedByAdmin
        dispatch(updateClickedUser({ ...loggedInData, name: response.name, address: response.address, studentSignature: response.studentSignature, internStartDate: response.internStartDate, internEndDate: response.internEndDate }));

      }

      // dispatch(updateUserData({ ...loggedInData, name: response.name, address: response.address, studentSignature: response.studentSignature, internStartDate: response.internStartDate, internEndDate: response.internEndDate }));
      window.location.reload()
      toast.success("Profile Updated Successfully");

      // dispatch(userLogout());
      // dispatch(adminLogout());
      // navigate('/user/login');

    } catch (error) {
      toast.error("Update failed");
    } finally {
      setisSaving(false);
      window.location.reload()
    }


  }

  const handleCancelChange = () => {
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    if (studentSignature) {
      const imageUrlofbase64 = imageOfBase64(studentSignature)
      setPreviewImage(imageUrlofbase64)
    }
  }, [studentSignature]);



  return (
    <div className='mt-4'>
      <Formik
        initialValues={{
          // firstname: firstName,
          // lastname: lastName,
          name: name,
          candidateregno: candidateNumber,
          phonenumber: phoneNumber,
          email: email,
          address: address,
          // dob: '',

          internstartend: internStartDate && internEndDate
            ? [dayjs(internStartDate), dayjs(internEndDate)]
            : [dayjs('2024-01-01'), dayjs('2024-12-31')],
          signature: studentSignature,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required(' Name is required'),
          candidateregno: Yup.string().max(255).required('Candidate Registration  No. is required'),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          address: Yup.string().max(255).required(' Address is required'),
          phonenumber: Yup.number().required('Phone Number is required').required('Phone Number is required'),
          internstartend: Yup.array()
            .of(Yup.string().required('Internship start & end date is required'))
            .required('Internship start & end date is required')


        })}
        onSubmit={handleSubmitForm}





      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='flex  font-poppins'>


              <div className=' '>
                <div className=' space-y-10 '>

                  <div className=' flex w-full flex-wrap gap-x-3 gap-y-5 '>
                    {/* first name */}
                    <div className='flex flex-col justify-between  w-full xl:w-[calc(50%-6px)] '>
                      <div className='flex flex-col gap-y-[5px] relative  '>
                        <span className='  font-poppins text-[14px] text-[#212529] font-medium  '>Name <span className=' text-red-600 font-medium text-xl'>* </span></span>
                        <input
                          type="name"
                          id='name'
                          value={values.name}
                          name='name'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your name'
                          disabled={adminRole === 'PartnerCenter'}

                          className={`font-poppins border-[1px] text-[14px] text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-md outline-none ${touched.name && errors.name ? 'border-red-500' : 'border-[#CED4DA]'
                            }`} />
                        {touched.name && errors.name && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.name}
                          </span>
                        )}
                      </div>

                    </div>
                    {/* lastname */}

                    {/* <div className='flex flex-col justify-between w-full xl:w-[calc(50%-6px)] '>
                      <div className='flex flex-col gap-y-[5px]   '>
                        <span className='font-poppins text-[#212529] text-[14px] font-medium text'>Last Name * </span>
                        <input
                          type="lastname"
                          id='lastname'
                          value={values.lastname}
                          name='lastname'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your lastname'

                          className={`font-poppins border-[1px] text-[14px] text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-md outline-none ${touched.lastname && errors.lastname ? 'border-red-500' : 'border-gray-400'
                            }`} />
                        {touched.lastname && errors.lastname && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.lastname}
                          </span>
                        )}
                      </div>

                    </div> */}
                    {/* candidtte registeration number */}
                    <div className='flex  flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                      <div className='flex flex-col gap-y-[5px] w-full  '>
                        <span className='font-poppins text-[#212529] text-[14px] font-medium text '>Candidate Registration Number <span className=' text-red-600 font-medium text-xl'> </span></span>
                        <input
                          type="text"
                          id='candidateregno'
                          value={values.candidateregno}
                          name='candidateregno'
                          onBlur={handleBlur}
                          disabled
                          onChange={handleChange}
                          placeholder='Enter your candidateregno'


                          className='border-[1px] text-[14px] font-poppins text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-lg outline-none ' />
                        {touched.candidateregno && errors.candidateregno && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.candidateregno}
                          </span>
                        )}

                      </div>

                    </div>

                    {/* phone number */}
                    <div className='flex  flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                      <div className='flex flex-col gap-y-[5px] w-full  '>
                        <span className='font-poppins text-[#212529] text-[14px] font-medium text'>Phone Number</span>
                        <input
                          type="number"
                          id='phonenumber'
                          value={values.phonenumber}
                          name='phonenumber'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your phonenumber'
                          disabled

                          className='border-[1px] text-[14px] font-poppins text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-lg outline-none ' />
                        {touched.phonenumber && errors.phonenumber && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.phonenumber}
                          </span>
                        )}
                      </div>

                    </div>


                    {/* Email */}
                    <div className='flex  flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                      <div className='flex flex-col gap-y-[5px] w-full font-poppins  '>
                        <span className='font-poppins text-[#212529] text-[14px] font-medium text'>Email Address</span>
                        <input
                          type="email"
                          id='email'
                          value={values.email}
                          name='email'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='johndoe@gmail.com'
                          disabled

                          className='border-[1px] text-[14px] font-poppins text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-lg outline-none ' />
                        {touched.email && errors.email && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.email}
                          </span>
                        )}
                      </div>

                    </div>

                    {/* Address */}
                    <div className='flex  flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                      <div className='flex flex-col gap-y-[5px] w-full  '>
                        <span className='font-poppins text-[#212529] text-[14px] font-medium text'>Address <span className=' text-red-600 font-medium text-xl'>*</span></span>
                        <input
                          type="text"
                          id='address'
                          value={values.address}
                          name='address'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your address'
                          disabled={adminRole === 'PartnerCenter'}


                          className='border-[1px] text-[14px] font-poppins text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-lg outline-none ' />
                        {touched.address && errors.address && (
                          <span error className='text-red-500 font-poppins' >
                            {errors.address}
                          </span>
                        )}
                      </div>

                    </div>

                    {/* Date of Birth */}
                    {/* <div className='flex  flex-col justify-between w-full xl:w-[calc(50%-6px)]'>
                      <div className='flex flex-col gap-y-[5px] w-full  '>
                        <span className='font-poppins text-[#212529] text-[14px] font-medium text'>Date of birth</span>
                        <input
                          type="date"
                          id='date'
                          value={values.date}
                          name='date'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your date'

                          className='border-[1px] text-[14px] font-poppins text-[#212529] border-[#CED4DA] p-1 h-10 px-2 rounded-lg outline-none ' />

                      </div>

                    </div> */}

                    {/* Intern start end */}
                    <div className='flex  flex-col justify-between w-full xl:w-[calc(50%-6px)] md:text-sm lg:text-'>
                      <div className='flex flex-col gap-y-[5px] w-full  '>
                        <span className='font-poppins text-[#212529] text-[14px] font-medium text'>Internship Start & End date <span className=' text-red-600 font-medium text-xl'>*</span></span>
                        <div className=' flex border-[1px] text-[14px] border-[#CED4DA] p-1 h-10 px-2 rounded-lg outline-none bg-white '>
                          <RangePicker
                            format="YYYY-MM-DD"
                            disabled={adminRole === 'PartnerCenter'}

                            value={values.internstartend.length > 0 ? [dayjs(values.internstartend[0]), dayjs(values.internstartend[1])] : [dayjs('2024-01-01'), dayjs('2024-03-31')]}
                            onChange={(dates) => setFieldValue('internstartend', dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : [])}
                            className={`font-poppins w-full outline-none border-none ${touched.internstartend && errors.internstartend ? 'border-red-500' : ''}`}
                          />
                          {touched.internstartend && errors.internstartend && (
                            <span className='text-red-500 font-poppins'>{errors.internstartend}</span>
                          )}

                        </div>

                      </div>

                    </div>


                    {/* signature upload section */}
                    {/* {
                      adminData?.role !== 'PartnerCenter' && (
                        
                      )
                     

                    } */}
                    <div className='space-y-3' >
                      <span className='font-poppins text-[#212529] text-[14px] font-medium text'>Signature <span className='text-xs font-poppins'>(JPEG, JPG or PNG)</span></span>

                      {/* <span className='font-poppins'>Signature</span> */}
                      {/* <img src="/assets/images/signature.png" alt="noimg" /> */}




                      <div className={`flex items-center bg-primary-background border-2 border-dotted border-gray-400 rounded-md p-2 w-48  ${adminRole === 'PartnerCenter' ? '' : 'cursor-pointer'} `} onClick={handleUploadImage}>

                        {previewImage ? (
                          <img
                            src={previewImage}
                            alt="Profile Preview"
                            className=" bg-white border-red-500 border-4"
                          />
                        )
                          // : 
                          // studentSignature ? (
                          //   <img
                          //     src={studentSignature}
                          //     alt="Profile Preview"
                          //     className="h-20 w-20 md:h-24 md:w-24 lg:h-28 lg:w-28 xl:h-32 xl:w-32 rounded-lg"
                          //   />
                          // ) 
                          : (


                            <div >
                              <span className='color-gray'><FileUploadIcon /></span>
                              <span className='font-poppins text-sm'>Upload the signature</span>

                            </div>

                          )}



                        <input type="file" accept="image/png, image/jpeg, image/jpg" id="hiddenFileInput" onChange={handleImageChange} style={{ display: 'none' }} disabled={adminRole === 'PartnerCenter'} />



                      </div>

                    </div>



                  </div>





                </div>
                {
                  adminRole !== 'PartnerCenter' && (
                    <button type='submit' className='bg-cyan-800 p-3 text-white rounded-md px-8 mt-8 text-[14px]'>
                      Save
                    </button>
                  )
                }


                {/* <Button type='submit' title='Save' className='mt-8 rounded-md px-8' /> */}
              </div>
            </div>
          </form >
        )}
      </Formik >


      {showConfirmDialog && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-white  rounded-md shadow-lg w-full h-3/10 md:w-1/3 flex flex-col justify-between   ">
            <div className="flex font-poppins sm:text-xs md:text-base lg: text-md bg-primary-title text-white p-6">
              <div className="flex flex-col font-poppins text-sm md:text-base">
                <p>Are you sure you want to update Profile?</p>
                {/* <p>You will need to re-login once you update profile.</p> */}
              </div>


            </div>
            <div className="mt-4 flex justify-center gap-x-6 pb-5 ">
              <button onClick={() => handleConfirmUpdate()} className="bg-primary-title text-white font-poppins md:text-base lg:text-md py-2 rounded mr-2 px-8">
                {
                  isSaving ?
                    (
                      <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                      </svg>
                    ) : (
                      <span>Yes</span>
                    )
                }
              </button>
              <button onClick={handleCancelChange} className=" text-primary-title border-2 border-primary-title font-poppins md:text-base lg:text-md py-2 rounded px-8">
                NO
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Account