// api
import axios from 'axios';
// const pathe = require('path')
// console.log('pathe', pathe.resolve(__dirname))

const api = axios.create({
    baseURL: 'https://s2e.tech-glazers.com/api'
    // baseURL: 'https://localhost:7209/api'

});

export default api;
