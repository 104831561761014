import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import DashboardBox from '../components/DashboardBox';
import LogBox from './LogBox';
import StyleIcon from '@mui/icons-material/Style';
import SampleLogBox from './SampleLogBox';
import { Link } from 'react-router-dom';
import dataService from '../services/data-service';
import { useSelector } from 'react-redux';
import SkletonLoader from '../components/SkletonLoader';
import SkelotonLoaderLogbox from '../components/SkelotonLoaderLogbox';

const LogBook = () => {
    const [isLoading, setIsLoading] = useState(false);

    const weekFields = ["All", "Completed", "Pending"];
    const [activeField, setActiveField] = useState("All");
    const [logbookData, setLogbookData] = useState([]);
    const [weeks, setWeeks] = useState(Array.from({ length: 24 }, (_, i) => ({ number: i + 1, status: 'untouched' }))); // Initialize weeks with default status

    var user = localStorage.getItem('user');
    var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser);
    var loggedInData = null;

    if (user) {
        loggedInData = JSON.parse(user);
    } else if (userClickedByAdmin) {
        loggedInData = userClickedByAdmin;
    }

    const { userId } = loggedInData || {};

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await dataService.getData(`/Logbook/getall?userId=${userId}`);
                console.log('response', response);
                setIsLoading(false)

                // Transform response to map weeks with their statuses
                const weekStatus = Array.from({ length: 24 }, (_, i) => {
                    const week = response.find(item => item.weekName.includes((i + 1).toString()));
                    return {
                        number: i + 1,
                        status: week ? week.status : 'untouched'
                    };
                });
                setWeeks(weekStatus);
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }




        };
        fetchData();
    }, [userId]);


    return (
        <div className='w-full'>
            <div className='flex justify-between mt-4 w-full'>
                <div className='flex items-end w-full space-x-5'>
                    {weekFields.map((item, index) => (
                        <span
                            key={index}
                            className={`font-poppins text-gray-500 cursor-pointer px-3 lg:px-8 pb-4 text-xs md:text-[13.33px] ${activeField === item ? 'border-b-[3px] border-primary-title' : 'border-b-[3px] border-transparent'}`}
                            onClick={() => setActiveField(item)}
                        >
                            {item}
                        </span>
                    ))}
                </div>
            </div>

            <hr className='border border-slate-300 -mx-8' />

            {/* Content based on activeField */}
            <div className='mt-4 w-full'>
                {activeField === "All" && (
                    <div className='flex flex-wrap gap-5 w-full'>
                        <SampleLogBox icon={StyleIcon} text="Sample Logbook" />
                        {
                            isLoading ? (
                                weeks.map(week => (
                                    <SkelotonLoaderLogbox />
                                ))
                            ) :
                                (
                                    weeks.map(week => (

                                        <LogBox key={week.number} number={week.number} text={`Week ${week.number}`} status={week.status} />
                                    ))
                                )
                        }

                    </div>
                )}
                {activeField === "Completed" && (
                    <div className='flex flex-wrap gap-x-5 gap-y-5  w-full'>
                        {
                            isLoading ? (
                                weeks.map(week => (
                                    <SkelotonLoaderLogbox />
                                ))
                            ) :
                                (

                                    weeks.filter(week => week.status === 'Complete').map(week => (
                                        <LogBox key={week.number} number={week.number} text={`Week ${week.number}`} status={week.status} />
                                    ))
                                )}
                    </div>
                )}
                {activeField === "Pending" && (
                    <div className='flex flex-wrap gap-x-5 gap-y-5 w-full'>
                        {weeks.filter(week => week.status === 'Incomplete').map(week => (
                            <LogBox key={week.number} number={week.number} text={`Week ${week.number}`} status={week.status} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LogBook;
