import React from 'react';
import { Link } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const LogBox = ({ number, text, className, status }) => {
    return (
        <div className='bg-primary-background h-44 md:h-60 w-[calc(50%-10px)] lg:w-[calc(33%-12px)] xl:w-[calc(25%-1rem)] xl: rounded-[5px] flex flex-col items-center justify-center space-y-8 shadow-md hover:-translate-y-1 hover:scale-105'>
            <Link to={`individualweek/${number}`}>
                <div className='relative'>
                    {
                        status === 'Complete' && (
                            <div className='absolute -top-6 -left-8 lg:-left-11 2xl:-left-16'>
                                <DoneIcon className='bg-[#067306] rounded-full text-white p-[3px] font-bold' style={{ fontSize: '28px' }} />

                            </div>
                        )

                    }
                    {
                        status === 'Incomplete' && (
                            <div className='absolute -top-6 -left-8 lg:-left-11 2xl:-left-16'>
                                <MoreHorizIcon className='bg-[#EAAC30] rounded-full text-white p-[2px] text-lg' style={{ fontSize: '28px' }} />

                            </div>
                        )

                    }
                </div>
                <div className=' '>
                    <div className='flex flex-col justify-center items-center gap-3'>
                        <div className='rounded-full h-28 w-28 bg-primary-title flex justify-center items-center'>
                            <span className='text-white font-bold font-poppins text-3xl md:text-5xl' >{number}</span>
                        </div>
                        <span className='font-poppins text-[16px] text-[#555555]'>{text}</span>
                    </div>
                </div>
            </Link>

        </div>

    );
};

export default LogBox;
