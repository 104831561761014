import React, { useEffect, useState } from 'react'
import Title from '../components/Title'
import TextEditor from '../components/TextEditor';
import button from '../components/Button';

import { useDispatch, useSelector } from 'react-redux'
import { useSaveAbstractMutation } from '../store/APIFeatures/userAPIs'
import { toast } from 'react-toastify';


import dataService from '../services/data-service';
// import { setAbstractData, setAbstractSaveStatus } from '../store/reducerSlices/userSlice'

const Abstract = () => {
  const [abstractData, setAbstractData] = useState('')
  console.log('abstractData', abstractData)
  const abstractField = ["Abstract", "Sample Abstract"];
  const [activeField, setActiveField] = useState("Abstract");
  const defaultParagraph = "<p>I worked as an apprentice where my duty was to help in food production and assist other kitchen staff such as Commis or Chef. I assist in the preparation of food and handle food wastage areas. </p> <p>I learned how a kitchen operates and learned how to present the food, prepare the food, and food quality standards. I also operated a dishwasher during my time in this department. I supplied mead, vegetables, fruits, and canned food from the storeroom to the kitchen in a basket or trolley.l also assisted the kitchen team in replenishing food items, cookery, cutlery, etc. during special events or rush hours. I also learned about the quality assured standards of the organization specifically for the food production department. This kind of exposure helped me understand the significance of maintaining the quality standards and its benefits which was to accentuate the customer satisfaction while keeping their trust and safety intact. Not only it helps us to provide a quality experience to the clients but also helps us to smoothly carry out the day to day task with right practices. Here I also learned about how to plan in advance. Working in this department was a really great experience as it taught me about management, right practices, planning and how to provide our guests with an explicit service.REMARKS: (To be filled by trainer/Handwritten)</p>"
  const [observationContent, setObservationContent] = useState('');
  const [isUploading, setIsUploading] = useState(false);


  var user = localStorage.getItem('user');
  var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)

  var admin = localStorage.getItem('admin');
  var loggedInData = null;



  if (user) {
    loggedInData = JSON.parse(user);
    console.log('Logged in data(user):', loggedInData);
  } else if (userClickedByAdmin) {
    loggedInData = userClickedByAdmin;
    console.log('data of particular user clicked by admin:', loggedInData);
  } else {
    console.log('No user logged in or admin has not clicked any user');
    // Handle the case where no one is logged in
  }
  const { userId } = loggedInData

   // this below admin data will only be used for the validation case of if admin with role PartnerCenter and userclicked by admin also then we dont allow them to edit any user
   const adminData = JSON.parse(admin)
   const adminRole = adminData?.role
  //  console.log('adminRole', adminRole)

   const editableYesOrNot =()=>{
    if(adminRole === 'PartnerCenter'){
      return false
    }else{
      return true
    }
   }

   const editable = editableYesOrNot()

  //  console.log('editable', editable)

  const dispatch = useDispatch()
  const [saveAbstract, { isLoading, error }] = useSaveAbstractMutation()


  const handleObservationChange = (content) => {
    setObservationContent(content);
  };
  const extractTextFromHtml = (html) => {
    return html.replace(/<\/?p>/g, '');
  }

  const handleSave = async () => {
    console.log('abstract save clicked')
    setIsUploading(true)
    try {
      const abstractText = extractTextFromHtml(observationContent);
      const response = await dataService.patchData(`/userinformation/create/abstract?userId=${userId}`, { abstract: abstractText })
      console.log('Save response:', response)
      toast.success('Abstract saved successfully!')
      setIsUploading(false)
    } catch (err) {
      console.error('Failed to save abstract:', err)
      toast.error('Failed to save abstract')
      setIsUploading(false)
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataService.getData(`/UserInformation/getabstract?userId=${userId}`);
        setAbstractData(response.abstract)
        console.log('response', response)

      } catch (error) {
        console.log('error', error)
      }

    }
    fetchData();

  }, [userId])
  

  return (
    <div className='m-8'>
      <div className='flex justify-between'>
        <Title title="Fill the Abstract" />
      </div>

      <div className='flex justify-between mt-4'>
        <div className='flex  items-end w-full  space-x-5'>
          {abstractField.map((item, index) => (
            <span
              key={index}
              className={`font-poppins text-gray-500 cursor-pointer lg:px-8 pb-4 text-xs md:text-[13.33px] ${activeField === item ? 'border-b-[3px] border-primary-title ' : 'border-b-[3px] border-transparent'}`}
              onClick={() => setActiveField(item)}
            >
              {item}
            </span>
          ))}
        </div>
      </div>


      <hr className='border border-slate-300  -mx-8' />

      {/* Content based on AbstractField */}
      <div className='mt-4'>
        {activeField === "Abstract" && (
          <div className=''>
            <div className='flex flex-col gap-x-5 gap-y-5 p-4  md:px-8 bg-primary-background rounded-lg py-4 shadow-md w-full'>
              <span className='font-poppins text-xs md:text-[#6E7C87] md:text-[16px]'>Write in 100-200 words the summary of your logbook and works you accomplished</span>
              <TextEditor value={abstractData} onChange={handleObservationChange} maxWords={200}  editable={editable}/>

            </div>
            <div className='flex justify-end mt-8'>
              <button type='button' disabled={isUploading} className="px-8 py-3 border-2 border-primary-title rounded-md font-medium bg-primary-title text-white transition ease-in-out delay-150 hover:-translate-y-2 hover:scale-110 duration-300" onClick={handleSave}  >
                {
                  isUploading ? (
                    <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                  ) : (
                    <span>Upload</span>
                  )
                }
              </button>
              {/* {isLoading && <p>Saving...</p>}
              {error && <p>Error: {error.message}</p>} */}
            </div>
          </div>

        )}
        {activeField === "Sample Abstract" && (
          <div className='flex flex-col gap-x-5 gap-y-5 p-4  md:px-8 bg-primary-background rounded-lg py-4 shadow-md'>
            <span className='font-poppins text-xs text-[#6E7C87] md:text-[16px]'>Write in 100-150 words the summary of your logbook and works you accomplished</span>
            <TextEditor defaultValue={defaultParagraph} editable={false} />
          </div>
        )}

      </div>
    </div>
  );
};






export default Abstract;