import React, { useRef, useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import dataService from '../services/data-service';
import { toast } from 'react-toastify';

const ImageUploader = ({ src, weekNumber, userId, index, image, handleImageChange, imageText, handleImageTextChange, defaultText, editable }) => {

  const [preview, setPreview] = useState(null);
  const [isFromBackend, setIsFromBackend] = useState(false);
  // const [isFromFrontend, setIsFromFrontend] = useState(false);
  console.log('isFromBackend', isFromBackend)
  const fileInputRef = useRef(null);
  const [deleting, setDeleting] = useState(false);


  // this is triggered on initial render and when there is image coming. meaning that  for data coming from inidividualweek.js
  useEffect(() => {
    console.log('image', image)
    if (typeof image === 'string' && image.startsWith('data:')) {
      
      setPreview(image);
      setIsFromBackend(true);
    } else if (image && image instanceof File) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setIsFromBackend(false);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
      setIsFromBackend(false);
    }
  }, [image]);

  const handleImageClick = () => {
    if (editable) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const MAX_SIZE = 2 * 1024 * 1024; // 2MB
      if (file.size > MAX_SIZE) {
        toast.error("File size exceeds the 2MB limit");
        return;
      }
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   handleImageChange(index, reader.result);
      //   setIsFromFrontend(true)
      //   // setIsFromBackend(false);
      //   fileInputRef.current.value = null;
      // };
      // reader.readAsDataURL(file);

      handleImageChange(index,file)
    }
  };

  const handleTextChange = (e) => {
    handleImageTextChange(index, e.target.value);
  };

  const handleDeleteImage = async (e) => {
    e.stopPropagation();
    if (!isFromBackend) {
      handleImageChange(index, null);
      return;
    }

    setDeleting(true);
    try {
      await dataService.deleteData(`/LogBook/deleteimage?imageIndex=${index}&userId=${userId}&name=Week ${weekNumber}`);
      toast.success('Image Deleted');
      handleImageChange(index, null);
    } catch (error) {
      toast.error('Error deleting image');
      console.error('Error deleting image:', error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="flex flex-col items-center w-[calc(50%-6px)] lg:w-[calc(25%-9px)] gap-y-5 ">
      {src ? (
        <div className="flex border-[2px] h-52 border-dashed rounded-md cursor-pointer">
          <img src={src} alt="" className='' />
        </div>
      ) : (
        <div className={`flex w-full border-[2px] h-52 border-dashed rounded-md ${editable ? 'cursor-pointer' : ''}`} onClick={handleImageClick}>
          {deleting ? (
            <svg aria-hidden="true" role="status" className='inline w-8 h-8 me-3 text-primary-title animate-spin' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
            </svg>
          ) : (
            <>
              {preview ? (
                <div className='relative w-full'>
                  <img
                    src={preview}
                    alt="Upload"
                    className="h-full object-cover rounded-lg w-full object-top"
                  />
                  {/* {isFromBackend && ( */}
                  <div className="absolute top-2 right-2 bg-white rounded-full p-[1px]" onClick={handleDeleteImage}>
                    <ClearIcon style={{ color: 'red' }} />
                  </div>
                  {/* )} */}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center w-full p-2 py-6 ">
                  <img src="/assets/images/uploadimg.png" alt="" className='h-12 md:h-16 lg:h-24' />
                  <div className="mt-4 w-full flex flex-col items-center">
                    <span className="font-poppins text-sm font-bold md:text-base text-primary-title">Upload Images</span>
                    {/* <div className="span font-poppins text-xs md:text-sm">or drag or drop</div> */}
                  </div>
                  <div className="flex">
                    <span className='text-sm md:text-base font-poppins'>JPEG, PNG up to 2MB</span>
                  </div>
                </div>
              )}
            </>
          )}
          
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
            disabled={!editable}
          />
        </div>
      )}
      <div className='flex w-full '>
        <textarea
          className='w-full rounded-lg p-3 text-sm font-poppins h-24 pl-4 resize-none outline-none border-[2px] border-gray-200'
          placeholder='Placeholder text here'
          value={imageText || defaultText}
          onChange={handleTextChange}
          disabled={!!src || !editable}
        />
      </div>
    </div>
  );
};

export default ImageUploader;