import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import LeftSection from './LeftSection';

// toast
import {  toast } from 'react-toastify';


import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChangePassword = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <div>
<Formik
        initialValues={{
          email: '',
          password: '',
          confirmpassword: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
          confirmpassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .max(255)
            .required('Confirm Password is required')


        })}
        onSubmit={async ({ firstname, lastname, phonenumber, profilepic }) => {
          // const isRegistered = await dataService.postData('/register',{name,email,password,name,refreshToken})

          toast.success('Password Changed Sucessfully')
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='flex h-screen '>
              {/* left section of the login code */}
              <LeftSection />

              {/* right section of the login code */}

              <div className=' w-1/2'>
                <div className='mt-12 px-20 p-10 space-y-10 '>
                  <div className='flex justify-center text-primary-title font-poppins text-xl font-semibold'>
                    <span className='text-2xl'>Change Password</span>


                  </div >


                  {/* secttion below of basic information */}

                  <div className=' flex w-full  '>
                    <div className='flex flex-col justify-between w-full'>
                      <div className='flex flex-col space-y-4 py-4 w-full  '>
                        <span className='font-poppins text-base'>Email Address* </span>
                        <input
                          type="email"
                          id='email'
                          value={values.email}
                          name='email'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Enter your email'

                          className={`border-[1px] border-gray-400 p-1 h-10 px-2 rounded-lg outline-none ${touched.email && errors.email ? 'border-red-500' : 'border-gray-400'
                            }`} />
                        {touched.email && errors.email && (
                          <span error className='text-red-500' >
                            {errors.email}
                          </span>
                        )}
                      </div>
                      <div className='flex flex-col space-y-4 py-4 w-full relative '>
                        <span className='font-poppins text-base'>Password* </span>
                        <div className='relative'>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id='password'
                            value={values.password}
                            name='password'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Enter your password'

                            className={`border-[1px] border-gray-400 p-1 h-10 px-2 rounded-lg outline-none w-full ${touched.password && errors.password ? 'border-red-500' : 'border-gray-400'
                              }`}
                          />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOffIcon className="w-5 h-5" /> : <RemoveRedEyeIcon className="w-5 h-5" />}
                          </div>
                        </div>

                        {touched.password && errors.password && (
                          <span error className='text-red-500' >
                            {errors.password}
                          </span>
                        )}
                      </div>



                      <div className="flex flex-col space-y-4 py-4 w-full relative">
                        <span className="font-poppins text-base">Confirm Password* </span>
                        <div className="relative">
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmpassword"
                            value={values.confirmpassword}
                            name="confirmpassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Re-enter your password"
                            className={`border-[1px] border-gray-400 p-1 h-10 px-2 rounded-lg outline-none w-full ${touched.password && errors.password ? 'border-red-500' : 'border-gray-400'
                            }`}
                        />
                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={handleClickShowConfirmPassword}>
                          {showConfirmPassword ? <VisibilityOffIcon className="w-5 h-5" /> : <RemoveRedEyeIcon className="w-5 h-5" />}
                        </div>
                      </div>
                        {touched.confirmpassword && errors.confirmpassword && (
                          <span className="text-red-500">{errors.confirmpassword}</span>
                        )}
                      </div>

                    </div>


                  </div>
                  <div className='flex space-x-4 justify-center'>
                    <Button title="Home" className='text-primary-title bg-white border-2 px-11   border-primary-title rounded-md ' />
                    <Button title="Save " className='text-white border-2 px-11 bg-primary-title rounded-md' />

                  </div>


                </div>
              </div>
            </div>
          </form >
        )}
      </Formik >
    </div>
  )
}

export default ChangePassword