import React from 'react'
import { Link } from 'react-router-dom'

const SampleLogBox = ({ icon: Icon, text }) => {
    return (


        <div className='bg-primary-title h-44 md:h-60 w-[calc(50%-10px)] lg:w-[calc(33%-12px)] xl:w-[calc(25%-1rem)] rounded-[5px] flex flex-col items-center justify-center  space-y-8'>
            <Link to="samplelogbook" className='flex flex-col justify-center items-center gap-3'>
                <div className='md:hidden rounded-full h-20 w-20 md:h-28 md:w-28 bg-white flex justify-center items-center border-2 '>
                    <Icon style={{ fontSize: '2rem' }} className='text-primary-title' />

                </div>
                <div className='hidden rounded-full h-28 w-28 bg-white md:flex md:justify-center md:items-center '>
                    <Icon style={{ fontSize: 60 }} className='text-primary-title' />

                </div>
                <span className='font-poppins text-[16px] text-white'>{text}</span>
            </Link>
        </div >

    )
}

export default SampleLogBox