// auth-header
// import {store} from '../store/index'

export function authHeader() {
    var user = localStorage.getItem('user');
    var admin = localStorage.getItem('admin');
    var loggedInData = null;
    
    if (user) {
        loggedInData = JSON.parse(user);
        console.log('Logged in data from auth header (user):', loggedInData);
    } else if (admin) {
        loggedInData = JSON.parse(admin);
        console.log('Logged in data from auth header (admin):', loggedInData);
    } else {
        console.log('No user or admin is logged in');
        // Handle the case where no one is logged in
    }
    
    if (loggedInData) {
        // console.log('Authorization header:', { Authorization: 'Bearer ' + loggedInData.token });
        console.log('loggedInDatatoken', loggedInData)
        return { Authorization: 'Bearer ' + loggedInData.token };
    } else {
        console.log('No Authorization header');
        return {};
    }
}
