import React from 'react'

const IndividualNotification = ({ name, changedComponent, time }) => {
    return (
        <div className='flex gap-y-2 flex-col'>
            <div className='font-normal text-xl '><span className='text-cyan-800 text-2xl font-medium'>{name}</span> has updated the picture in logbook of week 1</div>
            <div className='gap-x-4'>
                <span className='text-lg font-medium'>{changedComponent}.</span>
                <span>
                    {time} min ago
                </span>
            </div>
            <hr />
        </div>
    )
}

export default IndividualNotification