import React from 'react'

const SubTitle = ({title}) => {
  return (
    <div className='flex mt-10 text-primary-title font-poppins text-xl font-semibold'>
      <span>{title}</span>
    </div>
  )
}

export default SubTitle