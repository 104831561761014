
// Author: Kalyan Bikram Adhikari
// Created-Date: 
// Modified-Date: 2024/06/27 & 06/28 

import React, { useEffect, useState } from 'react'
import SidebarButton from '../sidebarButton/SidebarButton'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import CourseDetail from './courseDetails/CourseDetail';
import { Link, useNavigate } from 'react-router-dom';

import { useGetUserDataQuery } from '../store/APIFeatures/userAPIs';

// from react-redux
import { useDispatch, useSelector } from 'react-redux'
import Account from '../profile/account/index';
import StudentDecleration from '../profile/studentDecleration/index';
import CourseDetail from '../profile/courseDetails/index';
import ProfileLeftButton from './ProfileLeftButton';
import dataService from '../services/data-service';
import { toast } from 'react-toastify';
import { updateClickedUser, userLogout } from '../store/reducerSlices/userSlice';
import { adminLogout } from '../store/reducerSlices/adminSlice';
import { updateUserData } from '../store/reducerSlices/userSlice';




const ProfileLeft = () => {
    //image changed form base64 to image
    const [imageSrc, setImageSrc] = useState('');
    const [isSaving, setIsSaving] = useState('')
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);


    const profile_fields = ["Account", "Student's Declaration", "Course Details"];
    const [activeField, setActiveField] = useState("Account");

    const navigate = useNavigate()
    const dispatch = useDispatch()
    // loggedInInfor could be of either user or admin
    // const loggedInInfo = useSelector((state) => state.user)
    // const { name, address, gender, supervisorName, trainingCenter } = loggedInInfo.userLoginData
    // console.log('loggedininfo', loggedInInfo)
    // const profiledata = useSelector((state)=>state.)

    var user = localStorage.getItem('user');
    // var admin = localStorage.getItem('admin');
    var userClickedByAdmin = useSelector((state) => state.adminClickedUser.clickedUser)
    var loggedInData = null;

    if (user) {
        loggedInData = JSON.parse(user);
        console.log('Logged in data from profile left (user):', loggedInData);
    } else if (userClickedByAdmin) {
        loggedInData = userClickedByAdmin;
        console.log('data of particular user clicked by admin:', loggedInData);
    } else {
        console.log('No user logged in or admin has not clicked any user');
        // Handle the case where no one is logged in
    }
    const { name, address, gender, supervisorName, candidateNumber, trainingCenter, imageUrl, internStartDate, internEndDate, phoneNumber, userId, role } = loggedInData

    // Further logic for handling loggedInData if it's not null
    // `if (loggedInData) {
    //     if (loggedInData.role === 'Administrator') {
    //         console.log('Admin is logged in');
    //         // Add any specific actions for admin here
    //     } else if (loggedInData.role === 'User') {
    //         console.log('User is logged in');
    //         // Add any specific actions for user here
    //     } else {
    //         console.log('Unknown role');
    //     }
    // }`

    // Function to determine MIME type based on image extension
    const getMimeType = (imageUrl) => {
        // You may need to inspect the base64 string or use additional metadata if available
        if (imageUrl.startsWith('/9j/')) {
            return 'image/jpeg';
        } else if (imageUrl.startsWith('iVBORw0KGgo')) {
            return 'image/png';
        } else if (imageUrl.startsWith('R0lGODlh')) {
            return 'image/gif';
        }
        return 'image/jpeg'; // Default to JPEG if unknown
    };
    useEffect(() => {
        if (imageUrl) {
            const mimeType = getMimeType(imageUrl);
            const dataUrl = `data:${mimeType};base64,${imageUrl}`;
            setImageSrc(dataUrl);
        }
    }, [imageUrl]);



    const [activeProfileLeftButton, setactiveProfileLeftButton] = useState("Account");
    const handleConfirmImageChange = () => {
        setShowConfirmDialog(true)
    }
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);

            setIsSaving(true)


            const formData = new FormData();
            formData.append('profileImage', file);




            // formData.append('candidateNumber', candidateNumber);
            // formData.append('name', name);
            // formData.append('phoneNumber', phoneNumber);
            // formData.append('address', address);
            // formData.append('imageUrl', file);
            // formData.append('trainingcenter', trainingCenter);
            // formData.append('studentSignature', '');
            // formData.append('internStartDate', internStartDate);
            // formData.append('internEndDate', internEndDate);


            try {
                // const response = await dataService.putData(`/userinformation/update/user?userId=${userId}`, formData)
                const response = await dataService.patchData(`/UserAccount/uploadImage?userId=${userId}`, formData)


                console.log('response of user update', response)
                if (user) {
                    const userdata = JSON.parse(user)
                    dispatch(updateUserData({ ...userdata, imageUrl: response.imageUrl }));


                } else if (userClickedByAdmin) {
                    console.log('into userclicked');
                    
                    const userdata = userClickedByAdmin
                    dispatch(updateClickedUser({ ...userdata, imageUrl: response.imageUrl }));

                }



                // window.location.reload()
                toast.success("Profile Updated Successfully");


                // dispatch(userLogout());
                // dispatch(adminLogout());
                // navigate('/user/login');



            } catch (error) {
                toast.error(error.response || "Update failed");
            } finally {
                setIsSaving(false)
            }



        }
    };

    // const handleConfirmUpdate = async () => {
    //     setIsSaving(true)
    //     if (imageUpdated === true) {
    //         const formData = new FormData();
    //         formData.append('candidateNumber', candidateNumber);
    //         formData.append('name', name);
    //         formData.append('phoneNumber', phoneNumber);
    //         formData.append('address', address);
    //         formData.append('imageUrl', imageSrc);
    //         formData.append('trainingcenter', trainingCenter);
    //         formData.append('studentSignature', '');
    //         formData.append('internStartDate', internStartDate);
    //         formData.append('internEndDate', internEndDate);


    //         try {
    //             const response = dataService.putData(`/userinformation/update/user?userId=${userId}`, formData)
    //             console.log('response of user update', response)
    //             toast.success("Profile Updated Successfully");

    //             navigate('/user/profile');


    //         } catch (error) {
    //             toast.error(error.response.data || "Update failed");
    //         } finally {
    //             setIsSaving(false)
    //         }
    //     }

    // }
    const handleCancelChange = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div>
            <div className='w-full bg-slate h-full md:bg-primary-background rounded-lg md:w-52 lg:w-72 md:shadow-md font-poppins'>
                <div className="flex w-full gap-2  md:flex-col ">
                    {/* <div className="w-1/2  md:w-full md:rounded-t-lg  md:p-0 flex justify-center items-center" > */}
                    <div className="relative w-1/2 md:w-full md:rounded-t-lg md:p-0 flex justify-center items-center">

                        {/* <img className='  h-44 w-44 object-cover object-top rounded-full md:rounded-none  md:h-60 md:w-full  ' src={imageSrc}  alt="" /> */}
                        {/* Image with hover effect */}
                        <img
                            className='h-44 w-44 object-cover object-top rounded-full md:rounded-none md:h-60 md:w-full'
                            src={imageSrc}
                            alt=""
                        />

                        {/* Hover overlay */}
                        {/* <label
                            htmlFor="imageInput"
                            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 cursor-pointer transition-opacity duration-300 rounded-full md:rounded-none"
                        >
                            Change Image
                        </label> */}


                        <span className='absolute inset-0 flex  items-center justify-center bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 cursor-pointer transition-opacity duration-300 rounded-full md:rounded-none' onClick={handleConfirmImageChange}>
                            Change Image
                        </span>
                    </div>
                    <div className='flex flex-col mt-4 w-[calc(60%-5px)] text-sm md:text-base md:w-full '>
                        <div className='flex flex-col text-lg space-y-3 mb-4'>
                            <div className='flex flex-col  md:items-center md:justify-center w-full '>
                                <span className='text-primary-title font-semibold text-sm md:text-[16px] '>{name} </span>

                            </div>
                            <div className='flex  md:justify-center text-sm md:text-[14px] font-medium text-[#555555]'>
                                <LocationOnIcon />
                                <span className=''>{address}</span>
                            </div>
                        </div>
                        <div className='flex flex-col text-lg  mb-4 text-[#555555]'>
                            <div className='flex flex-col md:items-center text-sm md:text-md'>
                                <span className='md:text-[14px] lg:text-[16x] font-extrabold'>Under Supervision of:</span>

                            </div>
                            <div className='flex  md:justify-center text-sm md:text-base font-medium text-[#555555] font-poppins'>
                                {/* {gender && gender === "Male" ? "Mr." : "Mrs."}  */}
                                <span className='font-poppins text-[14px] text-[#555555]'>{supervisorName}</span>

                            </div>
                        </div>

                        <div className='flex flex-col text-sm md:text-base mb-4 text-[#555555]'>
                            <div className='flex flex-col md:items-center'>
                                <span className='md:text-[14px] lg:text-[16px] font-extrabold'>Partner Training Center:</span>

                            </div>
                            <div className='flex  md:justify-center text-[#555555] text-sm md:text-md '>
                                <span className=' text-[14px] font-medium text-[#555555]'>{trainingCenter}</span>

                            </div>
                        </div>

                        <div className='flex flex-col '>
                        </div>
                        <hr className="mx-3 border-gray-300 border-t-2 hidden md:block" />


                    </div>
                </div>



                {/* this will only be for larger screen */}
                <div className='hidden  mx-3 md:block md:flex-col'>
                    < Link to="account">
                        <ProfileLeftButton
                            // icon={HomeRoundedIcon}
                            title="Account"
                            isProfileLeftActive={activeProfileLeftButton === 'Account'}
                            onClick={() => setactiveProfileLeftButton('Account')}
                        />
                    </Link>
                    < Link to="studentdecleration">
                        <ProfileLeftButton
                            title="Student's Declaration"
                            isProfileLeftActive={activeProfileLeftButton === "Student's Declaration"}
                            onClick={() => setactiveProfileLeftButton("Student's Declaration")}
                        />
                    </Link>
                    < Link to="coursedetail">
                        <ProfileLeftButton
                            title="Course Details"
                            isProfileLeftActive={activeProfileLeftButton === 'Course Details'}
                            onClick={() => setactiveProfileLeftButton('Course Details')}
                        />
                    </Link>

                </div>

                {/* for mobile screen */}

                {
                    window.innerWidth <= 768 && (
                        <div className="">
                            <div className='flex justify-between mt-4 '>
                                <div className='flex  items-end w-full  space-x-5'>
                                    {profile_fields.map((item, index) => (
                                        <span
                                            key={index}
                                            className={`font-poppins text-gray-500 cursor-pointer lg:px-8 pb-4 text-xs md:text-lg ${activeField === item ? 'border-b-[3px] border-primary-title ' : 'border-b-[3px] border-transparent'}`}
                                            onClick={() => setActiveField(item)}
                                        >
                                            {item}
                                        </span>
                                    ))}
                                </div>
                            </div>


                            <hr className='border border-slate-300  -mx-8' />

                            <div className="mt-4  ">
                                {
                                    activeField === "Account" && (
                                        <div className='' >
                                            <Account />
                                        </div>
                                    )
                                }
                                {
                                    activeField === "Student's Declaration" && (
                                        <div>
                                            <StudentDecleration />
                                        </div>
                                    )
                                }
                                {
                                    activeField === "Course Details" && (
                                        <div>
                                            <CourseDetail />
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                    )
                }


            </div>


            {showConfirmDialog && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
                    <div className="bg-white  rounded-md shadow-lg w-full h-3/10 md:w-1/3 flex flex-col justify-between   ">
                        <div className="flex font-poppins sm:text-xs md:text-base lg: text-md bg-primary-title text-white p-6">
                            <div className="flex flex-col font-poppins text-sm md:text-base">
                                <p>Are you sure you want to update Profile?</p>
                                {/* <p>You will need to re-login once you update profile.</p> */}
                            </div>


                        </div>
                        <div className="mt-4 flex justify-center gap-x-6 pb-5 ">
                            {/* Hidden file input */}
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                className="hidden"
                                id="imageInput"
                                onChange={handleImageChange}
                            />
                            <button className="bg-primary-title text-white font-poppins md:text-base lg:text-md py-2 rounded mr-2 px-8">
                                {
                                    isSaving ?
                                        (
                                            <svg aria-hidden="true" role="status" class='inline w-7 h-7 me-3 text-white animate-spin ' viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                        ) : (
                                            <label
                                                className='cursor-pointer'
                                                htmlFor="imageInput"
                                            >
                                                Change
                                            </label>
                                        )
                                }
                            </button>
                            <button onClick={handleCancelChange} className=" text-primary-title border-2 border-primary-title font-poppins md:text-base lg:text-md py-2 rounded px-8">
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProfileLeft