import React from 'react';

const DashboardBox = ({ icon: IconComponent, name }) => {
    return (
        <div className='bg-slate-50 rounded-md h-44 md:h-60 md:rounded-md flex flex-col items-center justify-center shadow-md hover:-translate-y-1 hover:scale-105'>
            <div className="md:hidden">
                <div style={{ fontSize: '1rem' }} className="bg-cyan-800 mb-2 flex items-center justify-center rounded-full h-24 w-24">
                    {IconComponent && <IconComponent />}
                </div>
            </div>
            <div className="hidden md:block">
                <div  className=" flex items-center justify-center rounded-full h-24 w-24 bg-cyan-800   mb-2">
                    {IconComponent && <IconComponent />} 
                </div>
            </div>
            <span className='font-poppins text-sm md:text-[16px] text-[#555555]'>{name}</span>
        </div>
    );
};

export default DashboardBox;
